import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-time-place',
  templateUrl: './step-time-place.component.html',
  styleUrls: ['./step-time-place.component.scss'],
})
export class StepTimePlaceComponent {
  @Input() timePlaceForm!: FormGroup;

  date: string = '';
  time: string = '';

  updateTime(time: string): void {
    this.timePlaceForm.get('time')?.setValue(time);
  }

  updateDate(date: string): void {
    this.timePlaceForm.get('date')?.setValue(date);
  }
}
