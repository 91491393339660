<div class="star-rating-container">
  <ng-container *ngFor="let star of [1, 2, 3]; let i = index">
    <!-- High: 3 stars fa-solid -->
    <i class="fa-solid fa-star" *ngIf="type === 'High'"></i>

    <!-- Moderate: 2 stars fa-solid, 1 fa-regular -->
    <i class="fa-solid fa-star" *ngIf="type === 'Moderate' && i < 2"></i>
    <i class="fa-regular fa-star" *ngIf="type === 'Moderate' && i === 2"></i>

    <!-- Low: 1 star fa-solid, 2 fa-regular -->
    <i class="fa-solid fa-star" *ngIf="type === 'Low' && i === 0"></i>
    <i class="fa-regular fa-star" *ngIf="type === 'Low' && i > 0"></i>
  </ng-container>
  <span *ngIf="withLabel">{{ getLabel() }} {{ score ? score / 10 : "" }}</span>
</div>
