import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { DialogFilterValuesComponent } from './dialog-filter-values.component';

@NgModule({
  declarations: [DialogFilterValuesComponent],
  exports: [DialogFilterValuesComponent],
  imports: [CommonModule, MatListModule, MatDialogModule, MatButtonModule],
})
export class DialogFilterValuesModule {}
