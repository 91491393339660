import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TableData } from '@app/drivers/dialog-driver/dialog-driver.constants';
import {
  IInspection,
  IInspectionVehicle,
  IInspectionViolation,
} from '@app/models/inspection.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';

@Component({
  selector: 'app-dialog-driver-inspection',
  templateUrl: './dialog-driver-inspection.component.html',
  styleUrls: ['./dialog-driver-inspection.component.scss'],
})
export class DialogDriverInspectionComponent implements OnInit {
  inspectionRowData!: IInspection;
  inspectionDetail!: IInspection;
  vehicles: IInspectionVehicle[] = [];
  violations: IInspectionViolation[] = [];
  eventDetails!: { title: string; value: string | number }[];
  isLoading: boolean = true;
  vehiclesColumns: string[] = ['unit', 'type', 'make', 'plate', 'vin'];
  violationColumns: string[] = [
    'unitNumber',
    'code',
    'category',
    'violDescription',
    'origOos',
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInspection,
    private dialogInfoService: DialogDriverService
  ) {
    this.inspectionRowData = data;
  }

  ngOnInit(): void {
    if (this.inspectionRowData) {
      this.dialogInfoService
        .getInspectionDetail(this.inspectionRowData.reportNumber)
        .subscribe({
          next: (response: IInspection[]) => {
            this.inspectionDetail = response[0];
            this.vehicles = this.inspectionDetail.Vehicles;
            if (this.inspectionDetail.violations[0].unit) {
              this.violations = this.inspectionDetail.violations;
            }
            this.isLoading = false;
          },
          error: (error: any) => {
            console.error('Error:', error);
            this.isLoading = false;
          },
        });
    }
  }
}
