<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Speed</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="grid">
    <div class="grid-header">Activity Details</div>
    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading" class="grid-data">
      <ng-container *ngFor="let item of activityDetails">
        <div class="title">{{ item?.title }}</div>
        <div class="value">{{ item?.value }}</div>
      </ng-container>
    </div>
    <div class="grid-map">
      <div>
        <iframe
          width="350"
          height="250"
          style="border: 0"
          Loading="lazy"
          allowfullscreen
          [src]="srcMap"
        ></iframe>
      </div>
    </div>
  </div>
</mat-dialog-content>
