import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { environment } from '@env/environment';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';
import { formatDateIsoString, formatTime } from '@app/shared/helpers/date.helper';
import { ITelematicsDetail, ITelematicsEvent } from '@app/models/telematics.model';

@Component({
  selector: 'app-dialog-driver-telematics',
  templateUrl: './dialog-driver-telematics.component.html',
  styleUrls: ['./dialog-driver-telematics.component.scss'],
})
export class DialogDriverTelematicsComponent implements OnInit {
  telematicsRowData!: ITelematicsEvent;
  telematicsDetail!: ITelematicsDetail;
  eventDetails!: { title: string; value: string | number }[];
  isLoading: boolean = false;
  isLoadingVideo: boolean = false;
  videoStream!: string;
  srcMap!: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ITelematicsEvent,
    private dialogInfoService: DialogDriverService,
    private sanitizer: DomSanitizer
  ) {
    this.telematicsRowData = data;
  }

  ngOnInit(): void {
    if (this.telematicsRowData) {
      const unsafeUrl = `${environment.googleMapStartUrl}${this.telematicsRowData.latitude},${this.telematicsRowData.longitude}&zoom=14&key=${environment.googleApiKey}`;

      this.srcMap = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
      this.getTelematicsDetail();
      if (this.telematicsRowData.video) {
        this.getTelematicsVideo();
      }
    }
  }

  getTelematicsDetail(): void {
    this.isLoading = true;
    this.dialogInfoService
      .getTelematicsSummaryDetail(this.telematicsRowData.id)
      .subscribe({
        next: (response: any) => {
          this.telematicsDetail = response[0];
          this.setEventDetails();
          this.isLoading = false;
        },
        error: (error: any) => {
          console.error('Error:', error);
          this.isLoading = false;
        },
      });
  }

  getTelematicsVideo(): void {
    this.isLoadingVideo = true;
    this.dialogInfoService
      .getTelematicsSummaryVideo(this.telematicsRowData.id)
      .subscribe({
        next: (res) => {
          this.isLoadingVideo = false;
          this.videoStream = `data:video/mp4;base64,${res[0].video}`;
        },
        error: (error) => {
          this.isLoadingVideo = false;
          console.log(error);
        },
      });
  }

  setEventDetails(): void {
    this.eventDetails = [
      {
        title: 'Event name',
        value: this.telematicsDetail.eventName
          ? addSpacesToCamelCase(this.telematicsDetail.eventName)
          : '',
      },
      {
        title: 'Date',
        value: this.telematicsDetail.date
          ? formatDateIsoString(this.telematicsDetail.date)
          : '',
      },
      {
        title: 'Time',
        value: this.telematicsDetail.date ? formatTime(this.telematicsDetail.date) : '',
      },
      { title: 'Driver ID', value: this.telematicsDetail.driverId },
      {
        title: 'Vehicle ID',
        value: this.telematicsDetail.vehicleId ? this.telematicsDetail.vehicleId : '',
      },
      {
        title: 'Turning Force (g)',
        value: this.telematicsDetail.turningForce
          ? this.telematicsDetail.turningForce
          : '',
      },
      {
        title: 'Braking Force (g)',
        value: this.telematicsDetail.brakingForce
          ? this.telematicsDetail.brakingForce
          : '',
      },
      {
        title: 'Tractor Yaw (1/ft)',
        value: this.telematicsDetail.yawRate ? this.telematicsDetail.yawRate : '',
      },
      {
        title: 'System State',
        value: this.telematicsDetail.systemState ? this.telematicsDetail.systemState : '',
      },
      {
        title: 'Right Warning',
        value: this.telematicsDetail.rightWarning ? 'Yes' : 'No',
      },
      {
        title: 'Left Warning',
        value: this.telematicsDetail.leftWarning ? 'Yes' : 'No',
      },
      {
        title: 'Classification',
        value: this.telematicsDetail.classification
          ? this.telematicsDetail.classification
          : '',
      },
    ];
  }
}
