<app-header (toggleMobileMenu)="toggleMobileMenu()"></app-header>
<main [ngClass]="{ 'menu-expanded': isExpanded }">
  <aside [ngClass]="{ visible: isMenuOpened }" class="animate-slide-in-left">
    <app-sidenav-list
      (toggleMenu)="toggleMenu()"
      [isExpanded]="isExpanded"
      [ngClass]="{ expanded: isExpanded }"
    ></app-sidenav-list>
  </aside>
  <article>
    <router-outlet></router-outlet>
  </article>
</main>
