export function formatDateIsoString(isoString: string): string {
  const date = new Date(isoString);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function formatDateWithSlash(date: Date): string {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let dayStr = day < 10 ? '0' + day : day.toString();
  let monthStr = month < 10 ? '0' + month : month.toString();

  return monthStr + '/' + dayStr + '/' + year;
}

export function formatTime(isoString: string): string {
  const date = new Date(isoString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12;
  const strTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${
    seconds < 10 ? '0' : ''
  }${seconds} ${ampm} (EST)`;

  return strTime;
}

export function convertDateFormat(inputDate: string) {
  const [year, month, day] = inputDate.split('-');
  return `${month}/${day}/${year}`;
}

export function getAMorPM(time: string) {
  const [hour, minutes] = time.split(':');
  const hourIn24Format = parseInt(hour, 10);

  if (hourIn24Format < 12) {
    return 'AM';
  }
  return 'PM';
}

export function convertTo12HrFormat(time24: string) {
  const [hours, minutes] = time24.split(':');
  let newHours = parseInt(hours, 10);
  let period = 'AM';

  if (newHours >= 12) {
    period = 'PM';
    if (newHours > 12) {
      newHours -= 12;
    }
  } else if (newHours === 0) {
    newHours = 12;
  }

  const formattedHours = newHours < 10 ? `0${newHours}` : newHours;
  return `${formattedHours}:${minutes} ${period}`;
}

export function convertToHourMinuteFormat(time: string): string {
  const timeParts = time.split(':');
  if (timeParts.length < 2) {
    return 'Invalid format';
  }
  const hour = timeParts[0];
  if (hour.includes('.')) {
    const dayParts = time.split('.');
    if (dayParts.length === 2) {
      return `${dayParts[0]}d`;
    }
  }
  const minute = timeParts[1];
  return `${hour}h ${minute}m`;
}

export function getNowDate(fullYear: boolean = false): string {
  const now = new Date();
  if (fullYear) {
    return `${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`;
  }
  return `${now.getMonth() + 1}/${now.getDate()}/${now
    .getFullYear()
    .toString()
    .substr(-2)}`;
}

export function getNowTime(): string {
  const now = new Date();

  const hours = now.getHours() > 12 ? now.getHours() - 12 : now.getHours();
  const minutes = now.getMinutes() < 10 ? `0${now.getMinutes()}` : now.getMinutes();
  const ampm = now.getHours() >= 12 ? 'pm' : 'am';
  return `${hours}:${minutes} ${ampm} EST`;
}

export function convertToUSDateFormatWithTime12H(isoDate: string) {
  const date = new Date(isoDate);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  let period = 'AM';
  if (hours >= 12) {
    period = 'PM';
  }
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0) {
    hours = 12;
  }

  return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;
}

export function convertMinutesToHHmm(minutes: number) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  return `${hours}:${minutesLeft.toString().padStart(2, '0')}:00`;
}

export function convertFromDatePickerToDatabaseFormat(dateString: string) {
  const parts = dateString.split('/');
  const year = parts[2];
  const month = parts[0].padStart(2, '0');
  const day = parts[1].padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getDateForEndpoint(daysAgo = 0) {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
}

export function getCurrentUTCDateTime() {
  const now = new Date();
  const year = now.getUTCFullYear();
  const month = String(now.getUTCMonth() + 1).padStart(2, '0');
  const day = String(now.getUTCDate()).padStart(2, '0');
  const hours = String(now.getUTCHours()).padStart(2, '0');
  const minutes = String(now.getUTCMinutes()).padStart(2, '0');
  const seconds = String(now.getUTCSeconds()).padStart(2, '0');
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
}

export function formatOnlyDate(dateString: string): string {
  const date = new Date(dateString);

  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  return `${month}/${day}/${year}`;
}
