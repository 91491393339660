<mat-form-field appearance="outline" class="custom-date-time-picker">
  <mat-label>{{ label }} {{ required ? "*" : "" }}</mat-label>
  <input
    class="custom-input"
    [value]="value"
    (input)="onDateChange($event)"
    matInput
    [disabled]="disabled"
    readonly
  />
  <input
    class="date-input"
    hidden
    matInput
    [ngxMatDatetimePicker]="picker"
    (dateChange)="onDateChange($event)"
    [(ngModel)]="innerValue"
  />
  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [disabled]="disabled"
    [showSpinners]="true"
    [showSeconds]="false"
    [stepHour]="1"
    [hideTime]="hideTime"
    [stepMinute]="1"
    [touchUi]="false"
    [enableMeridian]="true"
  >
    <ngx-mat-datepicker-actions>
      <button mat-raised-button ngxMatDatepickerCancel [disabled]="disabled">
        <i class="fas fa-times"></i>
        <span class="button-title">Cancel</span>
      </button>
      <button
        mat-flat-button
        color="primary"
        ngxMatDatepickerApply
        [disabled]="disabled"
      >
        <i class="fas fa-check"></i>
        <span class="button-title">Apply</span>
      </button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>
