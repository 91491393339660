import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  upload(formData: any, entityType: string, entityId: string): Observable<any> {
    const uploadUrl = `${this.baseUrl}/media/upload?entityType=${entityType}&entityId=${entityId}`;
    return this.http.post(uploadUrl, formData, {
      responseType: 'text',
    });
  }

  downloadFileList(entityType: string, entityId: string): Observable<any> {
    const url = `${this.baseUrl}/media/fileList?entityType=${entityType}&entityId=${entityId}`;
    return this.http.get<any>(url);
  }

  downloadFile(
    entityType: string,
    entityId: string,
    fileName?: string
  ): Observable<Blob> {
    let url = `${this.baseUrl}/media/download?entityType=${entityType}&entityId=${entityId}`;

    if (fileName) {
      url += `&fileName=${fileName}`;
    }

    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  deleteFile(entityId: string, entityType: string): Observable<any> {
    return this.http.delete(
      `${this.baseUrl}/media/delete?entityType=${entityType}&entityId=${entityId}`,
      {
        responseType: 'text',
      }
    );
  }
}
