import { Component, Input, OnInit } from '@angular/core';

import { Section } from '@app/models';
import { Note } from '@app/models/note.model';
import { PermissionService } from '@app/services/permission.service';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { TabNotesSections } from '@app/drivers/dialog-driver/dialog-driver.constants';

@Component({
  selector: 'app-tab-sections-notes',
  templateUrl: './tab-sections-notes.component.html',
  styleUrls: ['../tab-sections.component.scss'],
})
export class TabSectionsNotesComponent implements OnInit {
  @Input() driverId!: string;
  userId: string = 'jdoe';
  userName: string = 'User';
  avatarUrl: string = '/assets/images/fallback.png';
  contents: string = '';
  entityType: string = 'driver_profile';

  readonly sections: Section[] = TabNotesSections;
  notes: Note[] = [];

  constructor(
    private dialogInfoService: DialogDriverService,
    private permissionService: PermissionService
  ) {
    setTimeout(() => this.dialogInfoService.viewInit());
  }

  ngOnInit(): void {}

  onNavClick(elementId: string): void {
    this.dialogInfoService.onNavClick(elementId);
  }

  canViewDriverNotes(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Driver Notes') ||
      this.permissionService.hasPermission('WRITE', 'Driver Notes')
    );
  }
}
