import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MultiSelectComponent } from './multi-select.component';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';

@NgModule({
  declarations: [MultiSelectComponent],
  exports: [MultiSelectComponent],
  imports: [CommonModule, CheckboxModule, FormsModule],
})
export class MultiSelectModule {}
