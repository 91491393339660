<div class="stepper-container w-500" [formGroup]="accidentDetailsForm">
  <div>
    <h3>Where are the assets (truck/trailer) located?</h3>
    <mat-form-field appearance="outline" class="column">
      <mat-label>Select Assets Located...</mat-label>
      <mat-select formControlName="assetsLocated">
        <mat-option *ngFor="let option of assetsOptions" [value]="option.id">
          {{ option.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <h3>Provide the type of crash</h3>
    <mat-form-field appearance="outline" class="column">
      <mat-label>Select Type of Crash...</mat-label>
      <mat-select formControlName="crashType">
        <mat-option *ngFor="let option of crashTypeOptions" [value]="option.id">
          {{ option.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <h3>Provide a description of the accident</h3>
    <mat-form-field appearance="outline" class="textarea w-full">
      <textarea matInput formControlName="accidentDescription"></textarea>
    </mat-form-field>
  </div>
  <div>
    <h3>Provide any witness information</h3>
    <mat-form-field appearance="outline" class="textarea w-full">
      <textarea matInput formControlName="witnessInfoDescription"></textarea>
    </mat-form-field>
  </div>
  <!-- TBD -->
  <!--<div>
    <h3>Upload any images of the accident</h3>

    <input type="file" hidden #fileInput />
    <div class="supporting-media" (click)="fileInput?.click()">
      <div class="caption">
        <i class="fa-solid fa-cloud-arrow-up"></i>
        <div class="label">
          <div class="block-1">Drag & Drop Document</div>
          <div class="block-2">or Select a File...</div>
        </div>
      </div>
    </div>
  </div>-->
</div>
