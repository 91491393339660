<div class="header-block">
  <h2>Inspections</h2>
</div>

<table
  mat-table
  matSort
  [dataSource]="inspectionDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="inspectionDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.inspectionDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="reportNumber">
    <th mat-header-cell *matHeaderCellDef>Inspection Id</th>
    <td mat-cell *matCellDef="let element">{{ element?.reportNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="reportState">
    <th mat-header-cell *matHeaderCellDef>State</th>
    <td mat-cell *matCellDef="let element">{{ element?.reportState }}</td>
  </ng-container>

  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Inspection Level</th>
    <td mat-cell *matCellDef="let element">{{ element?.level }}</td>
  </ng-container>

  <ng-container matColumnDef="violation">
    <th mat-header-cell *matHeaderCellDef>Violation</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.violations[0].basic ? "Yes" : "No" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="inspectionColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: inspectionColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('inspections', row.id)"
  ></tr>
</table>

<div *ngIf="!isLoading && inspectionDataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
