export function calculateDialogWidth(minus: number = 0): string {
  const screenWidth = window.innerWidth;
  const calculatedWidth = screenWidth * 0.6 - 40 - minus;
  return `${calculatedWidth}px`;
}

export function getBackgroundClass(name: string): string {
  const colors = [
    '#FFCDD2',
    '#C8E6C9',
    '#BBDEFB',
    '#D1C4E9',
    '#FFECB3',
    '#B2DFDB',
    '#B3E5FC',
    '#D7CCC8',
    '#F0F4C3',
    '#F5F5F5',
    '#FFAB91',
    '#FFD54F',
    '#FFCC80',
    '#E6EE9C',
    '#CFD8DC',
    '#FFAB40',
    '#80CBC4',
    '#80DEEA',
    '#A5D6A7',
    '#90CAF9',
  ];
  const initials = getInitials(name);
  let sum = 0;
  for (let char of initials) {
    sum += char.charCodeAt(0);
  }
  const colorIndex = sum % colors.length;
  return 'background-color-' + colorIndex;
}

export function getInitials(name: string): string {
  const words = name.split(' ');
  const initials = words
    .slice(0, 2)
    .map((word) => word[0])
    .join('');
  return initials.toUpperCase();
}
