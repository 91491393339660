import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';

import { FlyoutType } from '@app/models/fly-out.model';
import { StatusType } from '@app/models/status.model';
import { FlyoutService } from '@app/services/flyout.service';
import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';

@Component({
  selector: 'app-section-history-events-table',
  templateUrl: './section-history-events-table.component.html',
  styleUrls: ['./section-history-events-table.component.scss'],
})
export class SectionHistoryEventsTableComponent {
  @Input() eventData: any[] = [];
  @Input() showSmaller!: boolean;

  displayedColumnsGroups: string[] = [
    'criteria',
    'severity',
    'interventions',
    'processDate',
    'status',
  ];
  selectedRow: string | null = null;
  addSpaceAfterComma = addSpaceAfterComma;

  statuses = [
    {
      value: StatusType.OPEN.toLowerCase(),
      viewValue: StatusType.OPEN,
      icon: 'fa-solid fa-circle-notch',
    },
    {
      value: StatusType.ASSIGNED.toLowerCase(),
      viewValue: StatusType.ASSIGNED,
      icon: 'fa-solid fa-user-circle icon-second-blue',
    },
    {
      value: StatusType.CLOSED.toLowerCase(),
      viewValue: StatusType.CLOSED,
      icon: 'fa-solid fa-circle-xmark icon-red icon-default-size',
    },
  ];

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private flyoutService: FlyoutService
  ) {}

  onSelectClick(event: Event): void {
    event.stopPropagation();
  }

  onRowClick(data: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
    this.selectedRow = data?.id;
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogEventComponent, {
        data: { ...data, showInfo: true, uniqueIdentifier: FlyoutType.EVENT },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  announceSortChange(sortState: Sort): void {
    const data = this.eventData.slice();

    this.eventData = data.sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'key':
          return this.compare(a.key, b.key, isAsc);
        case 'severity':
          return this.compare(a.severity, b.severity, isAsc);
        case 'type':
          return this.compareString(a.type, b.type, isAsc);
        case 'criteria':
          return this.compare(a.criteria, b.criteria, isAsc);
        case 'interventions':
          return this.compare(a.interventions, b.interventions, isAsc);
        case 'processDate':
          return this.compareString(a.processDate, b.processDate, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compareString(str1: string, str2: string, isAsc: boolean): number {
    if (str1 === undefined || str1 === null) {
      return str2 === undefined || str2 === null ? 0 : isAsc ? -1 : 1;
    }
    if (str2 === undefined || str2 === null) {
      return isAsc ? 1 : -1;
    }
    return str1.localeCompare(str2) * (isAsc ? 1 : -1);
  }
}
