import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';

@Component({
  selector: 'app-step-police',
  templateUrl: './step-police.component.html',
  styleUrls: ['./step-police.component.scss'],
})
export class StepPoliceComponent implements OnInit {
  @Input() policeForm!: FormGroup;

  policeCalled: any[] = [];

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.getPoliceCalledList();
    this.setSubscriptionCalled();
  }

  getPoliceCalledList(): void {
    this.driverService.getAccidentOptionsList('PoliceCalled').subscribe({
      next: (res) => {
        this.policeCalled = res.sort((a: any, b: any) => a.id - b.id);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setSubscriptionCalled(): void {
    // prettier-ignore
    this.policeForm.get('called')?.valueChanges.subscribe((value) => {
      if (value === '1' || value === '2') {
        this.policeForm.get('citation')?.setValidators(Validators.required);
      } else {
        this.policeForm.get('citation')?.clearValidators();
        this.policeForm.get('citation')?.reset();
      }
      this.policeForm.get('citation')?.updateValueAndValidity();
    });
  }
}
