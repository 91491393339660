<!-- Driver Pre Selected -->
<mat-form-field appearance="outline" class="custom-input" *ngIf="driverId">
  <mat-label>Driver ID</mat-label>
  <span matPrefix>
    <div class="input-img">
      <i *ngIf="!selectedDriverImage" class="fa-solid fa-magnifying-glass"></i>
      <img
        *ngIf="selectedDriverImage"
        [src]="selectedDriverImage"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
    </div>
  </span>
  <input type="text" matInput [formControl]="searchDriverIdControl" />
</mat-form-field>

<!-- Driver Without Pre-selection -->
<mat-form-field appearance="outline" class="custom-input" *ngIf="!driverId">
  <mat-label>Driver ID</mat-label>
  <span matPrefix>
    <div class="input-img">
      <i *ngIf="!selectedDriverImage" class="fa-solid fa-magnifying-glass"></i>
      <img
        *ngIf="selectedDriverImage"
        [src]="selectedDriverImage"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
    </div>
  </span>
  <input
    type="text"
    matInput
    [formControl]="searchDriverIdControl"
    [matAutocomplete]="auto"
    [hidden]="false"
    (input)="clearImg()"
  />
  <span *ngIf="selectedDriverImage" class="input-reset" matSuffix (click)="resetDriver()">
    <i class="fa-solid fa-circle-xmark"></i>
  </span>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectDriver($event.option.value)"
  >
    <mat-option *ngFor="let driver of filteredDrivers | async" [value]="driver">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img
            [src]="getImageUrl(driver.driverId)"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
        </div>
        <span>{{ driver.driverName | capitalize }} ({{ driver.driverId }})</span>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="searchDriverIdControl.touched && searchDriverIdControl.invalid">
    The field is required
  </mat-error>
</mat-form-field>
