import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { DriversListComponent } from './drivers-list/drivers-list.component';

const routes: Routes = [
  { path: '', component: DriversListComponent },
  { path: ':terminal', component: DriversListComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DriversRoutingModule {}
