export enum EventType {
  SPEEDING = 'speeding',
  TELEMATICS = 'telematics',
  ACCIDENT = 'accident',
  HOS = 'hos',
  INSPECTIONS = 'inspection',
  SEATBELT = 'seatbelt',
}

export enum SettingsEventType {
  ACCIDENTS = 'Accidents',
  SPEEDING_EGREGIOUS = 'Speeding Egregious',
  SPEEDING_PERCENTAGE = 'Speeding Percentage',
  TELEMATICS = 'Telematics',
  HOS = 'Hours of Service',
  INSPECTIONS = 'Inspections',
  SEATBELT = 'Seatbelt',
}

export enum GroupByValueType {
  GROUP = 'group',
  INDIVIDUAL = 'individual',
}

export enum AssignedType {
  ALL = 'all',
  UNASSIGNED = 'unassigned',
}
