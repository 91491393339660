<div class="stepper-container w-500" [formGroup]="injuryForm">
  <h3>Were you injured in the accident?*</h3>
  <mat-radio-group formControlName="injury" required>
    <mat-radio-button value="true">Yes</mat-radio-button>
    <mat-radio-button value="false">No</mat-radio-button>
  </mat-radio-group>

  <div [style.visibility]="isInjured() ? 'visible' : 'hidden'">
    <h3>Please describe the extent of the injuries*</h3>
    <mat-form-field appearance="outline" class="textarea">
      <textarea
        matInput
        [required]="isInjured()"
        formControlName="injuryDescription"
      ></textarea>
    </mat-form-field>

    <h3>Do you require medical assitance?*</h3>
    <mat-radio-group
      formControlName="medicalAssistance"
      [required]="isInjured()"
    >
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
