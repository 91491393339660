export const environment = {
  production: false,
  apiUrl: 'https://test.api.brocadetech.com/safety',
  adminApiUrl: 'https://test.api.brocadetech.com/safety-admin',
  apiTokenHeaderKey: 'brocade-safety-api-subscription-key',
  apiTokenHeaderValue: '52dcec2125f847f4a15f817c91d2d7be',
  driverImageToken:
    '?sp=r&st=2023-12-27T17:53:49Z&se=2027-01-01T01:53:49Z&spr=https&sv=2022-11-02&sr=c&sig=JsF3pNPbo%2BwNZ0it26l6UK7G5JOD30b9Hm%2ByI%2FOrS8E%3D',
  driverImageBaseUrl: 'https://stsafetytesteastus.blob.core.windows.net/driver-images',
  googleApiKey: 'AIzaSyC2Fbs_Cg7adG4gScUzT2jLnXFl8y1OtKM',
  googleMapStartUrl: 'https://www.google.com/maps/embed/v1/search?q=',
  redirectUri: 'https://test.cowan.safety.brocadetech.com/home',
  clientId: 'cc00e12b-d0c8-46b8-9393-e7f99267772e',
  tenantId: 'a3768ebc-6d15-4dd3-b02e-85ed81e15f35',
  tenantSubDomain: 'safetybrocadetest',
  emailPattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  organizationId: '7d8087e6-4448-49b2-90f6-289571e8bd05',
};
