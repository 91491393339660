<article *ngIf="canViewDriverNotes()">
  <section>
    <app-notes
      [avatarUrl]="avatarUrl"
      [userName]="userName"
      [contents]="contents"
      [notes]="notes"
      [entityId]="driverId"
      [sectionId]="'qualification-files'"
      [entityType]="entityType"
    ></app-notes>
  </section>
</article>

<ul *ngIf="sections.length > 1">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="isFirst">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
