import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'utcDate',
  standalone: true,
})
export class UtcDatePipe implements PipeTransform {
  transform(value: any, formatStr = 'M/D/YYYY'): string {
    if (!value) {
      return '';
    }

    // Get the user's timezone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the input date from UTC to the user's timezone
    const zonedDate = moment.utc(value).tz(timeZone);

    // Format the date in the user's timezone
    return zonedDate.format(formatStr);
  }
}
