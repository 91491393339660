import { Component, Input } from '@angular/core';

import { SelectOptionItem } from '@app/shared/models';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {
  @Input() disabled: boolean = false;
  @Input() width?: string;
  @Input() height?: string;
  @Input() label?: string;
  @Input() margin?: string;
  @Input() options: SelectOptionItem[] = [];
  showOptions: boolean = false;
  selectedOptions: SelectOptionItem[] = [];
  searchTerm: string = '';

  constructor() {}

  get styles(): { [key: string]: string } {
    const params: any = {};
    if (this.width?.length) {
      params.width = this.width;
    }
    if (this.height?.length) {
      params.height = this.height;
    }
    if (this.margin?.length) {
      params.margin = this.margin;
    }
    return params;
  }

  get showOptionsBlock(): boolean {
    return !!this.selectedOptions?.length;
  }

  get filteredOptions(): SelectOptionItem[] {
    return !this.searchTerm?.length
      ? this.options
      : this.options.slice().filter(({ label }) => {
          return label
            .toLowerCase()
            .includes(this.searchTerm?.trim().toLowerCase());
        });
  }

  onShowOptionsClick(event: Event): void {
    if (!this.disabled) {
      this.handleClick(event);
      this.showOptions = !this.showOptions;
      this.searchTerm = '';
    }
  }

  onToggleOptionClick(event: Event, option: SelectOptionItem): void {
    this.handleClick(event);
    if (!this.isOptionSelected(option)) {
      this.selectedOptions.push(option);
    } else {
      this.onRemoveSelectedOptionClick(event, option);
    }
  }

  onRemoveSelectedOptionClick(event: Event, option: SelectOptionItem): void {
    this.handleClick(event);
    this.selectedOptions = this.selectedOptions.filter(({ value }) => {
      return value !== option.value;
    });
  }

  onRemoveAllClick(event: Event): void {
    this.handleClick(event);
    this.selectedOptions = [];
  }

  isOptionSelected(option: SelectOptionItem): boolean {
    return this.selectedOptions.some(({ value }) => value === option?.value);
  }

  handleClick(event: Event): void {
    event?.preventDefault();
    event?.stopPropagation();
  }
}
