export function addSpacesToCamelCase(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function toTitleCase(str: string): string {
  return str.toLowerCase().replace(/\b\w/g, (char: any) => {
    return char.toUpperCase();
  });
}

export function addSpaceAfterComma(text: string) {
  return text.replace(/,([^ ])/g, ', $1');
}

export function convertStringToArray(input: string): string[] {
  if (input) {
    return input.split(',');
  }
  return [];
}

export function insertBreaks(str: string): string {
  const maxLineLength = 60;
  let result = '';
  let lineLength = 0;

  str.split(' ').forEach((word) => {
    if (lineLength + word.length > maxLineLength) {
      result += '<br>';
      lineLength = 0;
    }

    if (lineLength > 0) {
      result += ' ';
      lineLength++;
    }

    result += word;
    lineLength += word.length;
  });

  return result;
}

export function prepareCommaSeparatedString(array: any[]): string {
  return array.length > 0 ? array.map((item) => item).join(',') : '';
}

export function removeSpaces(input: string): string {
  return input.replace(/\s+/g, '');
}

export function removeSpacesAfterCommas(input: string): string {
  return input.replace(/,\s+/g, ',');
}
