<div class="stepper-container w-500" [formGroup]="otherPartyForm">
  <h3>Who was involved in the accident?*</h3>
  <mat-radio-group formControlName="involved" class="column">
    <mat-radio-button *ngFor="let option of involved" [value]="option.id">
      {{ option.description }}
    </mat-radio-button>
  </mat-radio-group>

  <div
    *ngIf="
      otherPartyForm.get('involved')?.value === 3 ||
      otherPartyForm.get('involved')?.value === 4
    "
  >
    <h3>
      Please provide the other individual(s)' names, addresses, phone numbers
    </h3>
    <mat-form-field appearance="outline" class="textarea">
      <textarea
        matInput
        formControlName="otherIndividualDescription"
      ></textarea>
    </mat-form-field>

    <h3>Was there a fatality involved?*</h3>
    <mat-radio-group formControlName="fatalityInvolved">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <h3>Was the other individual(s) injured?*</h3>
    <mat-radio-group formControlName="otherIndividualInjured">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="otherPartyForm.get('otherIndividualInjured')?.value == 'true'">
      <h3>Please describe the extent of the injuries*</h3>
      <mat-form-field appearance="outline" class="textarea">
        <textarea
          matInput
          formControlName="extentInjuriesDescription"
        ></textarea>
      </mat-form-field>
    </div>

    <h3>Does the other individual require medical assistance?*</h3>
    <mat-radio-group formControlName="otherMedicalAssistance">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </div>

  <div
    *ngIf="
      otherPartyForm.get('involved')?.value === 2 ||
      otherPartyForm.get('involved')?.value === 3
    "
  >
    <h3>Was there damage to the other vehicle or property?*</h3>
    <mat-radio-group formControlName="otherVehicleDamage">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="otherPartyForm.get('otherVehicleDamage')?.value == 'true'">
      <h3>Please describe the extent of the damages</h3>
      <mat-form-field appearance="outline" class="textarea">
        <textarea
          matInput
          formControlName="extentDamagesDescription"
        ></textarea>
      </mat-form-field>
    </div>

    <h3>Does the other vehicle need to be towed?*</h3>
    <mat-radio-group formControlName="otherVehicleTowed">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="otherPartyForm.get('otherVehicleTowed')?.value == 'true'">
      <h3>Please provide the tow information</h3>
      <mat-form-field appearance="outline" class="textarea">
        <textarea matInput formControlName="towDescription"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
