import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PermissionService } from '@app/services/permission.service';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { IAccident } from '@app/models/accidents.model';
import { DialogNewAccidentComponent } from '@app/shared/components/dialog-new-accident/dialog-new-accident.component';
import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-accidents-list',
  templateUrl: './accidents-list.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class AccidentsListComponent implements OnInit, OnDestroy {
  @Input() dateRange: any;
  @Input() driverId!: string;
  @Input() maxDisplayedRecords!: number;
  @Input() defaultPageSize!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  accidentsColumns: string[] = [
    'accidentDate',
    'crashType',
    'citationReceived',
    'accidentCity',
    'linkedIntervention',
  ];
  selectedRow: { sectionName: string; rowId?: string } | null = null;
  dateRangeSubscription!: Subscription;
  refreshGridSubscription!: Subscription;
  accidentList!: IAccident[];
  accidentDataFiltered: IAccident[] = [];
  isLoading: boolean = false;
  isViewMore: boolean = true;
  showViewMore: boolean = false;
  pageSize!: number;
  isExpandViewTriggered: boolean = false;

  subscription!: Subscription;

  constructor(
    private dialogInfoService: DialogDriverService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private expandViewService: ExpandViewService
  ) {}

  ngOnInit(): void {
    this.pageSize = this.maxDisplayedRecords;
    this.getAccidentsSummary();
    this.setDateRangeSubscription();
    this.setRefreshGridSubscription();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.isExpandViewTriggered = true;
      this.loadMore(true);
    });
  }

  setRefreshGridSubscription(): void {
    this.refreshGridSubscription = this.dialogInfoService
      .getAccidentGridRefreshNeeded()
      .subscribe(() => {
        this.getAccidentsSummary();
      });
  }

  setDateRangeSubscription(): void {
    this.dateRangeSubscription = this.dialogInfoService.dateChange$.subscribe(() =>
      this.getAccidentsSummary()
    );
  }

  ngOnDestroy(): void {
    if (this.dateRangeSubscription) {
      this.dateRangeSubscription.unsubscribe();
    }
    if (this.refreshGridSubscription) {
      this.refreshGridSubscription.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'accidents' });
  }

  isRowSelected(sectionName: string, rowId: string): boolean {
    return (
      this.selectedRow?.sectionName === sectionName && this.selectedRow?.rowId === rowId
    );
  }

  loadMore(expandViewMore: boolean = false): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : this.defaultPageSize;
    this.getAccidentsSummary();
  }

  getAccidentsSummary(): void {
    this.isLoading = true;
    const { start, end } = this.dateRange.value;
    if (start && end) {
      const request = {
        startDate: start,
        endDate: end,
        driverId: this.driverId,
      };
      this.dialogInfoService.getAccidentsSummary(request).subscribe({
        next: (response: IAccident[]) => {
          this.isLoading = false;
          this.accidentList = response;
          if (this.accidentList.length) {
            this.setDataFiltered();
            this.setShowViewMore();
          }
          if (this.isExpandViewTriggered) {
            this.expandViewService.incrementExpandView();
            this.isExpandViewTriggered = false;
          }
        },
        error: (error: any) => {
          this.isLoading = false;
          console.error('Error:', error);
        },
      });
    }
  }

  setDataFiltered(): void {
    this.accidentDataFiltered = this.accidentList?.slice(0, this.pageSize);
  }

  setShowViewMore(): void {
    if (this.accidentList.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  onNewAccidentClick(): void {
    this.dialog.open(DialogNewAccidentComponent, {
      panelClass: 'single',
      maxWidth: '800px',
      width: '100%',
      autoFocus: false,
      data: {
        driverId: this.driverId,
      },
    });
  }

  canCreateAccident(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Accident');
  }
}
