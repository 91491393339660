import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NotesComponent } from './notes.component';
import { EditorModule } from '../editor/editor.module';
import { ShowImageModalComponent } from './show-image-modal/show-image-modal.component';
import { ImgFallbackModule } from '@app/shared/directives/img-fallback/img-fallback.module';

@NgModule({
  declarations: [NotesComponent, ShowImageModalComponent],
  imports: [
    CommonModule,
    EditorModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
    ImgFallbackModule,
    MatTooltipModule,
  ],
  exports: [NotesComponent],
})
export class NotesModule {}
