import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';

import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { addSpaceAfterComma } from '@app/shared/helpers/string.helper';
import { FlyoutType } from '@app/models/fly-out.model';
import { FlyoutService } from '@app/services/flyout.service';
import { PermissionService } from '@app/services/permission.service';
import { InterventionService } from '@app/services/intervention.service';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';

@Component({
  selector: 'app-event-table',
  templateUrl: './event-table.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class EventTableComponent {
  @Input() eventData: any[] = [];
  @Input() showSmaller!: boolean;

  displayedColumnsGroups: string[] = [
    'criteria',
    'severity',
    'interventions',
    'processDate',
    'status',
  ];
  selectedRow: string | null = null;
  addSpaceAfterComma = addSpaceAfterComma;

  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private dialog: MatDialog,
    private flyoutService: FlyoutService,
    private permissionService: PermissionService,
    private interventionService: InterventionService
  ) {}

  onSelectClick(event: Event): void {
    event.stopPropagation();
  }

  onRowClick(data: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
    this.selectedRow = data?.id;
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogEventComponent, {
        data: { ...data, showInfo: true, uniqueIdentifier: FlyoutType.EVENT },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  announceSortChange(sortState: Sort): void {
    const data = this.eventData.slice();
    if (!sortState.active || sortState.direction === '') {
      this.eventData = data;
      return;
    }

    this.eventData = data.sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'type':
          return this.compare(a.type, b.type, isAsc);
        case 'criteria':
          return this.compare(a.criteria, b.criteria, isAsc);
        case 'processDate':
          return this.compareDate(a.processDate, b.processDate, isAsc);
        case 'severity':
          return this.compare(a.severity, b.severity, isAsc);
        case 'status':
          return this.compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  compareDate(date1: string, date2: string, isAsc: boolean): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return (d1 < d2 ? -1 : 1) * (isAsc ? 1 : -1);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  canEditEvents(): boolean {
    return this.permissionService.hasPermission('WRITE', 'Events');
  }

  onInterventionSelected(intervention: any, row: any): void {
    this.interventionService.getIntervention(intervention.id).subscribe({
      next: (res) => {
        this.openInterventionDetail(res[0], row);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  openInterventionDetail(intervention: any, row: any): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );

    this.dialog
      .open(DialogCoachingDetailsComponent, {
        data: {
          key: intervention.ticketId,
          driverName: row.driverName,
          driverId: intervention.driverID,
          ...intervention,
          uniqueIdentifier: FlyoutType.COACHING,
        },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: dialogWidth,
        panelClass: ['dialog-event', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
      });
  }
}
