<article>
  <section>
    <div class="header-block events-block">
      <div class="title-filter">
        <h2>Events</h2>
        <div class="title-action events-filters" (click)="openFiltersModal($event)">
          <i class="fas fa-sliders-h"></i>
          <span>Filter</span>
        </div>
      </div>

      <div class="radio-slider">
        <input
          type="radio"
          name="slider"
          id="list-option"
          class="radio-input"
          (change)="onOptionChange('list')"
          checked
        />
        <label for="list-option" class="radio-label"
          ><i class="fa-regular fa-rectangle-list"></i> <span>List</span></label
        >

        <input
          type="radio"
          name="slider"
          id="group-option"
          class="radio-input"
          (change)="onOptionChange('group')"
        />
        <label for="group-option" class="radio-label"
          ><i class="fas fa-layer-group"></i> <span>Group</span></label
        >
      </div>
    </div>

    <div class="filter-boxes">
      <div class="filter-box" *ngFor="let filter of filters; let i = index">
        <span>{{ filter }}</span>
        <button class="remove-filter" (click)="removeBox(i)">
          <i class="fas fa-times icon-filter"></i>
        </button>
      </div>
      <button *ngIf="filters.length" class="clear-all" (click)="clearAll(true)">
        Clear All
      </button>
    </div>

    <table
      *ngIf="selectedOption === 'list'"
      mat-table
      matSort
      name="all-events-table"
      matSortActive="processDate"
      matSortDisableClear
      matSortDirection="desc"
      (matSortChange)="announceSortChange($event)"
      [dataSource]="dataSourceFiltered"
      class="table-events-header-sticky events-driver-table"
    >
      <ng-container matColumnDef="type">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="type-column">
          Type
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="type-column"
          (click)="onRowClick(element)"
        >
          {{ element?.type }}
        </td>
      </ng-container>

      <ng-container matColumnDef="criteria">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Criteria</th>
        <td
          class="criteria-column"
          mat-cell
          *matCellDef="let element"
          (click)="onRowClick(element)"
          matTooltip="{{ addSpaceAfterComma(element?.criteria) }}"
        >
          {{ addSpaceAfterComma(element?.criteria) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="severity">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Severity</th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
          <i
            *ngIf="element.severity === 'Critical'"
            [matTooltip]="element.severity"
            class="fa-solid fa-angle-double-up icon-red icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Significant'"
            [matTooltip]="element.severity"
            class="fa-solid fa-chevron-up icon-red icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Moderate'"
            [matTooltip]="element.severity"
            class="fa-solid fa-equals icon-orange icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Minor'"
            [matTooltip]="element.severity"
            class="fa-solid fa-chevron-down icon-gray icon-default-size"
          ></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="interventions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Interventions</th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngFor="let intervention of element?.interventionObjects; let isLast = last"
          >
            <span
              style="text-decoration: underline"
              (click)="onInterventionSelected(intervention, element)"
            >
              {{ intervention.key }}
            </span>
            {{ isLast ? "" : ", " }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="processDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="date-column">
          Date
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="date-column"
          (click)="onRowClick(element)"
        >
          {{ element?.processDate | utcDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="status-column">
          Status
        </th>
        <td mat-cell *matCellDef="let element" class="status-column">
          <app-status-select
            [id]="element.id"
            [driverId]="element.driverId"
            [status]="element.status"
            [eventData]="element"
            [hasInterventionLinked]="element?.interventions ? true : false"
            [disabled]="!canEditEvents()"
          ></app-status-select>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="clickable"
        [class.row-selected]="isRowSelected(row.id)"
      ></tr>
    </table>

    <!-- Spinner -->
    <div *ngIf="isLoading && selectedOption === 'list'" class="spinner-overlay">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <!-- No record found message -->
    <div
      *ngIf="!isLoading && totalRecordCount === 0 && selectedOption === 'list'"
      class="no-records-found"
    >
      <p>{{ driverId }} has no events.</p>
    </div>

    <div
      class="view-more"
      *ngIf="!isLoading && showViewMore && selectedOption === 'list'"
      (click)="loadMore()"
    >
      <span>{{ isViewMore ? "View More" : "View Less" }}</span>
      <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
    </div>

    <div *ngIf="selectedOption === 'group'">
      <!-- Accident Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!accidentData.length"
          (click)="accidentData.length && toggleSection('accident')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!accidentData.length"
            [ngClass]="{ rotated: !isSectionExpanded.accident }"
          ></i>
          <h3 [class.text-disabled]="!accidentData.length">
            Accident ({{ accidentData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.accident ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="accidentData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>

      <!-- Telematics Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!telematicsData.length"
          (click)="telematicsData.length && toggleSection('telematics')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!telematicsData.length"
            [ngClass]="{ rotated: !isSectionExpanded.telematics }"
          ></i>
          <h3 [class.text-disabled]="!telematicsData.length">
            Telematics ({{ telematicsData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.telematics ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="telematicsData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>

      <!-- Hours of Service Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!hosData.length"
          (click)="hosData.length && toggleSection('hos')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!hosData.length"
            [ngClass]="{ rotated: !isSectionExpanded.hos }"
          ></i>
          <h3 [class.text-disabled]="!hosData.length">
            Hours of Service ({{ hosData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.hos ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="hosData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>

      <!-- Inspections Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!inspectionData.length"
          (click)="inspectionData.length && toggleSection('inspection')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!inspectionData.length"
            [ngClass]="{ rotated: !isSectionExpanded.inspection }"
          ></i>
          <h3 [class.text-disabled]="!inspectionData.length">
            Inspections ({{ inspectionData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.inspection ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="inspectionData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>

      <!-- Speeding Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!speedingData.length"
          (click)="speedingData.length && toggleSection('speeding')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!speedingData.length"
            [ngClass]="{ rotated: !isSectionExpanded.speeding }"
          ></i>
          <h3 [class.text-disabled]="!speedingData.length">
            Speeding ({{ speedingData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.speeding ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="speedingData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>

      <!-- Seatbelt Section -->
      <div class="event-section">
        <div
          class="event-header"
          [class.disabled]="!seatbeltData.length"
          (click)="seatbeltData.length && toggleSection('seatbelt')"
        >
          <i
            class="fas fa-sort-down"
            [class.icon-disabled]="!seatbeltData.length"
            [ngClass]="{ rotated: !isSectionExpanded.seatbelt }"
          ></i>
          <h3 [class.text-disabled]="!seatbeltData.length">
            Seatbelt ({{ seatbeltData.length }})
          </h3>
        </div>
        <div
          class="event-content"
          [@expandCollapse]="isSectionExpanded.seatbelt ? 'expanded' : 'collapsed'"
        >
          <app-event-table [eventData]="seatbeltData" [showSmaller]="showSmaller">
          </app-event-table>
        </div>
      </div>
    </div>
  </section>
</article>
