<div class="header-block">
  <h2>Percentage Speeding</h2>
</div>

<table
  mat-table
  matSort
  [dataSource]="speedingDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="milesDriven">
    <th mat-header-cell *matHeaderCellDef>Miles Driven</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalDriveDistance !== "" ? element?.totalDriveDistance : "-" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="evaluationPeriod">
    <th mat-header-cell *matHeaderCellDef>Evaluation Period</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalDriveTime !== "" ? element?.totalDriveTime : "-" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="speedingPeriod">
    <th mat-header-cell *matHeaderCellDef>Speeding Period</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalSpeedingEvents !== "" ? element?.totalSpeedingEvents : "-" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="speeding">
    <th mat-header-cell *matHeaderCellDef>% Speeding</th>
    <td mat-cell *matCellDef="let element">{{ element?.totalPercentSpeeding }}%</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="speedingColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: speedingColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('speeding', row.id)"
  ></tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div class="paginator-container" *ngIf="!isViewMore">
  <div class="paginator-text">
    Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
  </div>
  <mat-paginator
    [pageSize]="pageEvent.pageSize"
    [length]="paginationParams.total"
    [hidePageSize]="true"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>

<div *ngIf="!isLoading && speedingDataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
