<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>{{ data.title }}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="content-container">
    <div *ngFor="let sample of sampleData; let i = index">
      <h2>{{ sample.title }}</h2>
      <div class="message-container" *ngFor="let message of sample.messages">
        {{ message }}
      </div>
    </div>
  </div>
</mat-dialog-content>
