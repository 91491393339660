<div class="header-block events-block">
  <h2>Events</h2>

  <div class="radio-slider">
    <input
      type="radio"
      name="slider"
      id="list-option"
      class="radio-input"
      (change)="onOptionChange('list')"
      checked
    />
    <label for="list-option" class="radio-label"
      ><i class="fa-regular fa-rectangle-list"></i> <span>List</span></label
    >

    <input
      type="radio"
      name="slider"
      id="group-option"
      class="radio-input"
      (change)="onOptionChange('group')"
    />
    <label for="group-option" class="radio-label"
      ><i class="fas fa-layer-group"></i> <span>Group</span></label
    >
  </div>
</div>
<!-- List -->
<div class="container-table" *ngIf="selectedOption === 'list'">
  <table
    mat-table
    matSort
    matSortActive="processDate"
    matSortDisableClear
    matSortDirection="desc"
    (matSortChange)="announceSortChange($event)"
    [dataSource]="eventsDataFiltered"
    class="table-header-sticky events-table"
  >
    <ng-container matColumnDef="type">
      <th mat-header-cell mat-sort-header="type" *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
        {{ element?.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="criteria">
      <th mat-header-cell mat-sort-header="criteria" *matHeaderCellDef>Criteria</th>
      <td
        mat-cell
        class="criteria-column"
        *matCellDef="let element"
        matTooltip="{{ addSpaceAfterComma(element?.criteria) }}"
        (click)="onRowClick(element)"
      >
        {{ addSpaceAfterComma(element?.criteria) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="severity">
      <th mat-header-cell mat-sort-header="severity" *matHeaderCellDef>Severity</th>
      <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
        <i
          *ngIf="element.severity === 'Critical'"
          [matTooltip]="element.severity"
          class="fa-solid fa-angle-double-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Significant'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-up icon-red icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Moderate'"
          [matTooltip]="element.severity"
          class="fa-solid fa-equals icon-orange icon-default-size"
        ></i>
        <i
          *ngIf="element.severity === 'Minor'"
          [matTooltip]="element.severity"
          class="fa-solid fa-chevron-down icon-gray icon-default-size"
        ></i>
      </td>
    </ng-container>

    <ng-container matColumnDef="interventions">
      <th mat-header-cell mat-sort-header="interventions" *matHeaderCellDef>
        Interventions
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngFor="let intervention of element?.interventionObjects; let isLast = last"
        >
          <span
            style="text-decoration: underline"
            (click)="onInterventionSelected(intervention, element)"
          >
            {{ intervention?.key }}
          </span>
          {{ isLast ? "" : ", " }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th
        class="status-column"
        mat-header-cell
        mat-sort-header="status"
        *matHeaderCellDef
      >
        Status
      </th>
      <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
        <ng-container [ngSwitch]="element?.status">
          <ng-container *ngFor="let status of statuses">
            <span *ngSwitchCase="status.viewValue">
              <i [class]="status.icon"></i>
              <span class="status-text">{{ status.viewValue }}</span>
            </span>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="processDate">
      <th mat-header-cell mat-sort-header="processDate" *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
        {{ element?.processDate | utcDate }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: eventsColumns"
      class="clickable"
      [class.row-selected]="isRowSelected(row.id)"
    ></tr>
  </table>

  <div *ngIf="isLoading" class="spinner-overlay">
    <mat-progress-spinner
      mode="indeterminate"
      [diameter]="30"
      class="spinner"
    ></mat-progress-spinner>
  </div>

  <div *ngIf="!isLoading && eventsDataFiltered.length === 0" class="no-records-found">
    <p>No records found.</p>
  </div>

  <hr *ngIf="!isLoading && showViewMore" />

  <div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
    <span>{{ isViewMore ? "View More" : "View Less" }}</span>
    <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
  </div>
</div>

<!-- Group -->
<div *ngIf="selectedOption === 'group'">
  <!-- Accident Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!accidentData.length"
      (click)="accidentData.length && toggleSection('accident')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!accidentData.length"
        [ngClass]="{ rotated: !isSectionExpanded.accident }"
      ></i>
      <h3 [class.text-disabled]="!accidentData.length">
        Accident ({{ accidentData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.accident ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="accidentData">
      </app-section-history-events-table>
    </div>
  </div>

  <!-- Telematics Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!telematicsData.length"
      (click)="telematicsData.length && toggleSection('telematics')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!telematicsData.length"
        [ngClass]="{ rotated: !isSectionExpanded.telematics }"
      ></i>
      <h3 [class.text-disabled]="!telematicsData.length">
        Telematics ({{ telematicsData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.telematics ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="telematicsData">
      </app-section-history-events-table>
    </div>
  </div>

  <!-- Hours of Service Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!hosData.length"
      (click)="hosData.length && toggleSection('hos')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!hosData.length"
        [ngClass]="{ rotated: !isSectionExpanded.hos }"
      ></i>
      <h3 [class.text-disabled]="!hosData.length">
        Hours of Service ({{ hosData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.hos ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="hosData">
      </app-section-history-events-table>
    </div>
  </div>

  <!-- Inspections Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!inspectionData.length"
      (click)="inspectionData.length && toggleSection('inspection')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!inspectionData.length"
        [ngClass]="{ rotated: !isSectionExpanded.inspection }"
      ></i>
      <h3 [class.text-disabled]="!inspectionData.length">
        Inspections ({{ inspectionData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.inspection ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="inspectionData">
      </app-section-history-events-table>
    </div>
  </div>

  <!-- Speeding Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!speedingData.length"
      (click)="speedingData.length && toggleSection('speeding')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!speedingData.length"
        [ngClass]="{ rotated: !isSectionExpanded.speeding }"
      ></i>
      <h3 [class.text-disabled]="!speedingData.length">
        Speeding ({{ speedingData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.speeding ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="speedingData">
      </app-section-history-events-table>
    </div>
  </div>

  <!-- Seatbelt Section -->
  <div class="event-section">
    <div
      class="event-header"
      [class.disabled]="!seatbeltData.length"
      (click)="seatbeltData.length && toggleSection('seatbelt')"
    >
      <i
        class="fas fa-sort-down"
        [class.icon-disabled]="!seatbeltData.length"
        [ngClass]="{ rotated: !isSectionExpanded.seatbelt }"
      ></i>
      <h3 [class.text-disabled]="!seatbeltData.length">
        Seatbelt ({{ seatbeltData.length }})
      </h3>
    </div>
    <div
      class="event-content"
      [@expandCollapse]="isSectionExpanded.seatbelt ? 'expanded' : 'collapsed'"
    >
      <app-section-history-events-table [eventData]="seatbeltData">
      </app-section-history-events-table>
    </div>
  </div>
</div>
