import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, startWith, map } from 'rxjs';

@Component({
  selector: 'app-multi-select-with-chips',
  templateUrl: './multi-select-with-chips.component.html',
  styleUrls: ['./multi-select-with-chips.component.scss'],
})
export class MultiSelectWithChipsComponent implements OnInit {
  @Input() list: string[] = [];
  @Input() elements: string[] = [];
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() disabled: boolean = false;
  @Output() elementsSelected = new EventEmitter<string[] | null>();

  selectedElements: string[] = [];
  elementControl = new FormControl({ value: '', disabled: this.disabled });
  filteredElements!: Observable<string[]>;

  @ViewChild('elementInput')
  elementInput!: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.setElements();
    this.setupElementAutocomplete();
  }

  setupElementAutocomplete(): void {
    this.filteredElements = this.elementControl.valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      map((value) => this.searchUsers(value || ''))
    );
  }

  searchUsers(searchTerm: string): string[] {
    return this.list.filter(
      (element: string) =>
        element.toLowerCase().includes(searchTerm.toLowerCase()) &&
        !this.selectedElements.includes(element)
    );
  }

  setElements(): void {
    if (this.elements?.length) {
      this.elements.forEach((element: string) => {
        if (!this.selectedElements.includes(element)) {
          this.selectedElements.push(element);
        }
      });
    }
  }

  removeElement(user: string): void {
    const index = this.selectedElements.indexOf(user);
    if (index >= 0) {
      this.selectedElements.splice(index, 1);
      this.elementInput.nativeElement.value = '';
      this.elementControl.setValue('');
      this.elementsSelected.emit(this.selectedElements);
    }
  }

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input) {
      this.elementControl.setValue(input.value, { emitEvent: true });
    }
  }

  onBlur(): void {
    this.elementControl.setValue('');
    this.elementInput.nativeElement.value = '';
  }

  resetSelectedElements(): void {
    this.elementInput.nativeElement.value = '';
    this.selectedElements = [];
    this.elementControl.setValue('');
    this.elementsSelected.emit(null);
  }

  displayFn(element: string): string {
    return element ? element : '';
  }

  selectElement(element: string): void {
    if (!this.selectedElements.includes(element)) {
      this.selectedElements.push(element);
      this.elementInput.nativeElement.value = '';
      this.elementControl.setValue('');
      this.elementsSelected.emit(this.selectedElements);
    }
  }
}
