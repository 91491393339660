<mat-form-field appearance="outline" class="custom-input">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <span matPrefix>
    <div class="input-img">
      <i *ngIf="!selectedUserImage" class="fa-solid fa-magnifying-glass"></i>
      <img
        *ngIf="selectedUserDisplay === assigneeType.UNASSIGNED"
        [src]="selectedUserImage"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
      <img
        *ngIf="selectedUserDisplay.trim() && assignee?.imageUrl"
        [src]="assignee?.imageUrl"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
      <div
        *ngIf="
          selectedUserImage &&
          selectedUserDisplay.trim() &&
          selectedUserDisplay !== assigneeType.UNASSIGNED &&
          !assigneeImages[assignee?.userId]
        "
        class="initials"
        [ngClass]="getBackgroundClass(selectedUserDisplay)"
      >
        {{ getInitials(selectedUserDisplay) }}
      </div>
    </div>
  </span>
  <input
    *ngIf="editable"
    type="text"
    matInput
    [formControl]="searchUserIdControl"
    [matAutocomplete]="auto"
    [hidden]="false"
    (input)="clearImg()"
  />
  <input
    *ngIf="!editable"
    type="text"
    matInput
    [formControl]="searchUserIdControl"
    [disabled]="true"
    [hidden]="false"
    (input)="clearImg()"
  />
  <span *ngIf="selectedUserImage" class="input-reset" matSuffix (click)="resetUser()">
    <i class="fa-solid fa-circle-xmark" *ngIf="editable"></i>
  </span>
  <span *ngIf="!selectedUserImage" matSuffix class="input-reset chevron-icon">
    <i class="fa-solid fa-chevron-down"></i>
  </span>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectUser($event.option.value)"
  >
    <!-- Unassigned option-->
    <mat-option *ngIf="!hideUnassigned" [value]="assigneeType.UNASSIGNED">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img [src]="getImageUrl('')" appImgFallback alt="Avatar" class="user-avatar" />
        </div>
        <span>{{ assigneeType.UNASSIGNED }}</span>
      </div>
    </mat-option>
    <!-- Users options-->
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img
            *ngIf="!user?.firstName.trim()"
            [src]="getImageUrl(user.assigneeId)"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <img
            *ngIf="user?.firstName.trim() && assigneeImages[user.userId]"
            [src]="assigneeImages[user.userId]"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <div
            *ngIf="user?.firstName.trim() && !assigneeImages[user.userId]"
            class="initials"
            [ngClass]="getBackgroundClass(setFullName(user))"
          >
            {{ getInitials(setFullName(user)) }}
          </div>
        </div>
        <span>{{ user.firstName }} {{ user.lastName }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="searchUserIdControl.touched && searchUserIdControl.invalid">
    The field is required
  </mat-error>
</mat-form-field>
<a
  *ngIf="canViewAssignToMe() && hideAssignToMe === false"
  class="assign-to-me-link"
  [class.disabled]="!allUsers.length"
  (click)="assignToMe()"
>
  Assign to me
</a>
