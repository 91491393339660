<section [class.mobile]="isMobile">
  <div class="block">
    <button
      *ngIf="canCreateAccidents()"
      mat-flat-button
      color="primary"
      class="accident"
      (click)="onNewAccidentClick()"
    >
      <i class="fas fa-plus-circle"></i>
      <span>New Accident</span>
    </button>
    <button
      *ngIf="canCreateInjury()"
      mat-flat-button
      color="primary"
      class="injury"
      (click)="onNewInjuryClick()"
    >
      <i class="fas fa-plus-circle"></i>
      <span>New Injury</span>
    </button>
  </div>
  <!-- <button mat-icon-button color="primary" matBadge="5" matBadgeColor="warn" matBadgeSize="small" class="envelope">
    <i class="fas fa-envelope"></i>
  </button> -->
  <div class="block">
    <div class="displaySettings">
      <a (click)="onMenuItemClick('settings')" class="settings">
        <div class="display-settings">
          <i class="fas fa-desktop"></i>
          <span class="display-link">Display Settings</span>
        </div>
      </a>
    </div>
    <!-- Profile FAB with dropdown toggle -->
    <div class="profile-menu">
      <a class="profile" (click)="onProfileMenuToggle()">
        <img
          class="circle-image"
          *ngIf="photoUrl"
          [src]="photoUrl"
          alt="User Image"
          matTooltip="{{ name }}"
        />
        <div
          *ngIf="!photoUrl"
          class="initials"
          matTooltip="{{ name }}"
          [ngClass]="getBackgroundClass(name)"
        >
          {{ getInitials(name) }}
        </div>
      </a>
      <!-- Dropdown Menu -->
      <div *ngIf="isDropdownMenuOpened" class="dropdown-menu">
        <ul>
          <li>
            <a (click)="openAccountProfileModal()">Profile</a>
          </li>
          <li><a (click)="logout()">Logout</a></li>
        </ul>
      </div>
    </div>
  </div>
</section>
