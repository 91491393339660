<div [formGroup]="injuryDescriptionForm">
  <div class="stepper-container w-full">
    <div>
      <h3>What area of the body was injured? *</h3>
      <div class="notes">(select all areas where injured)</div>

      <div class="human">
        <div class="human-row">
          <h3>Front</h3>
          <h3>Back</h3>
        </div>
        <div class="human-row-left">
          <h4>Right</h4>
          <h4>Left</h4>
        </div>
        <div class="human-row-right">
          <h4>Left</h4>
          <h4>Right</h4>
        </div>
        <img width="100%" [src]="humanBodyUrl" alt="Human Body" />

        <div class="human-points">
          <ng-container *ngFor="let item of items; let index = index">
            <mat-checkbox
              (change)="onCheckboxToggle(index)"
              (mouseover)="handleTooltip(index)"
              (mouseleave)="leaveTooltip()"
              [checked]="item.state"
              class="tooltip-hover"
              [ngStyle]="{ left: item.x + 'px', top: item.y + 'px' }"
            >
              <div
                class="tooltip-text tooltip-top"
                [class.visible]="isHandleTooltip(index)"
              >
                {{ item.name }}
              </div>
            </mat-checkbox>
          </ng-container>
        </div>

        <section *ngIf="isHasChecked" class="human-section">
          <div class="human-section-container">
            <div class="flex">
              <mat-chip-listbox multiple>
                <ng-container *ngFor="let item of items; let index = index">
                  <mat-chip *ngIf="item.state" [value]="item">
                    <span>{{ item.name }}</span>
                    <i
                      (click)="onCheckboxToggle(index)"
                      class="far fa-circle-xmark"
                    ></i>
                  </mat-chip>
                </ng-container>
              </mat-chip-listbox>
            </div>
            <div class="human-actions">
              <i (click)="onChipResetClick()" class="fas fa-xmark reset"></i>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="stepper-container w-500">
      <h3>Please describe the extent of the injuries *</h3>
      <mat-form-field appearance="outline" class="textarea w-full">
        <textarea matInput formControlName="extentInjuries"></textarea>
      </mat-form-field>
    </div>

    <h3>Is medical assistance required? *</h3>
    <mat-radio-group formControlName="medicalAssistanceRequired">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <div class="stepper-container w-500" *ngIf="isMedicalAssistanceRequired()">
      <h3>Where is the medical treatment being received?</h3>
      <mat-form-field appearance="outline" class="textarea w-full">
        <textarea
          matInput
          formControlName="medicalAssistanceReceived"
        ></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
