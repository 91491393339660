import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { ITelematicsDetail, ITelematicsList } from '@app/models/telematics.model';
import { formatDate } from '@app/shared/helpers/date.helper';
import { DateRange } from '@app/models/driver';
import {
  ISpeedElement,
  ISpeedList,
  ISpeedPercentageElement,
  ISpeedPercentageList,
} from '@app/models/speed.model';
import { IHoursService, IHoursServiceList } from '@app/models/hours-service.model';
import { IAccident } from '@app/models/accidents.model';
import { IInjury } from '@app/models/injury.model';
import { IInspection } from '@app/models/inspection.model';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class DialogDriverService {
  private readonly baseUrl = environment.apiUrl;
  private readonly seatbeltUrl = 'seatbelt';
  private readonly telematicsUrl = 'telematics';
  private readonly speedUrl = 'speeding';
  private readonly speedingUrl = 'speeding/percentage';
  private readonly hoursServiceUrl = 'hos';
  private readonly accidentsUrl = 'accident';
  private readonly injuryUrl = 'injury';
  private readonly inspectionUrl = 'inspection';
  private dateChangeSubject = new Subject<DateRange>();
  private accidentGridRefreshNeeded: Subject<void> = new Subject<void>();
  private injuryGridRefreshNeeded: Subject<void> = new Subject<void>();

  dateChange$ = this.dateChangeSubject.asObservable();

  constructor(private httpClient: HttpClient) {}

  refreshAccidentsGrid() {
    this.accidentGridRefreshNeeded.next();
  }

  getAccidentGridRefreshNeeded() {
    return this.accidentGridRefreshNeeded.asObservable();
  }

  refreshInjuryGrid() {
    this.injuryGridRefreshNeeded.next();
  }

  getInjuryGridRefreshNeeded() {
    return this.injuryGridRefreshNeeded.asObservable();
  }

  setDateRange(dateRange: DateRange) {
    this.dateChangeSubject.next(dateRange);
  }

  viewInit(top: number = 210): void {
    // 210 = 70 + 111 + 20
    this.markActive(top);
    let debounce: any;
    document.querySelector('article')?.addEventListener('scroll', (): void => {
      if (debounce) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => this.markActive(top), 0);
    });
  }

  markActive(top: number): void {
    const active: Element | null = document.querySelector('.active');
    active ? active.classList.remove('active') : null;
    const sections: NodeListOf<Element> = document.querySelectorAll(
      'section .header-block'
    );

    for (let i = 0; i < sections.length; i++) {
      if (this.getIsInViewport(sections[i], top)) {
        const li: Element | null = document.querySelector(
          'ul li:nth-child(' + (i + 1) + ')'
        );
        li?.classList.add('active');
        break;
      }
    }
  }

  getIsInViewport(elem: Element, top: number): boolean {
    return elem.getBoundingClientRect().top >= top;
  }

  onNavClick(elementId: string): void {
    document?.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getTelematicsSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
    page: number;
    pageSize: number;
  }): Observable<ITelematicsList> {
    const { startDate, endDate, driverId, page, pageSize } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const params = `driverId=${driverId}&startDate=${startStr}&endDate=${endStr}&PageNumber=${page}&PageSize=${pageSize}`;
    const url = `${this.baseUrl}/${this.telematicsUrl}?${params}`;
    return this.httpClient.get<ITelematicsList>(url);
  }

  getTelematicsSummaryDetail(recId: number): Observable<ITelematicsDetail> {
    const url = `${this.baseUrl}/${this.telematicsUrl}/${recId}`;
    return this.httpClient.get<ITelematicsDetail>(url);
  }
  getTelematicsSummaryVideo(recId: number): Observable<any> {
    const url = `${this.baseUrl}/${this.telematicsUrl}/video/${recId}`;
    return this.httpClient.get<any>(url);
  }

  getSpeedSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
    page: number;
    pageSize: number;
  }): Observable<ISpeedList> {
    const { startDate, endDate, driverId, page, pageSize } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const params = `driverId=${driverId}&startDate=${startStr}&endDate=${endStr}&PageNumber=${page}&PageSize=${pageSize}`;
    const url = `${this.baseUrl}/${this.speedUrl}?${params}`;
    return this.httpClient.get<ISpeedList>(url);
  }

  getSpeedSummaryDetail(recId: number): Observable<ISpeedElement> {
    const url = `${this.baseUrl}/${this.speedUrl}/${recId}`;
    return this.httpClient.get<ISpeedElement>(url);
  }

  getPercentageSpeedingSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
    page: number;
    pageSize: number;
  }): Observable<ISpeedPercentageList> {
    const { startDate, endDate, driverId, page, pageSize } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const params = `driverId=${driverId}&startDate=${startStr}&endDate=${endStr}&PageNumber=${page}&PageSize=${pageSize}`;
    const url = `${this.baseUrl}/${this.speedingUrl}?${params}`;
    return this.httpClient.get<ISpeedPercentageList>(url);
  }

  getPercentageSpeedingDetail(recId: number): Observable<ISpeedPercentageElement[]> {
    const url = `${this.baseUrl}/${this.speedingUrl}/${recId}`;
    return this.httpClient.get<ISpeedPercentageElement[]>(url);
  }

  getHoursServiceSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
    page: number;
    pageSize: number;
  }): Observable<IHoursServiceList> {
    const { startDate, endDate, driverId, page, pageSize } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const params = `driverId=${driverId}&startDate=${startStr}&endDate=${endStr}&PageNumber=${page}&PageSize=${pageSize}`;
    const url = `${this.baseUrl}/${this.hoursServiceUrl}?${params}`;
    return this.httpClient.get<IHoursServiceList>(url);
  }

  getHoursServiceDetail(recId: number): Observable<IHoursService[]> {
    const url = `${this.baseUrl}/${this.hoursServiceUrl}/${recId}`;
    return this.httpClient.get<IHoursService[]>(url);
  }

  getAccidentsSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
  }): Observable<IAccident[]> {
    const { startDate, endDate, driverId } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const url = `${this.baseUrl}/${this.accidentsUrl}?driverId=${driverId}&startDate=${startStr}&endDate=${endStr}`;
    return this.httpClient.get<IAccident[]>(url);
  }

  getAccidentDetail(id: string): Observable<any> {
    const url = `${this.baseUrl}/${this.accidentsUrl}/${id}`;
    return this.httpClient.get<any>(url);
  }

  getInjurySummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
  }): Observable<IInjury[]> {
    const { startDate, endDate, driverId } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const url = `${this.baseUrl}/${this.injuryUrl}?driverId=${driverId}&startDate=${startStr}&endDate=${endStr}`;
    return this.httpClient.get<IInjury[]>(url);
  }

  getInjuryDetail(id: string): Observable<any> {
    const url = `${this.baseUrl}/${this.injuryUrl}/${id}`;
    return this.httpClient.get<any>(url);
  }

  getInspectionSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
  }): Observable<IInspection[]> {
    const { startDate, endDate, driverId } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const url = `${this.baseUrl}/${this.inspectionUrl}?driverId=${driverId}&startDate=${startStr}&endDate=${endStr}`;
    return this.httpClient.get<IInspection[]>(url);
  }

  getInspectionDetail(reportNumber: string): Observable<IInspection[]> {
    const url = `${this.baseUrl}/${this.inspectionUrl}/${reportNumber}`;
    return this.httpClient.get<IInspection[]>(url);
  }

  getSeatbeltSummary(request: {
    startDate: Date;
    endDate: Date;
    driverId: string;
  }): Observable<any> {
    const { startDate, endDate, driverId } = request;
    const startStr = formatDate(startDate);
    const endStr = formatDate(endDate);
    const url = `${this.baseUrl}/${this.seatbeltUrl}?driverId=${driverId}&startDate=${startStr}&endDate=${endStr}`;
    return this.httpClient.get<any>(url);
  }
}
