import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticService {
  private readonly baseUrl = environment.apiUrl;
  private dataUpdateSubject = new BehaviorSubject<{
    terminal: string;
    startDate: string;
    endDate: string;
    terminationDateStart?: string;
    terminationDateEnd?: string;
    driverCategory?: Array<any>;
    driverStatus?: Array<any>;
    driverType?: Array<any>;
    typeFilter?: number | null;
    crashType?: Array<any>;
    accidentType?: string;
    injuryArea?: string;
    typeFilterName?: string;
  }>({
    terminal: '',
    startDate: '',
    endDate: '',
    terminationDateStart: '',
    terminationDateEnd: '',
    driverCategory: [],
    driverStatus: [],
    driverType: [],
    typeFilter: null,
    crashType: [],
    accidentType: '',
    injuryArea: '',
    typeFilterName: '',
  });

  private eventsDataUpdateSubject = new BehaviorSubject<{
    terminal: string;
    startDate: string;
    endDate: string;
    severity?: Array<any>;
    eventType?: Array<any>;
    eventStatus?: Array<any>;
    typeFilter?: string;
  }>({
    terminal: '',
    startDate: '',
    endDate: '',
    severity: [],
    eventType: [],
    eventStatus: [],
    typeFilter: '',
  });

  private interventionsDataUpdateSubject = new BehaviorSubject<{
    terminal: string;
    startDate: string;
    endDate: string;
    assignee?: Array<any>;
    status?: Array<any>;
    typeFilter?: number | null;
    scoreChangePeriod?: number | null;
    typeFilterName?: string;
  }>({
    terminal: '',
    startDate: '',
    endDate: '',
    assignee: [],
    status: [],
    typeFilter: null,
    scoreChangePeriod: null,
    typeFilterName: '',
  });

  private driverFilters = {
    driverScoreMin: null,
    driverScoreMax: null,
  };

  constructor(private http: HttpClient) {}

  sendDriversFiltersUpdate(driverScoreMin: any, driverScoreMax: any) {
    this.driverFilters = {
      driverScoreMin,
      driverScoreMax,
    };
  }

  getDriversFilters() {
    return this.driverFilters;
  }

  resetDriversFilters() {
    this.driverFilters = {
      driverScoreMin: null,
      driverScoreMax: null,
    };
  }

  sendDataUpdate(
    terminal: string,
    startDate: string,
    endDate: string,
    terminationDateStart?: string,
    terminationDateEnd?: string,
    driverCategory?: Array<any>,
    driverStatus?: Array<any>,
    driverType?: Array<any>,
    typeFilter?: number | null,
    crashType?: Array<any>,
    accidentType?: string,
    injuryArea?: string,
    typeFilterName?: string
  ) {
    this.dataUpdateSubject.next({
      terminal,
      startDate,
      endDate,
      terminationDateStart,
      terminationDateEnd,
      driverCategory,
      driverStatus,
      driverType,
      typeFilter,
      crashType,
      accidentType,
      injuryArea,
      typeFilterName,
    });
  }

  sendEventsDataUpdate(
    terminal: string,
    startDate: string,
    endDate: string,
    severity?: Array<any>,
    eventType?: Array<any>,
    eventStatus?: Array<any>,
    typeFilter?: string
  ) {
    this.eventsDataUpdateSubject.next({
      terminal,
      startDate,
      endDate,
      severity,
      eventType,
      eventStatus,
      typeFilter,
    });
  }

  sendInterventionsDataUpdate(
    terminal: string,
    startDate: string,
    endDate: string,
    assignee?: Array<any>,
    status?: Array<any>,
    typeFilter?: number | null,
    scoreChangePeriod?: number | null,
    typeFilterName?: string
  ) {
    this.interventionsDataUpdateSubject.next({
      terminal,
      startDate,
      endDate,
      assignee,
      status,
      typeFilter,
      scoreChangePeriod,
      typeFilterName,
    });
  }

  getDataUpdate() {
    return this.dataUpdateSubject.asObservable();
  }

  getEventsDataUpdate() {
    return this.eventsDataUpdateSubject.asObservable();
  }

  getInterventionsDataUpdate() {
    return this.interventionsDataUpdateSubject.asObservable();
  }

  getScores(terminal?: string, endDate?: string): Observable<any> {
    let params = new HttpParams();

    if (terminal) {
      params = params.set('terminal', terminal);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }
    params = params.set('organizationId', environment.organizationId);
    const url = `${this.baseUrl}/analytics/scores`;
    return this.http.get<any>(url, { params });
  }

  getScoreHistory(
    terminal?: string,
    startDate?: string,
    endDate?: string,
    selectedTimePeriod?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (terminal) {
      params = params.set('terminal', terminal);
    }

    if (startDate) {
      params = params.set('startDate', startDate);
    }

    if (endDate) {
      params = params.set('endDate', endDate);
    }

    if (selectedTimePeriod) {
      params = params.set('groupBy', selectedTimePeriod);
    }
    params = params.set('organizationId', environment.organizationId);
    const url = `${this.baseUrl}/analytics/scores/history`;
    return this.http.get<any>(url, { params });
  }

  getRetention(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      groupBy: 'groupBy',
    };
    const url = `${this.baseUrl}/analytics/retention?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDriverGeneral(terminal?: string, date?: string): Observable<any> {
    let params = new HttpParams();
    if (terminal) {
      params = params.set('terminal', terminal);
    }
    if (date) {
      params = params.set('date', date);
    }
    params = params.set('organizationId', environment.organizationId);
    const url = `${this.baseUrl}/analytics`;
    return this.http.get<any>(url, { params });
  }

  getDriversAccidents(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      accidentType: 'type',
      crashType: 'crashTypeDescription',
      groupBy: 'groupBy',
    };
    const url = `${this.baseUrl}/analytics/accidents?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDriversInjuries(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      groupBy: 'groupBy',
      injuryArea: 'injuryArea',
    };
    const url = `${this.baseUrl}/analytics/injury?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDriversInjuryByTerminalList(params?: any): Observable<any> {
    const paramMappings: any = {
      terminal: 'terminal',
      sortField: 'sortField',
      sortValue: 'sortValue',
      injuryArea: 'injuryArea',
      startDate: 'startDate',
      endDate: 'endDate',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
    };
    const url = `${this.baseUrl}/analytics/injury/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getAccidentsByTerminalList(params?: any): Observable<any> {
    const paramMappings: any = {
      eventType: 'eventType',
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      terminal: 'terminal',
      accidentType: 'type',
      crashType: 'crashTypeDescription',
    };
    const url = `${this.baseUrl}/analytics/accidents/terminal/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDriverAccidentsList(params?: any): Observable<any> {
    const paramMappings: any = {
      eventType: 'eventType',
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      terminal: 'terminal',
      accidentType: 'type',
      crashType: 'crashTypeDescription',
    };
    const url = `${this.baseUrl}/analytics/accidents/driver/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getAccidentsByDriverList(params?: any): Observable<any> {
    const paramMappings: any = {
      eventType: 'eventType',
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      terminal: 'terminal',
      accidentType: 'type',
      crashType: 'crashTypeDescription',
    };
    const url = `${this.baseUrl}/analytics/accidents/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDriverList(params?: any): Observable<any> {
    const paramMappings: any = {
      driverId: 'driverId',
      startDate: 'startDate',
      endDate: 'endDate',
      terminationDateStart: 'terminationDateStart',
      terminationDateEnd: 'terminationDateEnd',
      driverCategory: 'driverCategory',
      driverStatus: 'driverStatus',
      driverType: 'driverType',
      sortField: 'sortField',
      sortValue: 'sortValue',
      terminal: 'terminal',
      page: 'pageNumber',
      pageSize: 'pageSize',
    };
    const url = `${this.baseUrl}/analytics/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getEventGeneral(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      eventType: 'eventType',
      terminal: 'terminal',
      severity: 'severity',
    };
    const url = `${this.baseUrl}/analytics/events/general?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getEventSeverityByTerminalList(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      eventType: 'eventType',
      terminal: 'terminal',
      severity: 'severity',
      sortField: 'sortField',
      sortValue: 'sortValue',
      page: 'pageNumber',
      pageSize: 'pageSize',
    };
    const url = `${this.baseUrl}/analytics/events/terminal/severity?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getDataByEventsType(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      eventType: 'eventType',
      eventStatus: 'status',
      terminal: 'terminal',
    };
    const url = `${this.baseUrl}/analytics/events?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getEventsByTerminalList(params?: any): Observable<any> {
    const paramMappings: any = {
      eventType: 'eventType',
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      eventStatus: 'status',
      terminal: 'terminal',
    };
    const url = `${this.baseUrl}/analytics/events/terminal/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getEventsByDriverList(params?: any): Observable<any> {
    const paramMappings: any = {
      eventType: 'eventType',
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      eventStatus: 'status',
      terminal: 'terminal',
    };
    const url = `${this.baseUrl}/analytics/events/list?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key])
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  getAtAGlanceMetrics(params?: any): Observable<any> {
    const paramMappings: any = {
      lookBackPeriod: 'lookBackPeriod',
    };
    const url = `${this.baseUrl}/ataglancemetrics?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getInterventionsGeneral(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      status: 'status',
      terminal: 'terminal',
      assignee: 'assignee',
      groupBy: 'groupBy',
    };
    const url = `${this.baseUrl}/analytics/interventions?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getInterventionsByTerminalList(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      terminal: 'terminal',
      status: 'status',
      assignee: 'assignee',
    };
    const url = `${
      this.baseUrl
    }/analytics/interventions/terminal/list?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getInterventionsCoaching(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      groupBy: 'groupBy',
      terminal: 'terminal',
      status: 'status',
      assignee: 'assignee',
    };
    const url = `${this.baseUrl}/analytics/interventions/coaching?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getInterventionsCoachingList(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
      terminal: 'terminal',
      status: 'status',
      assignee: 'assignee',
      scoreChangePeriod: 'scoreChangePeriod',
    };
    const url = `${
      this.baseUrl
    }/analytics/interventions/coaching/list?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getInterventionsEffectiveness(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      status: 'status',
      evaluationDays: 'evaluationDays',
      assignee: 'assignee',
      groupBy: 'groupBy',
    };
    const url = `${
      this.baseUrl
    }/analytics/interventions/coaching/effectiveness?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getIntNegativeScoreChangesList(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      status: 'status',
      evaluationDays: 'evaluationDays',
      assignee: 'assignee',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
    };
    const url = `${
      this.baseUrl
    }/analytics/interventions/negative/list?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getDriverInterventionHistoryList(params?: any): Observable<any> {
    const paramMappings: any = {
      startDate: 'startDate',
      endDate: 'endDate',
      terminal: 'terminal',
      status: 'status',
      evaluationDays: 'evaluationDays',
      assignee: 'assignee',
      sortField: 'sortField',
      sortValue: 'sortValue',
      pageNumber: 'pageNumber',
      pageSize: 'pageSize',
    };
    const url = `${this.baseUrl}/analytics/interventions/list?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }
}
