<div [formGroup]="driverInfoForm">
  <div class="stepper-container">
    <!--Driver ID-->
    <mat-form-field appearance="outline">
      <mat-label>Driver ID</mat-label>
      <input
        #driverIdInput
        matInput
        formControlName="driverId"
        class="driverid-input"
      />
    </mat-form-field>
    <mat-error
      *ngIf="
        driverInfoForm.get('driverId')?.hasError('required') &&
        driverInfoForm.get('driverId')?.touched
      "
    >
      This field is required
    </mat-error>
    <div class="stepper-container driver" [class.loading]="isLoading">
      <!-- Spinner -->
      <mat-spinner
        *ngIf="isLoading"
        mode="indeterminate"
        [diameter]="50"
        class="overlay-spinner"
      ></mat-spinner>
      <h3>Driver Info</h3>
      <div class="row">
        <mat-label class="label">Driver Name</mat-label>
        <mat-form-field>
          <input matInput formControlName="driverName" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Organization</mat-label>
        <mat-form-field>
          <input matInput formControlName="organization" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Terminal</mat-label>
        <mat-form-field>
          <input matInput formControlName="terminal" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Fleet Manager</mat-label>
        <mat-form-field>
          <input matInput formControlName="fleetManager" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-label class="label">Safety Trainer</mat-label>
        <mat-form-field>
          <input matInput formControlName="safetyManager" />
        </mat-form-field>
      </div>
    </div>
    <h3 class="contact">Contact Info</h3>
    <mat-form-field appearance="outline">
      <mat-label>Cell #</mat-label>
      <input matInput formControlName="cellphone" />
    </mat-form-field>
    <mat-error
      *ngIf="
        driverInfoForm.get('cellphone')?.hasError('required') &&
        driverInfoForm.get('cellphone')?.touched
      "
    >
      This field is required
    </mat-error>
    <mat-error
      *ngIf="
        driverInfoForm.get('cellphone')?.hasError('invalidPhone') &&
        !driverInfoForm.get('cellphone')?.hasError('required') &&
        driverInfoForm.get('cellphone')?.touched
      "
    >
      Invalid phone number
    </mat-error>
  </div>
</div>
