import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { Router } from '@angular/router';

import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { toTitleCase } from '@app/shared/helpers/string.helper';
import { formatDate } from '@angular/common';
import { formatDateWithSlash } from '@app/shared/helpers/date.helper';
import { InterventionService } from '@app/services/intervention.service';
import { ToasterService } from '@app/services/toaster.service';
import { DialogActions } from '@app/models/dialog-info-sections';
import { Observable, debounceTime, map, of, startWith, switchMap } from 'rxjs';
import { DriverService } from '@app/services/driver.service';
import { MatSelect } from '@angular/material/select';
import { DriverIdService } from '@app/services/driver-id.service';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { DialogDriverComponent } from '@app/drivers/dialog-driver/dialog-driver.component';
import { FlyoutType } from '@app/models/fly-out.model';
import { PermissionService } from '@app/services/permission.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-edit-intervention',
  templateUrl: './edit-intervention.component.html',
  styleUrls: ['./edit-intervention.component.scss'],
})
export class EditInterventionComponent {
  @Input() data!: any;
  @Output() saveClicked = new EventEmitter<string>();
  @Output() interventionUpdated = new EventEmitter<any>();

  interventionForm!: FormGroup;
  currentStatus: string = '';
  dueDate!: string;
  priority!: string;
  filteredDrivers!: Observable<any[]>;
  driverSelected!: any;
  priorities = [
    { value: 'Low', label: 'Low', icon: 'fa-solid fa-arrow-down' },
    { value: 'Medium', label: 'Medium', icon: 'fa-solid fa-equals' },
    { value: 'High', label: 'High', icon: 'fa-solid fa-arrow-up' },
  ];
  toTitleCase = toTitleCase;

  @ViewChild('picker') datepicker!: MatDatepicker<Date>;
  @ViewChild('prioritySelect') prioritySelect!: MatSelect;

  constructor(
    private formBuilder: FormBuilder,
    private dialogEventService: DialogEventService,
    private dialog: MatDialog,
    private router: Router,
    private interventionService: InterventionService,
    private toasterService: ToasterService,
    private driverService: DriverService,
    private driverIdService: DriverIdService,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.setInterventionForm();
    this.setInterventionData();
    this.setSubsSearchDriverIdControl();
    this.setDriverSelected();
    this.currentStatus = this.data?.status;
  }

  setInterventionForm(): void {
    this.interventionForm = this.formBuilder.group({
      key: [{ value: this.data?.key, disabled: true }],
      reason: [this.data?.reason, Validators.required],
      driver: [this.capitalizeWords(this.data?.driverName), Validators.required],
    });
  }

  toUtcDate(date: any): string {
    return date ? formatDate(date, 'M/d/yyyy', 'en-US', '+0000') : '';
  }

  setInterventionData(): void {
    this.dueDate = this.data.dueDate ? this.toUtcDate(this.data.dueDate) : '';
    this.priority = this.data.priority ? this.data.priority : '';
  }

  openDatePicker(): void {
    this.datepicker.open();
  }

  getPriorityLabel(value: string): string {
    const priority = this.priorities.find((p) => p.value === value);
    return priority ? priority.label : '';
  }

  updateDueDate(event: any): void {
    this.dueDate = event ? formatDateWithSlash(event) : formatDateWithSlash(new Date());
    const request = {
      id: this.data?.id,
      dueDate: this.dueDate,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
    };
    this.updateIntervention(request, 'Due Date', DialogActions.UPDATE);
  }

  updateAssignedCoach(event: any): void {
    const request = {
      id: this.data?.id,
      status: this.data?.status,
      interventionType: this.data?.interventionType,
      priority: this.data?.priority,
      driverId: this.data?.driverID,
      terminal: this.data?.terminal,
      reason: this.data?.reason,
      dueDate: this.data?.dueDate,
      assignee: event.userId,
      assigneeName: event.firstName ? `${event.firstName} ${event.lastName}` : ' ',
      createdBy: this.data?.createdBy,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
      coachingScript: this.data?.coachingScript,
    };
    this.saveClicked.emit(DialogActions.UPDATE);
    this.interventionUpdated.emit(request);
  }

  updateIntervention(request: any, field: string, action: string): void {
    this.interventionService.upsertIntervention(request).subscribe({
      next: () => {
        this.saveClicked.emit(action);
        this.interventionUpdated.emit(request);
        this.toasterService.showSuccess(`The ${field} was updated successfully`);
      },
      error: (error) => console.error(error),
    });
  }

  getUserIdFromLocalStorage(): string {
    let userId = '';
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      userId = parsedData.userInfo.objectId;
    }
    return userId;
  }

  save(): void {
    const request = {
      id: this.data?.id,
      driverId: this.driverSelected?.driverId,
      driverName: this.driverSelected?.driverName,
      terminal: this.driverSelected?.terminal,
      reason: this.interventionForm.get('reason')!.value,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
    };
    this.updateIntervention(request, 'Intervention', DialogActions.UPDATE_CLOSE);
  }

  cancel(): void {
    this.saveClicked.emit(DialogActions.CANCEL);
  }

  handleStatusSelectionChange(newStatus: string): void {
    this.currentStatus = newStatus;
    this.dialogEventService.announceStatusSelectionChange(newStatus);
  }

  goToDriverProfile(): void {
    this.driverIdService.setDriverId(this.data?.driverID);
    const dialogWidth = calculateDialogWidth();
    this.dialog
      .open(DialogDriverComponent, {
        data: {
          ...this.data,
          driverId: this.data.driverID,
          fromEvent: true,
          uniqueIdentifier: FlyoutType.DRIVER,
        },
        position: {
          right: '0',
          top: '70px',
        },
        width: dialogWidth,
        minWidth: '810px',
        panelClass: ['dialog-driver', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.driverIdService.setDriverId(null);
      });
  }

  goToTerminal(terminal: string): void {
    this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
    this.router.navigate(['/drivers', terminal]);
  }

  // -- Driver methods --
  onSelectDriver(driver: any): void {
    this.driverSelected = driver;
    this.interventionForm
      .get('driver')!
      .setValue(this.capitalizeWords(this.driverSelected.driverName));
  }

  setSubsSearchDriverIdControl(): void {
    this.filteredDrivers = this.interventionForm.get('driver')!.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchDriverList(searchTerm))
    );
  }

  searchDriverList(searchTerm: string): Observable<any[]> {
    if (searchTerm) {
      const params = {
        searchField: searchTerm,
        pageSize: 15,
        pageNumber: 1,
        organizationId: environment.organizationId,
      };
      return this.driverService
        .getDriverList(params)
        .pipe(map((res: any) => res[0].driverSummaries));
    } else {
      return of([]);
    }
  }

  capitalizeWords(str: string): string {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  setDriverSelected(): void {
    this.driverSelected = {
      driverName: this.data?.driverName,
      driverId: this.data?.driverID,
      terminal: this.data?.terminal,
    };
  }
  // -- Driver methods --

  // -- Priority methods --
  onPrioritySelectionChange(): void {
    const request = {
      id: this.data?.id,
      priority: this.priority,
      lastModifiedBy: this.getUserIdFromLocalStorage(),
    };
    this.updateIntervention(request, 'Priority', DialogActions.UPDATE);
  }
  // -- Priority methods --

  canEditInterventions(): boolean {
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.getUserIdFromLocalStorage() === this.data?.assignee)
    );
  }
}
