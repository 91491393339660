import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]',
})
export class ImgFallbackDirective {
  @Input() public appImgFallback?: string;

  constructor(private readonly elementRef: ElementRef<HTMLImageElement>) {}

  @HostListener('error')
  loadFallbackOnError(): void {
    const element = this.elementRef.nativeElement;
    if (element.src !== this.fallbackImg) {
      element.src = this.fallbackImg;
    }
  }

  @HostListener('load')
  onLoad(): void {
    const element = this.elementRef.nativeElement;
    if (element.src !== this.fallbackImg) {
      element.classList.add('loaded');
    }
  }

  private get fallbackImg(): string {
    return this.appImgFallback || '/assets/images/fallback.png';
  }
}
