<div class="stepper-container w-full">
  <div [formGroup]="flaresHazmatForm">
    <h3>Did the accident occur on a roadway?*</h3>
    <mat-radio-group formControlName="accidentRoadway" required>
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <h3>
      Did you deploy emergency triangles or flares within 10 minutes of the
      accident?
    </h3>
    <mat-radio-group formControlName="emergencyTriangles">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <h3>Was fuel or hazardous materials cargo spilled?*</h3>
    <mat-radio-group formControlName="spilled" required>
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
