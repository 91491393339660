<div class="stepper-container w-500" [formGroup]="vehicleDamageForm">
  <h3>What is the truck number?*</h3>
  <div class="column">
    <mat-form-field appearance="outline">
      <mat-label>Truck Number</mat-label>
      <input type="number" matInput formControlName="truckNumber" />
    </mat-form-field>
  </div>

  <h3>What is the trailer number?</h3>
  <div class="column">
    <mat-form-field appearance="outline">
      <mat-label>Trailer Number</mat-label>
      <input type="number" matInput formControlName="trailerNumber" />
    </mat-form-field>
  </div>

  <h3>Was there damage to the vehicle?*</h3>
  <mat-radio-group formControlName="damage" class="column">
    <mat-radio-button
      *ngFor="let option of equipmentDamage"
      [value]="option.id"
    >
      {{ option.description }}
    </mat-radio-button>
  </mat-radio-group>

  <div
    *ngIf="
      vehicleDamageForm.get('damage')?.value === 2 ||
      vehicleDamageForm.get('damage')?.value === 3 ||
      vehicleDamageForm.get('damage')?.value === 4
    "
  >
    <h3>Please describe the extent of the damages</h3>
    <mat-form-field appearance="outline" class="textarea w-full">
      <textarea matInput formControlName="damageDescription"></textarea>
    </mat-form-field>

    <h3>What is the condition of the cargo?</h3>
    <div class="column">
      <mat-form-field appearance="outline">
        <mat-label>Cargo</mat-label>
        <input matInput formControlName="cargo" />
      </mat-form-field>
    </div>

    <h3>Does the vehicle need to be towed?*</h3>
    <mat-radio-group formControlName="towed">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>

    <div *ngIf="vehicleDamageForm.get('towed')?.value === 'true'">
      <h3>Please provide the tow information</h3>
      <mat-form-field appearance="outline" class="textarea w-full">
        <textarea matInput formControlName="towInfoDescription"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
