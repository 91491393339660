export interface SidenavList {
  title: string;
  link: string;
  icon: string;
  children?: Children[];
  permissionKey: string;
}

export interface Children {
  title: string;
  link: string;
  permissionKey: string;
}
