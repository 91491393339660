import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { StatusType } from '@app/models/status.model';
import { formatDateWithSlash } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-dialog-driver-events-filters',
  templateUrl: './dialog-driver-events-filters.component.html',
  styleUrls: ['./dialog-driver-events-filters.component.scss'],
})
export class DialogDriverEventsFiltersComponent implements OnInit {
  filtersForm!: FormGroup;
  date: string = '';

  // prettier-ignore
  eventTypes = [
    { id: 'Accident', name: 'Accident' },
    { id: 'HOS', name: 'HOS' },
    { id: 'Inspection', name: 'Inspection' },
    { id: 'Seatbelt', name: 'Seatbelt' },
    { id: 'Speeding', name: 'Speeding' },
    { id: 'Telematics', name: 'Telematics' },
  ];

  // prettier-ignore
  severityList = [
    { value: 'minor', viewValue: 'Minor', icon: 'fa-solid fa-chevron-down icon-gray icon-default-size' },
    { value: 'moderate', viewValue: 'Moderate', icon: 'fa-solid fa-equals icon-orange icon-default-size' },
    { value: 'significant', viewValue: 'Significant', icon: 'fa-solid fa-chevron-up icon-red icon-default-size' },
    { value: 'critical',viewValue: 'Critical', icon: 'fa-solid fa-angle-double-up icon-red icon-default-size' },
  ];

  // prettier-ignore
  statuses = [
    {
      value: StatusType.OPEN.toLowerCase(),
      viewValue: StatusType.OPEN,
      icon: 'fa-solid fa-circle-notch',
    },
    {
      value: StatusType.ASSIGNED.toLowerCase(),
      viewValue: StatusType.ASSIGNED,
      icon: 'fa-solid fa-user-circle icon-second-blue',
    },
    {
      value: StatusType.CLOSED.toLowerCase(),
      viewValue: StatusType.CLOSED,
      icon: 'fa-solid fa-circle-xmark icon-red icon-default-size',
    },
    {
      value: StatusType.NON_EVENT.toLowerCase(),
      viewValue: StatusType.NON_EVENT,
      icon: 'fa-solid fa-triangle-exclamation icon-red icon-default-size',
      disabled: false,
    },
  ];

  dateRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(
    private dialogRef: MatDialogRef<DialogDriverEventsFiltersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder
  ) {
    this.dateRange = this.fb.group({
      start: [data?.startDate ? new Date(data?.startDate) : null],
      end: [data?.endDate ? new Date(data?.endDate) : null],
    });
    this.filtersForm = this.fb.group({
      startDate: [data?.startDate],
      endDate: [data?.endDate],
      type: [data?.type],
      severity: [data?.severity],
      status: [data?.status],
    });
  }

  ngOnInit(): void {
    this.date = this.data?.date;
  }

  updateDate(date: string): void {
    this.filtersForm.get('date')?.setValue(date);
  }

  onApply(): void {
    const result = {
      form: this.filtersForm.value,
    };
    this.dialogRef.close(result);
  }

  onClearAll(): void {
    this.dialogRef.close('clearAll');
  }

  onDateRangeSelected(range: { start: Date | null; end: Date | null }) {
    this.dateRange.setValue({
      start: range.start,
      end: range.end,
    });
    if (range.start && range.end) {
      this.filtersForm
        .get('startDate')
        ?.setValue(formatDateWithSlash(this.dateRange.get('start')?.value));
      this.filtersForm
        .get('endDate')
        ?.setValue(formatDateWithSlash(this.dateRange.get('end')?.value));
    } else {
      this.filtersForm.get('startDate')?.setValue(this.dateRange.get('start')?.value);
      this.filtersForm.get('endDate')?.setValue(this.dateRange.get('end')?.value);
    }
  }
}
