import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';

@Component({
  selector: 'app-step-other-party',
  templateUrl: './step-other-party.component.html',
  styleUrls: ['./step-other-party.component.scss'],
})
export class StepOtherPartyComponent implements OnInit {
  @Input() otherPartyForm!: FormGroup;

  involved: any = [];

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.getOthersInvolvedList();
    this.setSubscriptionOtherPartyInvolved();
    this.setSubsOtherIndividualInjured();
  }

  getOthersInvolvedList(): void {
    this.driverService.getAccidentOptionsList('OthersInvolved').subscribe({
      next: (res) => {
        this.involved = res.sort((a: any, b: any) => a.id - b.id);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setSubscriptionOtherPartyInvolved(): void {
    // prettier-ignore
    this.otherPartyForm.get('involved')?.valueChanges.subscribe((value) => {
      const fields = [
        'otherIndividualDescription',
        'fatalityInvolved',
        'otherIndividualInjured',
        'extentInjuriesDescription',
        'otherMedicalAssistance',
        'otherVehicleDamage',
        'extentDamagesDescription',
        'otherVehicleTowed',
        'towDescription',
      ];
      for (let field of fields) {
        this.otherPartyForm.get(field)?.clearValidators();
        this.otherPartyForm.get(field)?.reset();
      }
      switch (value) {
        case '1':
          break;
        case '2':
          this.otherPartyForm.get('fatalityInvolved')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherIndividualInjured')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherMedicalAssistance')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherVehicleDamage')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherVehicleTowed')?.setValidators(Validators.required);
          break;
        case '3':
          this.otherPartyForm.get('otherVehicleDamage')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherVehicleTowed')?.setValidators(Validators.required);
          break;
        case '4':
          this.otherPartyForm.get('fatalityInvolved')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherIndividualInjured')?.setValidators(Validators.required);
          this.otherPartyForm.get('otherMedicalAssistance')?.setValidators(Validators.required);
          break;
        default:
          break;
      }

      for (let field of fields) {
        this.otherPartyForm.get(field)?.updateValueAndValidity();
      }
    });
  }

  setSubsOtherIndividualInjured(): void {
    // prettier-ignore
    this.otherPartyForm.get('otherIndividualInjured')?.valueChanges.subscribe((value) => {
      if (value === 'true') {
        this.otherPartyForm.get('extentInjuriesDescription')?.setValidators(Validators.required);
      } else {
        this.otherPartyForm.get('extentInjuriesDescription')?.clearValidators();
        this.otherPartyForm.get('extentInjuriesDescription')?.reset();
      }
      this.otherPartyForm.get('extentInjuriesDescription')?.updateValueAndValidity();
    });
  }
}
