import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { getDocument, GlobalWorkerOptions, PageViewport, version } from 'pdfjs-dist';

declare global {
  export type SVGGraphics = any;
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent {
  @Input() contents: string = '';
  @Input() hideToolbar: boolean = false;
  @Output() contentsChange = new EventEmitter<string>();

  @ViewChild('pdfUploadInput') pdfUploadInput: ElementRef | null = null;

  constructor() {
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
  }

  handlePdfUpload(): void {
    this.pdfUploadInput?.nativeElement?.click();
  }

  contentChanged(event: any): void {
    this.contentsChange.emit(event.html);
  }

  async onFileInputChange(event: any): Promise<void> {
    const { files } = event?.target;
    if (files?.length) {
      const arrayBuffer: ArrayBuffer = await this.fileToArrayBuffer(files[0]);
      const base64Images = await this.convertPdfToBase64Images(arrayBuffer);
      base64Images.forEach((imgSrc: string) => {
        this.contents += `<img src="${imgSrc}"/>`;
      });
    }
  }

  private async fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = (error) => reject(error);
    });
  }

  private async fileToBase64String(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  private async convertPdfToBase64Images(pdfFile: ArrayBuffer): Promise<string[]> {
    const images: string[] = [];
    const pdf = await getDocument({ data: pdfFile }).promise;
    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = {
        canvasContext: context as CanvasRenderingContext2D,
        viewport: viewport as PageViewport,
      };
      await page.render(renderContext).promise;
      images.push(canvas.toDataURL('image/png'));
    }
    return images;
  }
}
