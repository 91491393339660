import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
})
export class TextareaComponent {
  @Input() disabled?: boolean;
  @Input() width?: string;
  @Input() height?: string;
  @Input() label?: string;
  @Input() margin?: string;
  @Input() value?: string = '';

  constructor() {}

  get styles(): { [key: string]: string } {
    const params: any = {};
    if (this.width?.length) {
      params.width = this.width;
    }
    if (this.height?.length) {
      params.height = this.height;
    }
    if (this.margin?.length) {
      params.margin = this.margin;
    }
    return params;
  }
}
