import { Section } from '@app/models';

export const TabDetailsSections: Section[] = [
  { title: 'Coaching Script', id: 'coaching-plan' },
  { title: 'Linked Events', id: 'linked-events' },
  { title: 'Notes', id: 'notes-coaching' },
];

export const TabHistorySections: Section[] = [
  { title: 'Events', id: 'events' },
  { title: 'Coaching', id: 'coaching' },
];
