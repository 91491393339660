<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Percentage Speeding</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="percentage-speeding-container">
    <div class="main-title">Activity Details</div>
    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <!-- Activity Details Table -->
    <table *ngIf="!isLoading" mat-table [dataSource]="activityDetailsSummary">
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">{{ element?.date }}</td>
      </ng-container>

      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef>Day</th>
        <td mat-cell *matCellDef="let element">{{ element?.day }}</td>
      </ng-container>

      <ng-container matColumnDef="totalDistanceTraveled">
        <th mat-header-cell *matHeaderCellDef>Total Distance Traveled</th>
        <td mat-cell *matCellDef="let element">
          {{
            element?.totalDistanceTraveled !== ""
              ? element?.totalDistanceTraveled + " miles"
              : "-"
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="driveTime">
        <th mat-header-cell *matHeaderCellDef>Drive Time</th>
        <td mat-cell *matCellDef="let element">{{ element?.driveTime }}</td>
      </ng-container>

      <ng-container matColumnDef="maxSpeed">
        <th mat-header-cell *matHeaderCellDef>Percent Speeding</th>
        <td mat-cell *matCellDef="let element">{{ element?.maxSpeed }}%</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="activityDetailsColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: activityDetailsColumns"
        class="no-hover"
      ></tr>
    </table>

    <div
      *ngIf="!isLoading && activityDetailsSummary === undefined"
      class="no-records-found"
    >
      <p>No records found.</p>
    </div>

    <div class="main-title breakdown">Breakdown</div>
    <div *ngIf="isLoading" class="spinner-container breakdown">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <!-- Breakdown Table -->
    <!-- SAMSARA Source -->
    <div *ngIf="source === percentageSpeedingSource.SAMSARA">
      <table *ngIf="!isLoading" mat-table [dataSource]="breakdownSummary">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="breakdown-highlight">
            {{ element?.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef>Percentage</th>
          <td mat-cell *matCellDef="let element">{{ element?.percentage }}%</td>
        </ng-container>

        <ng-container matColumnDef="time">
          <th mat-header-cell *matHeaderCellDef>Time</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.time }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="breakdownColumnsSamsara"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: breakdownColumnsSamsara"
          class="no-hover"
        ></tr>
      </table>
    </div>
    <!-- SPEEDGAUGE Source -->
    <div *ngIf="source === percentageSpeedingSource.SPEEDGUAGE">
      <table *ngIf="!isLoading" mat-table [dataSource]="breakdownSummary">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="breakdown-highlight">
            {{ element?.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="incidents">
          <th mat-header-cell *matHeaderCellDef>Incidents</th>
          <td mat-cell *matCellDef="let element">{{ element?.incidents }}</td>
        </ng-container>

        <ng-container matColumnDef="observations">
          <th mat-header-cell *matHeaderCellDef>Observations</th>
          <td mat-cell *matCellDef="let element">{{ element?.observations }}</td>
        </ng-container>

        <ng-container matColumnDef="percentage">
          <th mat-header-cell *matHeaderCellDef>Percentage</th>
          <td mat-cell *matCellDef="let element">
            {{
              element?.percentage !== undefined && element?.percentage !== null
                ? element?.percentage + "%"
                : "-"
            }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="breakdownColumnsSpeedguage"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: breakdownColumnsSpeedguage"
          class="no-hover"
        ></tr>
      </table>
    </div>

    <div *ngIf="!isLoading && breakdownSummary === undefined" class="no-records-found">
      <p>No records found.</p>
    </div>

    <div *ngIf="source" class="footer-text">Source: {{ source }}</div>
  </div>
</mat-dialog-content>
