import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usPhoneFormat',
  standalone: true,
})
export class UsPhoneFormatPipe implements PipeTransform {
  transform(phoneValue: string): string {
    if (!phoneValue) {
      return '';
    }

    const value = phoneValue.replace(/\D/g, '');
    const formattedValue = value.slice(0, 10);
    const areaCode = formattedValue.slice(0, 3);
    const middle = formattedValue.slice(3, 6);
    const last = formattedValue.slice(6, 10);

    return `(${areaCode}) ${middle}-${last}`;
  }
}
