import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RadioButtonReactiveComponent } from './radio-button-reactive.component';

@NgModule({
  declarations: [RadioButtonReactiveComponent],
  imports: [CommonModule, FormsModule],
  exports: [RadioButtonReactiveComponent],
})
export class RadioButtonReactiveModule {}
