<table mat-table matSort [dataSource]="dataFiltered" class="table-header-sticky">
  <!-- Percentage Speeding columns-->
  <ng-container matColumnDef="date" *ngIf="sectionName === 'percentage-speeding'">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="milesDriven" *ngIf="sectionName === 'percentage-speeding'">
    <th mat-header-cell *matHeaderCellDef>Miles Driven</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalDriveDistance !== "" ? element?.totalDriveDistance : "-" }}
    </td>
  </ng-container>

  <ng-container
    matColumnDef="evaluationPeriod"
    *ngIf="sectionName === 'percentage-speeding'"
  >
    <th mat-header-cell *matHeaderCellDef>Evaluation Period</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalDriveTime !== "" ? element?.totalDriveTime : "-" }}
    </td>
  </ng-container>

  <ng-container
    matColumnDef="speedingPeriod"
    *ngIf="sectionName === 'percentage-speeding'"
  >
    <th mat-header-cell *matHeaderCellDef>Speeding Period</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.totalSpeedingEvents !== "" ? element?.totalSpeedingEvents : "-" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="speeding" *ngIf="sectionName === 'percentage-speeding'">
    <th mat-header-cell *matHeaderCellDef>% Speeding</th>
    <td mat-cell *matCellDef="let element">{{ element?.totalPercentSpeeding }}%</td>
  </ng-container>

  <!-- Speeding Egregious columns-->
  <ng-container matColumnDef="date" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.eventDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.eventDate | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="posted" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Posted</th>
    <td mat-cell *matCellDef="let element">{{ element?.posted }}</td>
  </ng-container>

  <ng-container matColumnDef="actual" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Actual</th>
    <td mat-cell *matCellDef="let element">{{ element?.acutal }}</td>
  </ng-container>

  <ng-container matColumnDef="difference" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Difference</th>
    <td mat-cell *matCellDef="let element">{{ element?.difference }}</td>
  </ng-container>

  <ng-container matColumnDef="category" *ngIf="sectionName === 'speeding-events'">
    <th mat-header-cell *matHeaderCellDef>Category</th>
    <td mat-cell *matCellDef="let element">{{ element?.category }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    class="clickable"
    (click)="onRowClick(row)"
  ></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
