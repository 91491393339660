import { Component, Input } from '@angular/core';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';

@Component({
  selector: 'app-print-accident-data',
  templateUrl: './print-accident-data.component.html',
  styleUrls: ['./print-accident-data.component.scss'],
})
export class PrintAccidentDataComponent {
  @Input() accidentData: any;
  @Input() crashTypeOptions: any[] = [];
  @Input() assetLocationOptions: any[] = [];
  @Input() equipmentDamageOptions: any[] = [];
  @Input() othersInvolvedOptions: any[] = [];

  private utcDatePipe = new UtcDatePipe();

  shouldShowActionItems(): boolean {
    return (
      this.showCallHazmat() ||
      this.showCallGeneOrLaura() ||
      this.showScheduleAccidentTesting()
    );
  }

  showCallHazmat(): boolean {
    return this.accidentData?.fuelHazardousSpilled;
  }

  showCallGeneOrLaura(): boolean {
    return this.accidentData?.fatalityInvolved;
  }

  showScheduleAccidentTesting(): boolean {
    return (
      this.accidentData?.fatalityInvolved ||
      this.accidentData?.othersRequireMedical ||
      this.accidentData?.othersVehicleTowed ||
      this.accidentData?.assetsLocated === 1
    );
  }

  // prettier-ignore
  getFormattedData() {
    this.accidentData.accidentDate = this.utcDatePipe.transform(
      this.accidentData.accidentDate,
      'M/d/yyyy h:mm A'
    );

    return [
      // "Action Items" section
      ...(this.shouldShowActionItems()
        ? [
            { section: true, label: 'Action Items', fullRow: true },
            ...(this.showCallHazmat()
              ? [
                  {
                    label:
                      'Call Hazmat Response that fuel or hazardous materials have spilled: 888-742-2885',
                    value: this.getYesNoValue(this.accidentData?.fuelHazardousSpilled),
                    isSubheading: false,
                    fullRow: true,
                  },
                ]
              : []),
            ...(this.showCallGeneOrLaura()
              ? [
                  {
                    label: 'Call Gene or Laura to inform them of the fatality.',
                    value: this.getYesNoValue(this.accidentData?.fatalityInvolved),
                    isSubheading: false,
                    fullRow: true,
                  },
                ]
              : []),
            ...(this.showScheduleAccidentTesting()
              ? [
                  {
                    label: 'Schedule accident testing with the driver.',
                    value: this.getYesNoValue(this.accidentData?.scheduleDriverTesting),
                    isSubheading: false,
                    fullRow: true,
                  },
                ]
              : []),
          ]
        : []),

      // "Driver Info" section
      { section: true, label: 'Driver Info', fullRow: true },
      { label: 'Driver ID', value: this.accidentData?.driverId, column: 'left' },
      { label: 'Driver Name', value: this.accidentData?.driverName, column: 'right' },
      { label: 'Driver Phone', value: this.accidentData?.driverPhone, fullRow: true },
      { label: 'Organization', value: this.accidentData?.organization, column: 'left' },
      { label: 'Terminal', value: this.accidentData?.terminal, column: 'right' },
      { label: 'Fleet Manager', value: this.accidentData?.fleetManager, column: 'left' },
      {
        label: 'Safety Trainer',
        value: this.accidentData?.safetyManager,
        column: 'right',
      },

      // "Time and Place" section
      { section: true, label: 'Time and Place', fullRow: true },
      {
        label: 'Time of Accident',
        value: this.accidentData?.accidentDate,
        column: 'left',
      },
      { label: 'Reported Time', value: this.accidentData?.creationDate, column: 'right' },
      {
        label: 'Address Line 1',
        value: this.accidentData?.accidentAddress1,
        fullRow: true,
      },
      {
        label: 'Address Line 2',
        value: this.accidentData?.accidentAddress2,
        fullRow: true,
      },
      { label: 'City', value: this.accidentData?.accidentCity, column: 'left' },
      { label: 'State', value: this.accidentData?.accidentState, column: 'right' },
      { label: 'Zip', value: this.accidentData?.accidentZip, column: 'left' },
      {
        label: 'Other Location Details',
        value: this.accidentData?.accidentLocationOtherDetails,
        fullRow: true,
      },

      // "Accident Details" section
      { section: true, label: 'Accident Details', fullRow: true },
      { label: 'Type of Accident', value: this.getCrashTypeDescription(), fullRow: true },
      {
        label: 'Location of Assets',
        value: this.getAssetLocationDescription(),
        fullRow: true,
      },
      {
        label: 'Occur on Roadway',
        value: this.getYesNoValue(this.accidentData?.accidentOnRoadway),
        column: 'left',
      },
      {
        label: 'Hazmat',
        value: this.getYesNoValue(this.accidentData?.fuelHazardousSpilled),
        column: 'right',
      },
      {
        label: 'Deployed Flares',
        value: this.getYesNoValue(this.accidentData?.deployedTriangleFlares),
        column: 'left',
      },
      {
        label: 'Accident Description',
        value: this.accidentData?.accidentDescription,
        fullRow: true,
      },
      {
        label: 'Witness Information',
        value: this.accidentData?.witnessInformation,
        fullRow: true,
      },

      // "Driver Injuries and Damages" section with sub-subheadings
      { section: true, label: 'Driver Injuries and Damages', fullRow: true },
      { isSubSubheading: true, label: 'Injuries', fullRow: true },
      {
        label: 'Injured',
        value: this.getYesNoValue(this.accidentData?.driverInjured),
        column: 'left',
      },
      {
        label: 'Medical Assistance',
        value: this.getYesNoValue(this.accidentData?.driverRequireMedical),
        column: 'right',
      },
      {
        label: 'Extent of Injuries',
        value: this.accidentData?.driverInjuryDescription,
        fullRow: true,
      },
      { isSubSubheading: true, label: 'Damages', fullRow: true },
      { label: 'Truck', value: this.accidentData?.driverTruckNumber, column: 'left' },
      {
        label: 'Trailer',
        value: this.accidentData?.driverTrailerNumber,
        column: 'right',
      },
      {
        label: 'What was Damaged',
        value: this.getEquipmentDamageDescription(),
        column: 'left',
      },
      {
        label: 'Cargo Condition',
        value: this.accidentData?.driverCargoCondition,
        column: 'right',
      },
      {
        label: 'Extent of Damages',
        value: this.accidentData?.driverEquipmentDamageDescription,
        fullRow: true,
      },
      {
        label: 'Towed',
        value: this.getYesNoValue(this.accidentData?.driverVehicleTowed),
        fullRow: true,
      },
      {
        label: 'Towing Information',
        value: this.accidentData?.driverVehicleTowedInformation,
        fullRow: true,
      },

      // "Other Party" section with sub-subheadings
      { section: true, label: 'Other Party', fullRow: true },
      { isSubSubheading: true, label: 'Information', fullRow: true },
      {
        label: 'Who was Involved',
        value: this.getWhoWasInvolvedDescription(),
        fullRow: true,
      },
      {
        label: 'Other Party Information',
        value: this.accidentData?.othersInvolvedDetails,
        fullRow: true,
      },
      { isSubSubheading: true, label: 'Injuries', fullRow: true },
      {
        label: 'Injured',
        value: this.getYesNoValue(this.accidentData?.othersInjured),
        column: 'left',
      },
      {
        label: 'Medical Assistance',
        value: this.getYesNoValue(this.accidentData?.othersRequireMedical),
        column: 'right',
      },
      {
        label: 'Fatality',
        value: this.getYesNoValue(this.accidentData?.fatalityInvolved),
        column: 'left',
      },
      {
        label: 'Extent of Injuries',
        value: this.accidentData?.othersInjuryDescription,
        fullRow: true,
      },
      { isSubSubheading: true, label: 'Damages', fullRow: true },
      {
        label: 'Property Damage',
        value: this.getYesNoValue(this.accidentData?.othersVehiclePropertyDamage),
        fullRow: true,
      },
      {
        label: 'Extent of Damages',
        value: this.accidentData?.othersVehiclePropertyDamageDescription,
        fullRow: true,
      },
      {
        label: 'Towed',
        value: this.getYesNoValue(this.accidentData?.othersVehicleTowed),
        fullRow: true,
      },
      {
        label: 'Towing Information',
        value: this.accidentData?.othersVehicleTowedInformation,
        fullRow: true,
      },

      // "Police" section
      { section: true, label: 'Police', fullRow: true },
      {
        label: 'Police Called',
        value: this.getYesNoValue(this.accidentData?.policeCalled),
        column: 'left',
      },
      {
        label: 'Citation',
        value: this.getYesNoValue(this.accidentData?.citationReceived),
        column: 'right',
      },
      {
        label: 'Police Report #',
        value: this.accidentData?.policeReportNumber,
        fullRow: true,
      },
      {
        label: 'Responding Officer Information',
        value: this.accidentData?.policeOfficerInformation,
        fullRow: true,
      },

      // "System" section
      { section: true, label: 'System', fullRow: true },
      { label: 'Created By', value: this.accidentData?.createdBy, column: 'left' },
      { label: 'Creation Date', value: this.accidentData?.creationDate, column: 'right' },
      {
        label: 'Last Modified By',
        value: this.accidentData?.lastModifiedBy,
        column: 'left',
      },
      {
        label: 'Last Modified Date',
        value: this.accidentData?.lastModifiedDate,
        column: 'right',
      },
    ];
  }

  // Helper methods to retrieve the description based on IDs or boolean values
  getCrashTypeDescription(): string {
    const crashType = this.crashTypeOptions.find(
      (option) => option.id === this.accidentData?.crashType
    );
    return crashType ? crashType.description : '';
  }

  getAssetLocationDescription(): string {
    const assetLocation = this.assetLocationOptions.find(
      (option) => option.id === this.accidentData?.assetsLocated
    );
    return assetLocation ? assetLocation.description : '';
  }

  getEquipmentDamageDescription(): string {
    const damage = this.equipmentDamageOptions.find(
      (option) => option.value === this.accidentData?.driverEquipmentDamage
    );
    return damage ? damage.label : '';
  }

  getWhoWasInvolvedDescription(): string {
    const involved = this.othersInvolvedOptions.find(
      (option) => option.value === this.accidentData?.othersInvolved
    );
    return involved ? involved.label : '';
  }

  getYesNoValue(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}
