<div class="header-block">
  <h2>Telematics</h2>
</div>

<table
  mat-table
  matSort
  [dataSource]="telematicsDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="video">
    <th mat-header-cell *matHeaderCellDef>Video</th>
    <td mat-cell *matCellDef="let element">
      <i class="fa-solid fa-circle-play video-icon" *ngIf="element.video"></i>
    </td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef>Type</th>
    <td mat-cell *matCellDef="let element">
      {{ addSpacesToCamelCase(element?.eventName) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef>Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "h:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="telematicsColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: telematicsColumns"
    (click)="onRowClick(row)"
    class="clickable"
    [class.row-selected]="isRowSelected('telematics', row.id)"
  ></tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div class="paginator-container" *ngIf="!isViewMore">
  <div class="paginator-text">
    Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
  </div>
  <mat-paginator
    [pageSize]="pageEvent.pageSize"
    [length]="paginationParams.total"
    [hidePageSize]="true"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>

<div *ngIf="!isLoading && telematicsDataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
