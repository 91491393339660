import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

export interface Item {
  id: string;
  name: string;
  state: boolean;
  x: number;
  y: number;
}
@Component({
  selector: 'app-step-injury-description',
  templateUrl: './step-injury-description.component.html',
  styleUrls: ['./step-injury-description.component.scss'],
})
export class StepInjuryDescriptionComponent {
  @Input() injuryDescriptionForm!: FormGroup;

  // prettier-ignore
  items: Item[] = [
    { id: 'front-center-1', name: 'Face', state: false, x: 153, y: 36 },
    { id: 'front-center-2', name: 'Chest', state: false, x: 152, y: 156 },
    { id: 'front-center-3', name: 'Abdomen', state: false, x: 154, y: 236 },
    { id: 'front-center-3', name: 'Pelvic Area', state: false, x: 154, y: 280 },

    { id: 'front-left-1', name: 'Left Shoulder', state: false, x: 200, y: 124 },
    { id: 'front-left-2', name: 'Left Upper Arm', state: false, x: 219, y: 182 },
    { id: 'front-left-3', name: 'Left Lower Arm', state: false, x: 229, y: 250 },
    { id: 'front-left-4', name: 'Left Hand', state: false, x: 243, y: 317 },
    { id: 'front-left-5', name: 'Left Hip', state: false, x: 186, y: 280 },
    { id: 'front-left-6', name: 'Left Front Upper Leg', state: false, x: 186, y: 345 },
    { id: 'front-left-7', name: 'Left Knee', state: false, x: 173, y: 414 },
    { id: 'front-left-8', name: 'Left Front Lower Leg', state: false, x: 172, y: 479 },
    { id: 'front-left-9', name: 'Left Foot', state: false, x: 169, y: 538 },

    { id: 'front-right-1', name: 'Right Shoulder', state: false, x: 104, y: 124 },
    { id: 'front-right-2', name: 'Right Upper Arm', state: false, x: 87, y: 182 },
    { id: 'front-right-3', name: 'Right Lower Arm', state: false, x: 77, y: 250 },
    { id: 'front-right-4', name: 'Right Hand', state: false, x: 62, y: 318 },
    { id: 'front-right-5', name: 'Right Hip', state: false, x: 120, y: 282 },
    { id: 'front-right-6', name: 'Right Front Upper Leg', state: false, x: 121, y: 345 },
    { id: 'front-right-7', name: 'Right Knee', state: false, x: 134, y: 414 },
    { id: 'front-right-8', name: 'Right Front Lower Leg', state: false, x: 136, y: 479 },
    { id: 'front-right-9', name: 'Right Foot', state: false, x: 139, y: 538 },

    { id: 'back-center-1', name: 'Head', state: false, x: 410, y: 36 },
    { id: 'back-center-2', name: 'Neck', state: false, x: 410, y: 99 },
    { id: 'back-center-3', name: 'Upper Back', state: false, x: 410, y: 148 },
    { id: 'back-center-4', name: 'Lower Back', state: false, x: 410, y: 224 },
    { id: 'back-center-5', name: 'Glute', state: false, x: 410, y: 284 },

    { id: 'back-left-1', name: 'Left Elbow', state: false, x: 338, y: 224 },
    { id: 'back-left-2', name: 'Left Rear Upper Leg', state: false, x: 382, y: 346 },
    { id: 'back-left-3', name: 'Left Rear Lower Leg', state: false, x: 388, y: 465 },
    { id: 'back-left-4', name: 'Left Ankle', state: false, x: 390, y: 536 },
    { id: 'back-left-5', name: 'Left Wrist', state: false, x: 324, y: 295 },

    { id: 'back-right-1', name: 'Right Elbow', state: false, x: 481, y: 226 },
    { id: 'back-right-2', name: 'Right Rear Upper Leg', state: false, x: 437, y: 346 },
    { id: 'back-right-3', name: 'Right Rear Lower Leg', state: false, x: 432, y: 465 },
    { id: 'back-right-4', name: 'Right Ankle', state: false, x: 430, y: 536 },
    { id: 'back-right-4', name: 'Right Wrist', state: false, x: 495, y: 295 },
  ];
  humanBodyUrl: string = '/assets/images/human-body-front-back.png';
  private tooltipId: number | null | undefined;

  constructor() {}

  get isHasChecked(): boolean {
    return !!this.items.find(({ state }) => state);
  }

  onCheckboxToggle(index: number): void {
    this.items[index].state = !this.items[index].state;
    if (this.areAllStatesFalse()) {
      this.injuryDescriptionForm.get('areaBodyInjured')?.setValue(null);
    } else {
      this.injuryDescriptionForm.get('areaBodyInjured')?.setValue(this.items);
    }
  }

  areAllStatesFalse(): boolean {
    return this.items.every((item) => !item.state);
  }

  handleTooltip(id: number): void {
    this.tooltipId = id;
  }

  leaveTooltip(): void {
    this.tooltipId = null;
  }

  isHandleTooltip(id: number): boolean {
    return this.tooltipId === id;
  }

  onChipResetClick(): void {
    this.items.forEach((item: Item): void => {
      item.state = false;
    });
    this.injuryDescriptionForm.get('areaBodyInjured')?.setValue(null);
  }

  isMedicalAssistanceRequired(): boolean {
    return (
      this.injuryDescriptionForm.get('medicalAssistanceRequired')?.value ===
      'true'
    );
  }
}
