<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Filters</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="content-container">
    <form [formGroup]="filtersForm" class="filters-form">
      <!-- Date -->
      <h5 class="title-date">Date Range</h5>
      <app-date-range-picker
        positionOffset="right"
        [startDate]="dateRange.controls.start.value"
        [endDate]="dateRange.controls.end.value"
        (dateRangeSelected)="onDateRangeSelected($event)"
      >
      </app-date-range-picker>

      <mat-divider></mat-divider>

      <!-- Event Type -->
      <h5 class="title">Event Type</h5>
      <mat-form-field class="input-size" appearance="outline">
        <mat-select placeholder="Select..." formControlName="type" multiple>
          <mat-option
            *ngFor="let eventType of eventTypes"
            [value]="eventType.id"
          >
            {{ eventType.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-divider></mat-divider>

      <!-- Severity -->
      <h5 class="title">Severity</h5>
      <mat-form-field class="input-size" appearance="outline">
        <mat-select placeholder="Select..." formControlName="severity" multiple>
          <mat-option
            *ngFor="let severity of severityList"
            [value]="severity.viewValue"
          >
            <i [class]="severity.icon"></i>
            <span class="dropdown-text">{{ severity.viewValue }}</span>
          </mat-option>
          <mat-select-trigger>
            <ng-container
              *ngIf="
                filtersForm.get('severity')?.value &&
                filtersForm.get('severity')?.value.length
              "
            >
              <span
                *ngFor="
                  let selectedValue of filtersForm.get('severity')?.value;
                  let i = index
                "
              >
                <ng-container *ngFor="let severity of severityList">
                  <span *ngIf="selectedValue === severity.viewValue">
                    <i [class]="severity.icon"></i>
                    <span class="dropdown-text">{{ severity.viewValue }}</span>
                    <span
                      *ngIf="i < filtersForm.get('severity')?.value.length - 1"
                      >,
                    </span>
                  </span>
                </ng-container>
              </span>
            </ng-container>
          </mat-select-trigger>
        </mat-select>
      </mat-form-field>

      <mat-divider></mat-divider>

      <!-- Status -->
      <h5 class="title">Status</h5>
      <mat-form-field class="input-size" appearance="outline">
        <mat-select placeholder="Select..." formControlName="status" multiple>
          <mat-option
            *ngFor="let status of statuses"
            [value]="status.viewValue"
          >
            <i [class]="status.icon"></i>
            <span class="dropdown-text">{{ status.viewValue }}</span>
          </mat-option>
          <mat-select-trigger>
            <ng-container
              *ngIf="
                filtersForm.get('status')?.value &&
                filtersForm.get('status')?.value.length
              "
            >
              <span
                *ngFor="
                  let selectedValue of filtersForm.get('status')?.value;
                  let i = index
                "
              >
                <ng-container *ngFor="let status of statuses">
                  <span *ngIf="selectedValue === status.viewValue">
                    <i [class]="status.icon"></i>
                    <span class="dropdown-text">{{ status.viewValue }}</span>
                    <span
                      *ngIf="i < filtersForm.get('status')?.value.length - 1"
                      >,
                    </span>
                  </span>
                </ng-container>
              </span>
            </ng-container>
          </mat-select-trigger>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions class="action-buttons">
  <button mat-flat-button color="primary" (click)="onApply()">
    <i class="fas fa-circle-check"></i>
    <span>Apply</span>
  </button>
  <button mat-button (click)="onClearAll()">
    <i class="fas fa-ban"></i>
    <span>Clear All</span>
  </button>
</mat-dialog-actions>
