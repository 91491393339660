<div [formGroup]="leaveForm">
  <ng-template matStepLabel>Leave</ng-template>
  <div class="stepper-container w-500">
    <h3>Does the employee need to leave work? *</h3>
    <mat-radio-group formControlName="leaveWork">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="isLeaveWork()">
      <h3>When did the employee leave work?</h3>
      <div class="column">
        <app-date-time-picker
          [hideTime]="true"
          [value]="dateLeaveWork"
          (dateChanged)="updateDateLeaveWork($event)"
        ></app-date-time-picker>
      </div>
    </div>
    <div *ngIf="isLeaveWork()">
      <h3>Has the employee returned to work? *</h3>
      <mat-radio-group formControlName="employeeReturned">
        <mat-radio-button value="true">Yes</mat-radio-button>
        <mat-radio-button value="false">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="isLeaveWork() && isEmployeeReturned()">
      <h3>When is the employee returning to work?</h3>
      <div class="column">
        <app-date-time-picker
          [hideTime]="true"
          [value]="dateEmployeeReturned"
          (dateChanged)="updateDateEmployeeReturned($event)"
        ></app-date-time-picker>
      </div>
    </div>
  </div>
</div>
