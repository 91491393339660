import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  @Input() disabled: boolean = false;
  @Input() width?: string;
  @Input() height?: string;
  @Input() label?: string;
  @Input() margin?: string;
  @Input() required?: boolean;
  @Input() labelBackground?: string;
  @Input() value: string = '';

  constructor() {}

  get styles(): { [key: string]: string } {
    const params: any = {};
    if (this.width?.length) {
      params.width = this.width;
    }
    if (this.height?.length) {
      params.height = this.height;
    }
    if (this.margin?.length) {
      params.margin = this.margin;
    }
    if (this.required && !this.value?.length) {
      params.border = '1px solid red';
    }
    return params;
  }
}
