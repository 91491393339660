import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusType } from '@app/models/status.model';
import { DriverNotesService } from '@app/services/driver-notes.service';

@Component({
  selector: 'app-change-status-confirm-modal',
  templateUrl: './change-status-confirm-modal.component.html',
  styleUrls: ['./change-status-confirm-modal.component.scss'],
})
export class ChangeStatusConfirmModalComponent implements OnInit {
  reason: string = '';
  explanation: string = '';

  statusType = StatusType;
  userId: string = '';

  constructor(
    public dialogRef: MatDialogRef<ChangeStatusConfirmModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private driverNotesService: DriverNotesService
  ) {}

  ngOnInit(): void {
    this.getUserIdFromLocalStorage();
  }

  getUserIdFromLocalStorage(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userId = parsedData.userInfo.objectId;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    const result = {
      reason: this.reason,
      explanation: this.explanation,
    };
    this.createNote(result);
    this.dialogRef.close(result);
  }

  createNote(result: any): void {
    let message = `<p><strong>Status Change from ${this.data.status} to ${this.data.newStatus}</strong></p>`;
    if (this.data.newStatus === this.statusType.NON_EVENT) {
      message += `<p><strong>Reason:</strong> ${result.reason}</p>`;
    }
    message += `<p><strong>Explanation:</strong> ${result.explanation}</p>`;

    this.driverNotesService
      .createNote({
        id: null,
        entity_id: this.data.event.id,
        entity_type: 'event',
        user_id: this.userId,
        createdBy: this.userId,
        lastModifiedBy: this.userId,
        message,
        images: 0,
        deleted: 0,
      })
      .subscribe({
        next: () => {},
        error: (error) => {
          console.log(error);
        },
      });
  }

  onDialogCloseClick(): void {
    this.dialogRef.close();
  }
}
