<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Create Intervention</div>
</mat-dialog-actions>

<mat-dialog-content>
  <form [formGroup]="interventionForm">
    <div class="block-form">
      <div class="fields-container">
        <!-- Driver ID -->
        <app-driver-id-search
          [driverId]="this.driver?.driverId ? this.driver?.driverId : ''"
          [driverName]="this.driver?.driverName ? this.driver?.driverName : ''"
          [terminal]="this.driver?.terminal ? this.driver?.terminal : ''"
          (driverIdSelected)="handleDriverIdSelected($event)"
        ></app-driver-id-search>

        <!-- Linked Event -->
        <div class="priority-container">
          <!-- Linked Event Pre Selected -->
          <mat-form-field
            appearance="outline"
            class="custom-input linked-event-input"
            *ngIf="data?.event?.id"
          >
            <mat-label>Linked Event</mat-label>
            <input type="text" matInput [disabled]="true" [value]="getEventSelected()" />
          </mat-form-field>

          <!-- Linked Event Without Pre-selection -->
          <mat-form-field
            *ngIf="!data?.event?.id"
            appearance="outline"
            class="custom-input linked-event-input"
          >
            <mat-label>Linked Event</mat-label>
            <mat-chip-grid #chipList>
              <mat-chip
                *ngFor="let selectedEvent of selectedEvents"
                (removed)="removeEvent(selectedEvent)"
              >
                {{ selectedEvent.driverId }} - {{ selectedEvent.type }} -
                {{ selectedEvent.processDate | utcDate }}
                <span matChipRemove class="material-symbols-outlined icon-black">
                  cancel
                </span>
              </mat-chip>
              <input
                type="text"
                matInput
                #linkedEventInput
                [formControl]="linkedEventControl"
                [matAutocomplete]="autoComplete"
                [matChipInputFor]="chipList"
              />
              <span
                *ngIf="selectedEvents.length"
                class="input-reset"
                matSuffix
                (click)="resetSelectedEvents()"
              >
                <i class="fa-solid fa-xmark"></i>
              </span>
            </mat-chip-grid>
            <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let event of filteredEvents | async"
                [value]="event"
                (onSelectionChange)="selectEvent(event)"
              >
                {{ event.driverId }} - {{ event.type }} -
                {{ event.processDate | utcDate }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <!-- Intervention Type -->
        <mat-form-field appearance="outline" class="custom-input intervention-select">
          <mat-label>Intervention Type</mat-label>
          <mat-select formControlName="interventionType">
            <mat-option value="Coaching">Coaching</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Assigned Coach-->
        <div class="custom-input assigned-coach-select">
          <app-assignee-search
            [required]="true"
            label="Assigned Coach"
            [assignee]="data?.assignee"
            (userIdSelected)="handleAssigneeSelected($event)"
          ></app-assignee-search>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Reason -->
    <div class="block-form reason-field">
      <div class="fields-container">
        <mat-form-field appearance="outline">
          <mat-label>Reason</mat-label>
          <input matInput formControlName="reason" maxlength="100" />
          <mat-error
            *ngIf="
              interventionForm.get('reason')?.hasError('required') &&
              interventionForm.get('reason')?.touched
            "
          >
            The field is required
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <!-- Coaching Script -->
    <div class="script-container">
      <div class="plan-block">
        <h1 class="title-modal">Coaching Script</h1>
      </div>
      <div class="editor-container">
        <app-editor
          [(contents)]="contents"
          (contentsChange)="onEditorContentChange($event)"
        ></app-editor>
      </div>
    </div>

    <!-- Priority -->
    <div class="priority-container">
      <mat-form-field appearance="outline" class="custom-input priority-select">
        <mat-label>Priority</mat-label>
        <mat-select formControlName="priority">
          <mat-select-trigger>
            <div class="select-trigger-content">
              <div
                class="safety-indicator {{
                  getPriorityLabel(interventionForm.get('priority')?.value).toLowerCase()
                }}"
              >
                <i class="mark mark-first"></i>
                <i class="mark"></i>
                <i class="mark"></i>
              </div>
              {{ getPriorityLabel(interventionForm.get("priority")?.value) }}
            </div>
          </mat-select-trigger>
          <mat-option *ngFor="let priority of priorities" [value]="priority.value">
            <div class="select-trigger-content">
              <div class="safety-indicator {{ priority.label.toLowerCase() }}">
                <i class="mark mark-first"></i>
                <i class="mark"></i>
                <i class="mark"></i>
              </div>
              {{ priority.label }}
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Due Date -->
    <div class="date-picker-block custom-input">
      <app-date-time-picker
        label="Due Date"
        [hideTime]="true"
        [value]="dueDate"
        (dateChanged)="updateDueDate($event)"
      ></app-date-time-picker>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <button
    mat-flat-button
    color="primary"
    class="save-change-button"
    (click)="onCreate()"
    [disabled]="!interventionForm.valid"
  >
    <i class="far fa-circle-check"></i>
    <span>Create</span>
  </button>
  <button class="cancel-button" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
