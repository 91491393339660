import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserPermission } from '@app/models/userProfileAndPermissions.model';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private permissionService: PermissionService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredPermissions = route.data['requiredPermissions'] as UserPermission[];

    const hasPermission = requiredPermissions.some((permission: UserPermission) =>
      this.permissionService.hasPermission(permission.permissionType, permission.resourceName) 
    );
   
    if (hasPermission) {
      return true;
    }

    this.router.navigate(['/unauthorized']);
    return false;
  }

}
