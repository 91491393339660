<div #modalContainer>
  <mat-dialog-actions>
    <div class="dialog-close">
      <div *ngIf="isLoadingPrintWeb" class="spinner-container-small">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="20"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <i class="fa-solid fa-print print-icon" (click)="printContent()"></i>
      <i class="fas fa-times" (click)="onDialogCloseClick()"></i>
    </div>
    <div mat-dialog-title>
      {{ accidentData?.driverId | uppercase }} Accident -
      {{ accidentData?.accidentDate | date : "longDate" }}
    </div>
  </mat-dialog-actions>

  <app-print-accident-data
    *ngIf="showPrintComponent"
    [accidentData]="accidentForm.getRawValue()"
    [crashTypeOptions]="crashTypeOptions"
    [assetLocationOptions]="assetLocationOptions"
    [equipmentDamageOptions]="equipmentDamageOptions"
    [othersInvolvedOptions]="othersInvolvedOptions"
  ></app-print-accident-data>
  <mat-dialog-content *ngIf="!showPrintComponent" class="accident-content">
    <!-- Spinner -->
    <div *ngIf="isLoading" class="container container-loading">
      <div class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
    </div>
    <!-- Form -->
    <form *ngIf="!isLoading" [formGroup]="accidentForm">
      <div class="container">
        <div
          class="block-action"
          *ngIf="showCallHazmat || showCallGeneOrLaura || showScheduleAccidentTesting"
        >
          <h2>Action Items</h2>
          <div class="block-checkbox">
            <mat-checkbox
              *ngIf="showCallHazmat"
              color="primary"
              formControlName="callHazmatResponse"
            >
              Call Hazmat Response that fuel or hazardous materials have spilled:
              888-742-2885
            </mat-checkbox>
            <mat-checkbox
              *ngIf="showCallGeneOrLaura"
              color="primary"
              formControlName="callAdminsInformFatality"
              >Call Gene or Laura to inform them of the fatality.</mat-checkbox
            >
            <mat-checkbox
              *ngIf="showScheduleAccidentTesting"
              color="primary"
              formControlName="scheduleDriverTesting"
              >Schedule accident testing with the driver.</mat-checkbox
            >
          </div>
        </div>

        <!-- Driver Info -->
        <div class="block-driver-info">
          <h2>Driver Info</h2>
          <div class="row">
            <!--Driver ID-->
            <mat-form-field appearance="outline">
              <mat-label>Driver ID</mat-label>
              <input #driverIdInput matInput formControlName="driverId" />
            </mat-form-field>
            <!--Driver Name-->
            <mat-form-field appearance="outline">
              <mat-label>Driver Name</mat-label>
              <input matInput formControlName="driverName" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Driver Phone-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Driver Phone#</mat-label>
              <input matInput formControlName="driverPhone" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Organization-->
            <mat-form-field appearance="outline">
              <mat-label>Organization</mat-label>
              <input matInput formControlName="organization" />
            </mat-form-field>
            <!--Terminal-->
            <mat-form-field appearance="outline">
              <mat-label>Terminal</mat-label>
              <input matInput formControlName="terminal" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Fleet Manager-->
            <mat-form-field appearance="outline">
              <mat-label>Fleet Manager</mat-label>
              <input matInput formControlName="fleetManager" />
            </mat-form-field>
            <!--Safety Trainer-->
            <mat-form-field appearance="outline">
              <mat-label>Safety Trainer</mat-label>
              <input matInput formControlName="safetyManager" />
            </mat-form-field>
          </div>
        </div>

        <!-- Time and Place -->
        <div class="block-time">
          <h2>Time and Place</h2>
          <div class="row">
            <!--Time of Accident-->
            <app-datetime-picker
              class="normal-size-field"
              label="Time of Accident"
              formControlName="accidentDate"
              [disabled]="!canEditAccidents()"
            ></app-datetime-picker>
            <!--Reported Time-->
            <app-datetime-picker
              class="normal-size-field"
              label="Reported Time"
              formControlName="creationDate"
              [disabled]="true"
            ></app-datetime-picker>
          </div>

          <div class="row">
            <!--Address Line 1-->
            <mat-form-field appearance="outline">
              <mat-label>Address Line 1</mat-label>
              <input matInput formControlName="accidentAddress1" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Address Line 2-->
            <mat-form-field appearance="outline">
              <mat-label>Address Line 2</mat-label>
              <input matInput formControlName="accidentAddress2" />
            </mat-form-field>
          </div>

          <div class="row-city-state-zip">
            <!--City-->
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput formControlName="accidentCity" />
            </mat-form-field>
            <!--State-->
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <input matInput formControlName="accidentState" />
            </mat-form-field>
            <!--Zip-->
            <mat-form-field appearance="outline">
              <mat-label>Zip</mat-label>
              <input matInput formControlName="accidentZip" />
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Other Location Details-->
            <app-textarea-reactive
              class="full-size-field"
              label="Other Location Details"
              formControlName="accidentLocationOtherDetails"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>
        </div>

        <!-- Accident Details -->
        <div class="block-driver-info">
          <h2>Accident Details</h2>
          <div class="row-medium-size">
            <!--Type of Accident-->
            <mat-form-field appearance="outline">
              <mat-label>Type of Accident</mat-label>
              <mat-select formControlName="crashType">
                <mat-option *ngFor="let option of crashTypeOptions" [value]="option.id">
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-medium-size">
            <!--Location of Assets-->
            <mat-form-field appearance="outline">
              <mat-label>Location of Assets</mat-label>
              <mat-select formControlName="assetsLocated">
                <mat-option
                  *ngFor="let option of assetLocationOptions"
                  [value]="option.id"
                >
                  {{ option.description }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row less-gap">
            <!--Occur on Roadway-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Occur on Roadway</mat-label>
              <mat-select formControlName="accidentOnRoadway" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Hazmat-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Hazmat</mat-label>
              <mat-select formControlName="fuelHazardousSpilled" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Deployed Flares-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Deployed Flares</mat-label>
              <mat-select
                formControlName="deployedTriangleFlares"
                placeholder="Select..."
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Accident Description-->
            <app-textarea-reactive
              class="full-size-field"
              label="Accident Description"
              formControlName="accidentDescription"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <div class="row-full-size">
            <!--Witness Information-->
            <app-textarea-reactive
              class="full-size-field"
              label="Witness Information"
              formControlName="witnessInformation"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>
        </div>

        <!-- Driver Injuries and Damages -->
        <div class="block-driver-info">
          <h2>Driver Injuries and Damages</h2>
          <h3 class="sub-header">Injuries</h3>

          <div class="row row-short-size">
            <!-- Injured -->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Injured</mat-label>
              <mat-select formControlName="driverInjured" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!-- Medical Assistance -->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Medical Assistance</mat-label>
              <mat-select formControlName="driverRequireMedical" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!-- Extent of Injuries -->
            <app-textarea-reactive
              class="full-size-field"
              label="Extent of Injuries"
              formControlName="driverInjuryDescription"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <h3 class="sub-header">Damages</h3>

          <div class="row">
            <!--Truck-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Truck</mat-label>
              <input type="number" matInput formControlName="driverTruckNumber" />
            </mat-form-field>
            <!--Trailer-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Trailer</mat-label>
              <input type="number" matInput formControlName="driverTrailerNumber" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--What was Damaged-->
            <mat-form-field class="normal-size-field" appearance="outline">
              <mat-label>What was Damaged</mat-label>
              <mat-select formControlName="driverEquipmentDamage" placeholder="Select...">
                <mat-option
                  *ngFor="let option of equipmentDamageOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Cargo Condition-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Cargo Condition</mat-label>
              <input matInput formControlName="driverCargoCondition" />
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Extent of Damages-->
            <app-textarea-reactive
              class="full-size-field"
              label="Extent of Damages"
              formControlName="driverEquipmentDamageDescription"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <div class="row">
            <!--Towed-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Towed</mat-label>
              <mat-select formControlName="driverVehicleTowed" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Towing Information-->
            <app-textarea-reactive
              class="full-size-field"
              label="Towing Information"
              formControlName="driverVehicleTowedInformation"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>
        </div>

        <!-- Other Party -->
        <div class="block-driver">
          <h2>Other Party</h2>
          <h3 class="sub-header">Information</h3>

          <div class="row">
            <!--Who was Involved-->
            <mat-form-field class="normal-size-field" appearance="outline">
              <mat-label>Who was Involved</mat-label>
              <mat-select formControlName="othersInvolved" placeholder="Select...">
                <mat-option
                  *ngFor="let option of othersInvolvedOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Other Party Information-->
            <app-textarea-reactive
              class="full-size-field"
              label="Other Party Information"
              formControlName="othersInvolvedDetails"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <h3 class="sub-header">Injuries</h3>

          <div class="row less-gap">
            <!--Injured-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Injured</mat-label>
              <mat-select formControlName="othersInjured" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Medical Assistance-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Medical Assistance</mat-label>
              <mat-select formControlName="othersRequireMedical" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Fatality-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Fatality</mat-label>
              <mat-select formControlName="fatalityInvolved" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Extent of Injuries-->
            <app-textarea-reactive
              class="full-size-field"
              label="Extent of Injuries"
              formControlName="othersInjuryDescription"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <h3 class="sub-header">Damages</h3>

          <div class="row">
            <!--Property Damage-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Property Damage</mat-label>
              <mat-select
                formControlName="othersVehiclePropertyDamage"
                placeholder="Select..."
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Extent of Damages-->
            <app-textarea-reactive
              class="full-size-field"
              label="Extent of Damages"
              formControlName="othersVehiclePropertyDamageDescription"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <div class="row">
            <!--Towed-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Towed</mat-label>
              <mat-select formControlName="othersVehicleTowed" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Towing Information-->
            <app-textarea-reactive
              class="full-size-field"
              label="Towing Information"
              formControlName="othersVehicleTowedInformation"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>
        </div>

        <!-- Police -->
        <div class="block-police">
          <h2>Police</h2>

          <div class="row-medium-size-with-gap">
            <!--Police Called-->
            <mat-form-field class="normal-size-field" appearance="outline">
              <mat-label>Police Called</mat-label>
              <mat-select formControlName="policeCalled" placeholder="Select...">
                <mat-option
                  *ngFor="let option of policeCalledOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Citation-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Citation</mat-label>
              <mat-select formControlName="citationReceived" placeholder="Select...">
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row">
            <!--Police Report #-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Police Report #</mat-label>
              <input matInput formControlName="policeReportNumber" />
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Responding Officer Information-->
            <app-textarea-reactive
              class="full-size-field"
              label="Responding Officer Information"
              formControlName="policeOfficerInformation"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>

          <h2>System</h2>

          <div class="row">
            <!--Created By-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Created By</mat-label>
              <input matInput formControlName="createdBy" />
            </mat-form-field>

            <!--Creation Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Creation Date"
              formControlName="creationDate"
              [disabled]="true"
            ></app-datetime-picker>
          </div>

          <div class="row">
            <!--Last Modified By-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Last Modified By</mat-label>
              <input matInput formControlName="lastModifiedBy" />
            </mat-form-field>

            <!--Last Modified Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Last Modified Date"
              formControlName="lastModifiedDate"
              [disabled]="true"
            ></app-datetime-picker>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions *ngIf="canEditAccidents()" class="action-buttons">
  <button mat-flat-button color="primary" (click)="save()">Save</button>
  <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
