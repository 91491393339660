import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-coaching-samples',
  templateUrl: './dialog-coaching-samples.component.html',
  styleUrls: ['./dialog-coaching-samples.component.scss'],
})
export class DialogCoachingSamplesComponent implements OnInit {
  sampleData: any[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.sampleData = this.processEndpointResponse(this.data);
  }

  private processEndpointResponse(response: any): any[] {
    const processedData: any = {};

    response.content.forEach((sample: any) => {
      const eventType = sample.eventTypes;
      const message = sample.message;

      if (!processedData[eventType]) {
        processedData[eventType] = [];
      }

      processedData[eventType].push(message);
    });

    return Object.keys(processedData).map((key) => ({
      title: key,
      messages: processedData[key],
    }));
  }
}
