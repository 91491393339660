import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-injury-detail',
  templateUrl: './step-injury-detail.component.html',
  styleUrls: ['./step-injury-detail.component.scss'],
})
export class StepInjuryDetailComponent implements OnInit {
  @Input() injuryDetailForm!: FormGroup;

  dateInjuryReported: string = '';
  timeInjuryReported: string = '';

  ngOnInit(): void {
    //prettier-ignore
    this.injuryDetailForm.get('injuryReported')?.valueChanges.subscribe((value) => {
      if (value === 'true') {
        this.injuryDetailForm.get('dateInjuryReported')?.setValidators(Validators.required);
        this.injuryDetailForm.get('timeInjuryReported')?.setValidators(Validators.required);
      } else {
        this.injuryDetailForm.get('dateInjuryReported')?.clearValidators();
        this.injuryDetailForm.get('dateInjuryReported')?.reset();
        this.injuryDetailForm.get('timeInjuryReported')?.clearValidators();
        this.injuryDetailForm.get('timeInjuryReported')?.reset();
      }
      this.injuryDetailForm.get('dateInjuryReported')?.updateValueAndValidity();
      this.injuryDetailForm.get('timeInjuryReported')?.updateValueAndValidity();
    });
  }

  updateDateInjuryReported(date: string): void {
    this.injuryDetailForm.get('dateInjuryReported')?.setValue(date);
  }

  updateTimeInjuryReported(time: string): void {
    this.injuryDetailForm.get('timeInjuryReported')?.setValue(time);
  }

  isInjured(): boolean {
    return this.injuryDetailForm.get('injuryReported')?.value === 'true';
  }
}
