<mat-form-field [class.border-open]="status === statusType.OPEN && border"
                [class.border-assigned]="status === statusType.ASSIGNED && border"
                [class.border-closed]="status === statusType.CLOSED && border"
                class="status-select"
                appearance="fill"
                [style.width.px]="border ? 170 : 166"
                style="height: 46px">
  <mat-select [(ngModel)]="status"
              name="status"
              (selectionChange)="onSelectionChange($event)"
              [disabled]="disabled">
    <mat-option *ngFor="let status of statuses"
                [value]="status.viewValue"
                [disabled]="status.disabled">
      <i [class]="status.icon"></i>
      <span class="dropdown-text">{{ status.viewValue }}</span>
    </mat-option>
    <mat-select-trigger>
      <ng-container [ngSwitch]="status">
        <ng-container *ngFor="let status of statuses">
          <span *ngSwitchCase="status.viewValue">
            <i [class]="status.icon"></i>
            <span class="dropdown-text">{{ status.viewValue }}</span>
          </span>
        </ng-container>
      </ng-container>
    </mat-select-trigger>
  </mat-select>
</mat-form-field>

