<div class="toggle-container">
  <mat-slide-toggle
    [(ngModel)]="onlyCompliance"
    (change)="toggleOnlyCompliance($event)"
  ></mat-slide-toggle>
  <span>Only show Compliance Schema records</span>
</div>
<article class="article-files" #scrollContainer>
  <section *ngFor="let section of sections">
    <div class="header-block">
      <h2 [attr.id]="section.id">{{ section.title }}</h2>
    </div>

    <table mat-table [dataSource]="dataSources[section.id]" matSort>
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let record">
          <span class="pdf-icon" (click)="openPdfModal()">
            <i class="fas fa-file-pdf clickable"></i>
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="recordType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Record Type</th>
        <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
          {{ record.recordType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="uploaded">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Uploaded</th>
        <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
          {{ record.uploaded }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expires">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expires</th>
        <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
          {{ record.expires }}
        </td>
      </ng-container>

      <ng-container matColumnDef="complianceSchema">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Schema</th>
        <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
          {{ record.complianceSchema }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let record" (click)="onRowClick(record)">
          <span [ngClass]="getStatusClass(record.status)">
            <i [ngClass]="getStatusIcon(record.status)"></i>
            {{ record.status }}
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row class="clickable" *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <div *ngIf="!isLoading && section.records.length === 0" class="no-records-found">
      <p>No records found.</p>
    </div>

    <div
      class="view-more"
      *ngIf="!isLoading && section.records.length > maxDisplayedRecords"
      (click)="toggleViewMore(section)"
    >
      <span>{{ section.isViewMore ? "View More" : "View Less" }}</span>
      <mat-icon matIconSuffix>{{
        section.isViewMore ? "expand_more" : "expand_less"
      }}</mat-icon>
    </div>
  </section>
</article>

<ul *ngIf="sections.length > 1">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
