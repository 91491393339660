import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const targetUrl = environment.apiUrl;

    if (req.url.startsWith(targetUrl)) {
      const clonedRequest = req.clone({
        headers: req.headers.set(
          environment.apiTokenHeaderKey,
          environment.apiTokenHeaderValue
        ),
      });

      // If the response type is not 'blob', force it to 'text'
      const responseType = req.responseType !== 'blob' ? 'text' : req.responseType;

      return next.handle(clonedRequest.clone({ responseType })).pipe(
        map((event) => {
          if (event instanceof HttpResponse) {
            if (typeof event.body === 'string') {
              try {
                // Clean the JSON string before parsing
                const cleanBodyString = cleanJsonString(event.body);
                const cleanBody = JSON.parse(cleanBodyString);
                return event.clone({ body: cleanBody });
              } catch (error) {
                console.error('Error parsing JSON response:', error);
                throw new HttpErrorResponse({
                  error: 'Error parsing JSON response after cleaning',
                  status: event.status,
                  statusText: event.statusText,
                  url: event.url ?? undefined,
                });
              }
            } else if (req.responseType === 'blob') {
              // If the response type is 'blob', return the original response
              return event.clone({ body: event.body });
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          console.error('HTTP Error:', error);
          return throwError(() => error);
        })
      );
    } else {
      return next.handle(req);
    }
  }
}

// Function to clean control characters from a JSON string
function cleanJsonString(jsonString: string): string {
  return jsonString.replace(/[\u0000-\u001F\u007F-\u009F]/g, ' ').replace(/\\n/g, ' ');
}
