import { Component, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-leave',
  templateUrl: './step-leave.component.html',
  styleUrls: ['./step-leave.component.scss'],
})
export class StepLeaveComponent {
  @Input() leaveForm!: FormGroup;

  dateLeaveWork: string = '';
  dateEmployeeReturned: string = '';

  ngOnInit(): void {
    //prettier-ignore
    this.leaveForm.get('leaveWork')?.valueChanges.subscribe((value) => {
      if (value === 'true') {
        //prettier-ignore
        this.leaveForm.get('employeeReturned')?.setValidators(Validators.required);
      } else {
        this.leaveForm.get('employeeReturned')?.clearValidators();
        this.leaveForm.get('employeeReturned')?.reset();
      }
      this.leaveForm.get('employeeReturned')?.updateValueAndValidity();
    });
  }

  updateDateLeaveWork(date: string): void {
    this.leaveForm.get('dateLeaveWork')?.setValue(date);
  }

  updateDateEmployeeReturned(date: string): void {
    this.leaveForm.get('dateEmployeeReturned')?.setValue(date);
  }

  isLeaveWork(): boolean {
    return this.leaveForm.get('leaveWork')?.value === 'true';
  }

  isEmployeeReturned(): boolean {
    return this.leaveForm.get('employeeReturned')?.value === 'true';
  }
}
