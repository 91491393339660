<article #scrollContainer>
  <section>
    <div class="grid">
      <div class="grid-header">
        <h2 [attr.id]="'event-details'">
          Details<i
            class="fas fa-info-circle icon-info"
            [matTooltip]="getTooltipText()"
          ></i>
        </h2>
      </div>
      <!-- spinner -->
      <div *ngIf="isLoading" class="grid-spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <div *ngIf="!isLoading" class="grid-data">
        <ng-container *ngFor="let item of eventDetails; let i = index; let last = last">
          <div class="title" [class.no-border]="last">{{ item?.title }}</div>
          <div
            class="value"
            [class.no-border]="last"
            [ngClass]="{ 'first-item': i === 0 }"
          >
            <span *ngIf="!last">{{ item?.value }}</span>
            <mat-form-field
              class="description"
              *ngIf="last"
              appearance="fill"
              style="width: 100%"
            >
              <textarea
                matInput
                placeholder=""
                [(ngModel)]="rowEvent.description"
                (input)="descriptionEdited = true"
                [disabled]="!canEditEvents()"
              >
              {{ rowEvent?.description }}
              </textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </div>
    <!-- Save Description Button -->
    <div class="button-container" *ngIf="descriptionEdited">
      <button mat-flat-button color="primary" (click)="saveDescription()">Save</button>
    </div>
  </section>
  <div class="page-break"></div>
  <section>
    <div class="header-block">
      <h2 [attr.id]="'activity'">Activity</h2>
    </div>
    <!-- spinner -->
    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
    <div *ngIf="!isLoading">
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'accident'"
      >
        <app-activity-accident
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-accident>
      </div>
      <div class="activity-container" *ngIf="rowEvent?.type?.toLowerCase() === 'hos'">
        <app-activity-hos
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-hos>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'inspection'"
      >
        <app-activity-inspection
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-inspection>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'seatbelt'"
      >
        <app-activity-seatbelt
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
        ></app-activity-seatbelt>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'speeding'"
      >
        <app-activity-speeding
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-speeding>
      </div>
      <div
        class="activity-container"
        *ngIf="rowEvent?.type?.toLowerCase() === 'telematics'"
      >
        <app-activity-telematics
          [eventActivities]="eventActivities"
          [maxDisplayedRecords]="maxDisplayedRecords"
          (rowClicked)="onRowClick($event.row, $event.sectionName)"
        ></app-activity-telematics>
      </div>
    </div>
  </section>

  <section *ngIf="canViewInterventions()">
    <div class="header-block">
      <h2 [attr.id]="'interventions'">Linked Interventions</h2>
      <div class="buttons">
        <button
          mat-button
          color="primary"
          *ngIf="canLinkEventstoInterventions()"
          (click)="linkToExisting()"
        >
          <i class="fas fa-link"></i>
          <span>Link to Existing</span>
        </button>
        <button
          mat-button
          color="primary"
          *ngIf="canLinkEventstoInterventions()"
          (click)="onAddNewClick()"
        >
          <i class="fas fa-plus-circle"></i>
          <span>Add New</span>
        </button>
      </div>
    </div>
    <div class="search-block" [ngClass]="{ 'show-search': showSearch }">
      <!-- Search Intervention -->
      <mat-form-field appearance="outline" class="search-intervention intervention-input">
        <mat-chip-grid #chipList>
          <mat-chip
            *ngFor="let selectedIntervention of selectedInterventions"
            (removed)="removeIntervention(selectedIntervention)"
          >
            {{ selectedIntervention.key }}
            <span matChipRemove class="material-symbols-outlined icon-black">
              cancel
            </span>
          </mat-chip>
          <input
            type="text"
            matInput
            #interventionInput
            placeholder="Search for intervention"
            [formControl]="interventionControl"
            [matAutocomplete]="autoComplete"
            [matChipInputFor]="chipList"
          />
          <span
            *ngIf="selectedInterventions.length"
            class="icon-xmark"
            matSuffix
            (click)="resetSelectedInterventions()"
          >
            <i class="fa-solid fa-xmark"></i>
          </span>
          <span
            *ngIf="!selectedInterventions.length"
            class="icon-magnifying-glass"
            matSuffix
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </mat-chip-grid>
        <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let event of filteredInterventions | async"
            [value]="event"
            (onSelectionChange)="selectIntervention(event)"
          >
            {{ event.key }} - {{ event.driverId }} -
            {{ event.reason }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Link Buttons-->
      <div class="buttons">
        <button
          mat-button
          color="primary"
          [disabled]="!selectedInterventions.length"
          (click)="linkIntervention()"
        >
          <i class="fas fa-link"></i>
          <span>Link</span>
        </button>
        <span class="cancel-link" (click)="cancelSearch()">Cancel</span>
      </div>
    </div>

    <div
      *ngIf="!interventions.length && !isLoadingInterventions"
      class="no-linked-interventions"
    >
      No Linked Interventions
    </div>

    <!-- spinner -->
    <div *ngIf="isLoadingInterventions" class="grid-spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <!-- Intervention Cards-->
    <div *ngIf="!isLoadingInterventions" class="card-container">
      <div
        *ngFor="let intervention of interventions"
        class="card"
        [ngClass]="canOpenInterventions(intervention) ? 'pointer' : 'default-pointer'"
        (click)="canOpenInterventions(intervention) ? goToDetails(intervention) : null"
      >
        <div
          *ngIf="canLinkEventstoInterventions()"
          class="close-icon"
          matTooltip="Unlink"
          (click)="onUnlinkIntervention($event, intervention)"
        >
          <i class="fa-solid fa-times"></i>
        </div>
        <div class="card-header">
          <h3>
            <span>{{ intervention.reason }}</span>
          </h3>
          <div class="safety-indicator {{ intervention?.priority?.toLowerCase() }}">
            <i class="mark"></i>
            <i class="mark"></i>
            <i class="mark"></i>
          </div>
        </div>
        <div class="card-actions">
          <div>
            <i class="fas fa-id-card"></i>
            <span>{{ intervention.driverId }}</span>
          </div>
          <div>
            <i class="fas fa-map-marker-alt"></i>
            <span>{{ intervention.terminal }}</span>
          </div>
        </div>
        <div class="card-type">
          <span *ngFor="let eventType of convertStringToArray(intervention.eventType)">
            {{ eventType }}
          </span>
        </div>
        <div class="card-footer">
          <i class="fa-regular fa-clock" [class.invisible]="!intervention?.dueDate"></i>
          <div>{{ intervention?.dueDate | date : "MMM d" }}</div>
          <div class="event-id">{{ intervention.ticketNum }}</div>
          <img
            *ngIf="!intervention?.assigneeName.trim()"
            [src]="''"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <img
            *ngIf="
              intervention?.assigneeName.trim() &&
              assigneeImages[intervention.assignedCoach]
            "
            [src]="assigneeImages[intervention.assignedCoach]"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <div
            *ngIf="
              intervention?.assigneeName.trim() &&
              !assigneeImages[intervention.assignedCoach]
            "
            class="initials"
            matTooltip="{{ intervention.assigneeName }}"
            [ngClass]="getBackgroundClass(intervention.assigneeName)"
          >
            {{ getInitials(intervention.assigneeName) }}
          </div>
        </div>
      </div>
    </div>
  </section>
  <section [attr.id]="'notes-events'">
    <app-notes
      [avatarUrl]="avatarUrl"
      [userName]="userName"
      [contents]="contents"
      [notes]="notes"
      [entityId]="rowData.id"
      [sectionId]="'notes'"
      [entityType]="entityType"
    ></app-notes>
  </section>
</article>

<ul *ngIf="sections.length > 1 && showMenu">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li (click)="onNavClick(item.id)" [class.active]="item.id === sectionSelected">
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
