<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>Filters</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="content-container">
    <form [formGroup]="filtersForm" class="filters-form">
      <!-- Score -->
      <div class="block-score-range" *ngIf="canViewSDriverScores()">
        <div class="score-range-header">
          <h5 class="title">Score Range</h5>
          <input
            type="number"
            class="score-input"
            formControlName="driverScoreMin"
            readonly
          />
          <span class="to-text">to</span>
          <input
            type="number"
            class="score-input"
            formControlName="driverScoreMax"
            readonly
          />
        </div>
        <div class="score-range-wrapper">
          <span>0</span>
          <mat-slider min="0" max="100" discrete="true">
            <input
              matSliderStartThumb
              [value]="filtersForm.get('driverScoreMin')?.value"
              (valueChange)="
                filtersForm.get('driverScoreMin')?.setValue($event)
              "
            />
            <input
              matSliderEndThumb
              [value]="filtersForm.get('driverScoreMax')?.value"
              (valueChange)="
                filtersForm.get('driverScoreMax')?.setValue($event)
              "
            />
          </mat-slider>
          <span>100</span>
        </div>
      </div>

      <mat-divider *ngIf="canViewSDriverScores()"></mat-divider>

      <!-- Terminal -->
      <div class="block-terminal">
        <h5 class="title">Terminal</h5>
        <mat-form-field class="input-size" appearance="outline">
          <mat-select
            formControlName="terminal"
            placeholder="Select..."
            multiple
          >
            <mat-option
              *ngFor="let terminal of terminalList"
              [value]="terminal.name"
            >
              {{ terminal.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-divider></mat-divider>

      <!-- Open Interventions -->
      <!--<div class="block-status">
        <h5 class="title">Open Interventions</h5>
        <div class="checkbox-container">
          <mat-checkbox color="primary" formControlName="trueOption"
            >True</mat-checkbox
          >
        </div>
      </div>

      <mat-divider></mat-divider>-->

      <!-- Status -->
      <div class="block-status">
        <h5 class="title">Status</h5>
        <div class="checkbox-container">
          <mat-checkbox color="primary" formControlName="active"
            >Active</mat-checkbox
          >
          <mat-checkbox color="primary" formControlName="inactive"
            >Inactive</mat-checkbox
          >
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- Work Status -->
      <div class="block-status">
        <h5 class="title">Work Status</h5>
        <div class="checkbox-container" *ngIf="!isLoadingWorkStatus">
          <mat-checkbox
            color="primary"
            *ngFor="let status of workStatusList; let i = index"
            [formControl]="workStatuses.at(i)"
          >
            {{ status.name }}
          </mat-checkbox>
        </div>
        <div *ngIf="isLoadingWorkStatus" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
      </div>

      <mat-divider></mat-divider>

      <!-- Driver Type -->
      <div class="block-status">
        <h5 class="title">Driver Type</h5>
        <div *ngIf="!isLoadingDriverType">
          <div
            class="checkbox-row"
            *ngFor="
              let row of getRows(driverTypeList.length);
              let rowIndex = index
            "
          >
            <mat-checkbox
              color="primary"
              *ngFor="
                let type of getRowItems(driverTypeList, rowIndex, 2);
                let i = index
              "
              [formControl]="driverTypes.at(i + rowIndex * 2)"
            >
              {{ type.name }}
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="isLoadingDriverType" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions class="action-buttons">
  <button mat-flat-button color="primary" (click)="onApply()">
    <i class="fas fa-circle-check"></i>
    <span>Apply</span>
  </button>
  <button mat-button (click)="onClearAll()">
    <i class="fas fa-ban"></i>
    <span>Clear All</span>
  </button>
</mat-dialog-actions>
