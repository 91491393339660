import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';

import { DialogFileUploadComponent } from './dialog-file-upload.component';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';

@NgModule({
  declarations: [DialogFileUploadComponent],
  exports: [DialogFileUploadComponent],
  imports: [CommonModule, MatButtonModule, MatDialogModule, MatSortModule, MatTableModule, CheckboxModule],
})
export class DialogFileUploadModule {}
