<div class="input-block" [ngStyle]="styles">
  <label [style.background-color]="labelBackground" class="custom-label">
    {{ label }}
    <span class="star" *ngIf="required">*</span>
  </label>
  <input
    class="custom-input"
    type="text"
    disabled="{{ disabled }}"
    [(ngModel)]="value"
  />
</div>
