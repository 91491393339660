import { Component, Input } from '@angular/core';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';

@Component({
  selector: 'app-print-injury-data',
  templateUrl: './print-injury-data.component.html',
  styleUrls: ['./print-injury-data.component.scss'],
})
export class PrintInjuryDataComponent {
  @Input() injuryData: any;

  private utcDatePipe = new UtcDatePipe();

  // prettier-ignore
  getFormattedData() {
    this.injuryData.injuryDate = this.utcDatePipe.transform(
      this.injuryData.injuryDate,
      'M/d/yyyy h:mm A'
    );

    this.injuryData.injuryReportedDate = this.utcDatePipe.transform(
      this.injuryData.injuryReportedDate,
      'M/d/yyyy h:mm A'
    );

    this.injuryData.reportToWorkDate = this.utcDatePipe.transform(
      this.injuryData.reportToWorkDate,
      'M/d/yyyy h:mm A'
    );

    this.injuryData.employeeWorkLeaveDate = this.utcDatePipe.transform(
      this.injuryData.employeeWorkLeaveDate,
      'M/d/yyyy h:mm A'
    );

    this.injuryData.employeeWorkReturnDate = this.utcDatePipe.transform(
      this.injuryData.employeeWorkReturnDate,
      'M/d/yyyy h:mm A'
    );
    

    return [
      // "Driver Info" section
      { section: true, label: 'Driver Info', fullRow: true },
      { label: 'Driver ID', value: this.injuryData?.driverId, column: 'left' },
      { label: 'Driver Name', value: this.injuryData?.driverName, column: 'right' },
      { label: 'Driver Phone', value: this.injuryData?.employeePhone, fullRow: true },
      { label: 'Organization', value: this.injuryData?.organization, column: 'left' },
      { label: 'Terminal', value: this.injuryData?.terminal, column: 'right' },
      { label: 'Fleet Manager', value: this.injuryData?.fleetManager, column: 'left' },
      { label: 'Safety Trainer', value: this.injuryData?.safetyManager, column: 'right' },

      // "Time and Place" section
      { section: true, label: 'Time and Place', fullRow: true },
      { label: 'Reported to Work', value: this.injuryData?.reportToWorkDate, column: 'left' },
      { label: 'Time of Injury', value: this.injuryData?.injuryDate, column: 'right' },
      { label: 'Address Line 1', value: this.injuryData?.injuryAddress1, fullRow: true },
      { label: 'Address Line 2', value: this.injuryData?.injuryAddress2, fullRow: true },
      { label: 'City', value: this.injuryData?.injuryCity, column: 'left' },
      { label: 'State', value: this.injuryData?.injuryState, column: 'right' },
      { label: 'Zip', value: this.injuryData?.injuryZip, column: 'left' },
      { label: 'Other Location Details', value: this.injuryData?.injuryLocationOtherDetails, fullRow: true },

      // "Injury Details" section with sub-subheadings
      { section: true, label: 'Injury Details', fullRow: true },
      { isSubheading: true, label: 'Report', fullRow: true },
      { label: 'Reported?', value: this.getYesNoValue(this.injuryData?.injuryReported), fullRow: true },
      { label: 'Reported To', value: this.injuryData?.injuryReportedTo, column: 'left' },
      { label: 'Reported Time', value: this.injuryData?.injuryReportedDate, column: 'right' },

      { isSubheading: true, label: 'Injury Info', fullRow: true },
      { label: 'How Injury Occurred', value: this.injuryData?.injuryHowOccurred, fullRow: true },
      { label: 'Area of Body Injured', value: this.injuryData?.injuryAreaOfBody.join(', '), fullRow: true },
      { label: 'Extent of Injuries', value: this.injuryData?.extentOfInjuryDescription, fullRow: true },

      { isSubheading: true, label: 'Medical Assistance', fullRow: true },
      { label: 'Medical Assistance?', value: this.getYesNoValue(this.injuryData?.medicalAssistanceRequired), fullRow: true },
      { label: 'Assistance Info', value: this.injuryData?.medicalWhereReceived, fullRow: true },

      // "Additional Info" section with sub-subheadings
      { section: true, label: 'Additional Info', fullRow: true },
      { isSubheading: true, label: 'Work Absence', fullRow: true },
      { label: 'Leave Work?', value: this.getYesNoValue(this.injuryData?.employeeWorkLeave), column: 'left' },
      { label: 'Leave Work Date', value: this.injuryData?.employeeWorkLeaveDate, column: 'right' },
      { label: 'Return to Work?', value: this.getYesNoValue(this.injuryData?.employeeWorkReturn), column: 'left' },
      { label: 'Return to Work Date', value: this.injuryData?.employeeWorkReturnDate, column: 'right' },

      { isSubheading: true, label: 'Witness', fullRow: true },
      { label: 'Witness?', value: this.getYesNoValue(this.injuryData?.witness), fullRow: true },
      { label: 'Witness Info', value: this.injuryData?.witnessInformation, fullRow: true },

      { isSubheading: true, label: 'Miscellaneous', fullRow: true },
      { label: 'Winter Cleats?', value: this.getYesNoValue(this.injuryData?.employeeWinterCleats), fullRow: true },

      { isSubheading: true, label: 'System', fullRow: true },
      { label: 'Created By', value: this.injuryData?.createdBy, column: 'left' },
      { label: 'Creation Date', value: this.injuryData?.creationDate, column: 'right' },
      { label: 'Last Modified By', value: this.injuryData?.LastModifiedBy, column: 'left' },
      { label: 'Last Modified Date', value: this.injuryData?.LastModifiedDate, column: 'right' }
    ];
  }

  getYesNoValue(value: boolean): string {
    return value ? 'Yes' : 'No';
  }
}
