<div class="header-block" *ngIf="showTitle">
  <h2 [attr.id]="sectionId">Notes</h2>
</div>

<!-- Editor -->
<div *ngIf="showEditor && canWriteNotes()" class="note note-editor">
  <div class="note-avatar">
    <img
      *ngIf="avatarUrl"
      [src]="avatarUrl"
      appImgFallback
      alt="Avatar"
      class="user-avatar"
    />
    <div
      *ngIf="!avatarUrl"
      class="initials"
      [ngClass]="getBackgroundClass(setFullName(userInfo))"
    >
      {{ getInitials(setFullName(userInfo)) }}
    </div>
  </div>
  <div class="note-title">
    <span>{{ setFullName(userInfo) }}</span>
  </div>
  <div class="note-message editor">
    <app-editor
      [hideToolbar]="hideToolbar"
      [(contents)]="contents"
      (contentsChange)="onEditorContentChange($event)"
    ></app-editor>

    <!-- Upload Sections -->
    <div *ngIf="!hideToolbar" class="upload-sections">
      <!-- Upload Image Section -->
      <div class="upload-section">
        <button (click)="imageInput.click()">
          <i class="fa-solid fa-image"></i> Select Image(s)
        </button>
        <input
          #imageInput
          type="file"
          (change)="onImageSelected($event)"
          hidden
          accept="image/*"
          multiple
        />
        <div class="image-names" *ngFor="let img of selectedImages; let i = index">
          <a class="click-element" (click)="openImageDialog(img)"
            ><i class="fa-solid fa-image"></i>{{ img.name }}</a
          >
          <i class="fa-solid fa-times" (click)="removeSelectedImage(i)"></i>
        </div>
      </div>

      <!-- Upload File Section -->
      <div class="upload-section">
        <button (click)="fileInput.click()">
          <i class="fa-solid fa-file"></i> Select File(s)
        </button>
        <input
          #fileInput
          type="file"
          (change)="onFileSelected($event)"
          hidden
          accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .zip, .rar, .7z"
          multiple
        />
        <div class="file-names" *ngFor="let file of selectedFiles; let i = index">
          <i class="fa-solid fa-file"></i>{{ file.name }}
          <i class="fa-solid fa-times" (click)="removeSelectedFile(i)"></i>
        </div>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      (click)="createNote()"
      [disabled]="contents === '' || contents === null"
    >
      <i class="fas fa-circle-check"></i>
      <span>Save Note</span>
    </button>
  </div>
</div>

<!-- Notes List -->
<div *ngIf="!isLoading || !showViewMore">
  <ng-container *ngFor="let note of notes; first as isFirst">
    <div class="note">
      <div class="note-avatar">
        <img
          *ngIf="note?.avatarUrl"
          [src]="note?.avatarUrl"
          appImgFallback
          alt="Avatar"
          class="user-avatar"
        />
        <div
          *ngIf="!note?.avatarUrl || note?.avatarUrl === ''"
          class="initials"
          matTooltip="{{ note.userName }}"
          [ngClass]="getBackgroundClass(note.userName)"
        >
          {{ getInitials(note.userName) }}
        </div>
      </div>
      <div class="note-title">
        <span>{{ note?.userName }} <span *ngIf="!note.active">(Deactivated)</span></span>
        <span>{{ note?.noteDate }}</span>
        <span>{{ note?.noteTime }}</span>
      </div>
      <div class="note-message editor">
        <div class="message">
          <div [innerHTML]="note?.noteMessage"></div>
        </div>

        <!-- Files -->
        <div class="upload-sections created-note">
          <div class="note-files-section">
            <div class="file-names" *ngFor="let file of note.files">
              <a class="click-element" (click)="downloadFile(file, note.noteId!)">
                <i
                  [ngClass]="isImageFile(file) ? 'fa-solid fa-image' : 'fa-solid fa-file'"
                ></i
                >{{ file }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- Spinner -->
<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<!-- View More button -->
<button
  *ngIf="showViewMore && !isLoading && totalRecordCount > maxAmountRecords"
  class="load-more"
  (click)="showAllNotes()"
>
  View {{ totalRecordCount - maxAmountRecords }} remaining
  {{ totalRecordCount - maxAmountRecords > 1 ? "notes" : "note" }}
</button>
