<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>{{data.title}}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="container">
    <div class="supporting-media">
      <div class="caption">
        <i class="fa-solid fa-cloud-arrow-up"></i>
        <div class="label">
          <div class="block-1">Drag & Drop Document</div>
          <div class="block-2">or Select a File...</div>
        </div>
      </div>
    </div>
  </div>

  <div class="header-upload-modal">Violation info</div>

  <div class="container-table-upload">
    <table mat-table [dataSource]="tableData.upload.data">
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let element">
          <i class="fa-solid fa-file-pdf icon-pdf"></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploaded">
        <th mat-header-cell *matHeaderCellDef>Uploaded</th>
        <td mat-cell *matCellDef="let element">{{element?.uploaded}}</td>
      </ng-container>

      <ng-container matColumnDef="expires">
        <th mat-header-cell *matHeaderCellDef>Expires</th>
        <td mat-cell *matCellDef="let element">{{element?.expires}}</td>
      </ng-container>

      <ng-container matColumnDef="current">
        <th mat-header-cell *matHeaderCellDef>Current</th>
        <td mat-cell *matCellDef="let element">
          <app-checkbox></app-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="note">
        <th mat-header-cell *matHeaderCellDef>Note</th>
        <td mat-cell *matCellDef="let element">{{element?.note}}</td>
      </ng-container>

      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>Delete</th>
        <td mat-cell *matCellDef="let element">
          <i class="fa-regular fa-trash-can icon-trash"></i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableData.upload.columns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableData.upload.columns;"></tr>
    </table>
    <div class="border_bottom"></div>
  </div>
</mat-dialog-content>
