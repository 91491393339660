import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';

@Component({
  selector: 'app-step-accident-details',
  templateUrl: './step-accident-details.component.html',
  styleUrls: ['./step-accident-details.component.scss'],
})
export class StepAccidentDetailsComponent implements OnInit {
  @Input() accidentDetailsForm!: FormGroup;

  assetsOptions: any[] = [];
  crashTypeOptions: any[] = [];

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.getAssetLocationList();
    this.getCrashTypeList();
  }

  getAssetLocationList(): void {
    this.driverService.getAccidentOptionsList('AssetLocation').subscribe({
      next: (res) => {
        this.assetsOptions = res.sort((a: any, b: any) => a.id - b.id);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getCrashTypeList(): void {
    this.driverService.getAccidentOptionsList('CrashType').subscribe({
      next: (res) => {
        this.crashTypeOptions = res.sort((a: any, b: any) => a.id - b.id);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
