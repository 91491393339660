import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogEventService {
  private statusSelectionChange = new Subject<string>();
  private eventStatusSubject = new Subject<any>();

  statusSelectionChanged$ = this.statusSelectionChange.asObservable();
  eventStatusChange$ = this.eventStatusSubject.asObservable();

  announceStatusSelectionChange(status: string) {
    this.statusSelectionChange.next(status);
  }

  announceStatusChange(status: any) {
    this.eventStatusSubject.next(status);
  }

  viewInit(top: number = 210): void {
    // 210 = 70 + 111 + 20
    this.markActive(top);
    let debounce: any;
    document.querySelector('article')?.addEventListener('scroll', (): void => {
      if (debounce) {
        clearTimeout(debounce);
      }
      debounce = setTimeout(() => this.markActive(top), 0);
    });
  }

  markActive(top: number): void {
    const active: Element | null = document.querySelector('.active');
    active ? active.classList.remove('active') : null;
    const sections: NodeListOf<Element> = document.querySelectorAll(
      'section .header-block'
    );

    for (let i = 0; i < sections.length; i++) {
      if (this.getIsInViewport(sections[i], top)) {
        const li: Element | null = document.querySelector(
          'ul li:nth-child(' + (i + 1) + ')'
        );
        li?.classList.add('active');
        break;
      }
    }
  }

  getIsInViewport(elem: Element, top: number): boolean {
    return elem.getBoundingClientRect().top >= top;
  }

  onNavClick(elementId: string): void {
    document?.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
