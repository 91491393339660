import { SidenavList } from '@app/models';

export const SidenavListConstants: SidenavList[] = [
  { title: 'My Day', link: 'home', icon: 'house', permissionKey: 'Home' },
  {
    title: 'Drivers',
    link: 'drivers',
    icon: 'truck',
    permissionKey: 'Drivers',
  },
  {
    title: 'Events',
    link: 'events',
    icon: 'triangle-exclamation',
    permissionKey: 'Events',
  },
  {
    title: 'Coaching',
    link: 'intervention/coaching',
    icon: 'book',
    permissionKey: 'Interventions',
  },
  {
    title: 'Qualification Files',
    link: 'qualification-files',
    icon: 'file',
    permissionKey: 'Qualification Files',
  },
  /*{
    title: 'Intervention',
    link: 'intervention',
    icon: 'book',
    children: [
      { title: 'Coaching', link: 'coaching' },
      // { title: 'Training', link: 'training' },
      // { title: 'Campaigns', link: 'campaigns' },
    ],
  },*/
  {
    title: 'Analytics',
    link: 'analytics',
    icon: 'chart-simple',
    children: [
      {
        title: 'Drivers',
        link: 'analytics-drivers',
        permissionKey: 'Driver Analytics',
      },
      {
        title: 'Events',
        link: 'analytics-events',
        permissionKey: 'Event Analytics',
      },
      {
        title: 'Intervention',
        link: 'analytics-intervention',
        permissionKey: 'Intervention Analytics',
      },
    ],
    permissionKey: 'Analytics',
  },
  {
    title: 'Settings',
    link: 'settings',
    icon: 'cog',
    permissionKey: 'Settings',
    children: [
      {
        title: 'Administration',
        link: 'administration',
        permissionKey: 'Admin Settings',
      },
      {
        title: 'Automation',
        link: 'automation',
        permissionKey: 'Automation',
      },
      {
        title: 'Configuration',
        link: 'configuration',
        permissionKey: 'Configuration',
      },
    ],
  },
];
