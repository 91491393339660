<mat-dialog-actions>
  <div class="dialog-close">
    <i class="fas fa-times" (click)="onDialogCloseClick()"></i>
  </div>
  <div mat-dialog-title>Change Status to {{ data.newStatus }}</div>
</mat-dialog-actions>

<mat-dialog-content>
  <p *ngIf="data.newStatus === statusType.NON_EVENT" class="info-text">
    Please select a reason and add an explanation for why this is a
    <strong>Non-Event</strong>.
  </p>
  <p *ngIf="data.newStatus !== statusType.NON_EVENT" class="info-text">
    Please add an explanation for why this is
    <strong>no longer</strong> a <strong>Non-Event</strong>.
  </p>
  <mat-form-field
    *ngIf="data.newStatus === statusType.NON_EVENT"
    class="reason-input"
    appearance="outline"
  >
    <mat-label>Reason</mat-label>
    <mat-select placeholder="Select..." [(value)]="reason" required>
      <mat-option value="Data/Sensor Issue">Data/Sensor Issue</mat-option>
      <mat-option value="Not an Issue">Not an Issue</mat-option>
      <mat-option value="Duplicate">Duplicate</mat-option>
      <mat-option value="Other">Other</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="input-size" appearance="outline">
    <mat-label>Explanation</mat-label>
    <textarea
      matInput
      placeholder="Please provide an explanation for the change in status..."
      [(ngModel)]="explanation"
      required
    ></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions class="action-buttons">
  <button
    mat-flat-button
    color="primary"
    [disabled]="
      data.newStatus === statusType.NON_EVENT ? !reason || !explanation : !explanation
    "
    (click)="onSave()"
  >
    <i class="far fa-circle-check"></i>
    <span>Save</span>
  </button>
  <button mat-button color="warn" (click)="onCancel()">
    <i class="fas fa-ban"></i><span>Cancel</span>
  </button>
</mat-dialog-actions>
