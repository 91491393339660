import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { DriverService } from '@app/services/driver.service';

@Component({
  selector: 'app-step-vehicle-damage',
  templateUrl: './step-vehicle-damage.component.html',
  styleUrls: ['./step-vehicle-damage.component.scss'],
})
export class StepVehicleDamageComponent implements OnInit {
  @Input() vehicleDamageForm!: FormGroup;

  equipmentDamage: any[] = [];

  constructor(private driverService: DriverService) {}

  ngOnInit(): void {
    this.getEquipmentDamageList();
    this.setSubscriptionDamage();
  }

  getEquipmentDamageList(): void {
    this.driverService.getAccidentOptionsList('EquipmentDamage').subscribe({
      next: (res) => {
        this.equipmentDamage = res.sort((a: any, b: any) => a.id - b.id);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  setSubscriptionDamage(): void {
    // prettier-ignore
    this.vehicleDamageForm.get('damage')?.valueChanges.subscribe((value) => {
      if (
        value === '2' ||
        value === '3' ||
        value === '4'
      ) {
        this.vehicleDamageForm.get('towed')?.setValidators(Validators.required);
      } else {
        this.vehicleDamageForm.get('towed')?.clearValidators();
        this.vehicleDamageForm.get('towed')?.reset();
      }
      this.vehicleDamageForm.get('towed')?.updateValueAndValidity();
    });
  }
}
