<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <h2>Display Settings</h2>
</mat-dialog-actions>

<mat-dialog-content>
  <section>
    <h3>Theme</h3>
    <div class="slide-toggle-group">
      <span (click)="theme.toggle()">Light Mode</span>
      <mat-slide-toggle
        #theme
        [checked]="isDarkTheme"
        (click)="toggleSlide('theme', $event)"
        color="primary"
      >
        <span>Dark Mode</span>
      </mat-slide-toggle>
    </div>
  </section>

  <!-- <section>
    <h3>Content Density</h3>
    <div class="slide-toggle-group">
      <span (click)="content.toggle()">Regular View</span>
      <mat-slide-toggle
        #content
        [checked]=isComfyContent
        (click)="toggleSlide('content', $event)"
        color="primary">
        <span>Comfy View</span>
      </mat-slide-toggle>
    </div>
  </section> -->
</mat-dialog-content>

<mat-dialog-actions class="end">
  <button mat-flat-button color="primary" (click)="onUpdateClick()">
    <i class="far fa-circle-check"></i>
    <span>Update Settings</span>
  </button>
</mat-dialog-actions>
