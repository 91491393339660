export const areaBodyOptions: string[] = [
  'Face',
  'Head',
  'Neck',
  'Left Shoulder',
  'Right Shoulder',
  'Chest',
  'Upper Back',
  'Lower Back',
  'Left Upper Arm',
  'Right Upper Arm',
  'Left Elbow',
  'Right Elbow',
  'Left Lower Arm',
  'Right Lower Arm',
  'Left Hand',
  'Right Hand',
  'Abdomen',
  'Left Hip',
  'Right Hip',
  'Pelvic Area',
  'Glute',
  'Left Front Upper Leg',
  'Right Front Upper Leg',
  'Left Rear Upper Leg',
  'Right Rear Upper Leg',
  'Left Knee',
  'Right Knee',
  'Left Front Lower Leg',
  'Right Front Lower Leg',
  'Left Rear Lower Leg',
  'Right Rear Lower Leg',
  'Left Ankle',
  'Right Ankle',
  'Left Foot',
  'Right Foot',
];
