import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  Observable,
  catchError,
  debounceTime,
  forkJoin,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { AssigneeType } from '@app/models/status.model';
import { UserManagementService } from '@app/services/user-management.service';
import { environment } from '@env/environment';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { PermissionService } from '@app/services/permission.service';
import { MediaService } from '@app/services/media.service';

@Component({
  selector: 'app-assignee-search',
  templateUrl: './assignee-search.component.html',
  styleUrls: ['./assignee-search.component.scss'],
})
export class AssigneeSearchComponent implements OnInit {
  @Input() userId: string = '';
  @Input() userName: string = '';
  @Input() terminal: string = '';
  @Input() assignee!: any;
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() showDeleted: boolean = false;
  @Input() setEditable: boolean = false;
  @Input() hideAssignToMe: boolean = false;
  @Input() hideUnassigned: boolean = false;
  @Output() userIdSelected = new EventEmitter<any>();

  searchUserIdControl = new FormControl();
  filteredUsers!: Observable<any[]>;
  selectedUserImage: string = '';
  selectedUserDisplay: string = '';
  editable: boolean = true;
  allUsers: any[] = [];

  assigneeType = AssigneeType;

  assigneeImages: { [assigneeId: string]: string | null } = {};

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private userManagementService: UserManagementService,
    private permissionService: PermissionService,
    private mediaService: MediaService
  ) {
    this.searchUserIdControl = new FormControl('');
  }

  ngOnInit(): void {
    if (!this.setEditable) {
      this.checkPermissions();
    }
    this.initializeFormControl();
    this.searchUserList('');
    if (this.assignee?.userId) {
      const user = {
        userId: this.assignee.userId,
        firstName: this.assignee.firstName,
        lastName: this.assignee.lastName,
      };
      this.onSelectUser(user);
    }
    this.filteredUsers = this.searchUserList('');
    this.filteredUsers.subscribe((users) => {
      this.allUsers = users;
    });
    this.setSubsSearchUserIdControl();
  }

  private initializeFormControl(): void {
    if (this.required) {
      this.searchUserIdControl.setValidators(Validators.required);
    } else {
      this.searchUserIdControl.clearValidators();
    }
    this.searchUserIdControl.updateValueAndValidity();
  }

  canViewAssignToMe(): boolean {
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'My Interventions')
    );
  }

  setFullName(user: any): string {
    return `${user?.firstName} ${user?.lastName}`;
  }

  setSubsSearchUserIdControl(): void {
    this.filteredUsers = this.searchUserIdControl.valueChanges.pipe(
      startWith(''),
      debounceTime(700),
      switchMap((searchTerm) => this.searchUserList(searchTerm))
    );
  }

  searchUserList(searchTerm: string): Observable<any> {
    const params = { searchField: searchTerm, type: 'Interventions' };
    return this.userManagementService.getUsers(params).pipe(
      switchMap((res: any) => {
        const users = res[0].userResults.filter(
          (user: any) => this.showDeleted || user.active
        );
        const imageLoadObservables = users.map((user: any) =>
          this.getAssigneeImage(user.userId).pipe(
            map((imageUrl) => {
              user.imageUrl = imageUrl;
              return user;
            })
          )
        );
        return forkJoin(imageLoadObservables);
      })
    );
  }

  getAssigneeImage(userId: string): Observable<any> {
    if (this.assigneeImages[userId]) {
      return of(this.assigneeImages[userId]);
    }

    return this.mediaService.downloadFile('userProfileImage', userId).pipe(
      map((blob: Blob) => {
        const imageUrl = URL.createObjectURL(blob);
        this.assigneeImages[userId] = imageUrl;
        return imageUrl;
      }),
      catchError(() => of(null))
    );
  }

  onSelectUser(user: any): void {
    this.assignee = user;
    if (user === AssigneeType.UNASSIGNED) {
      this.selectedUserDisplay = AssigneeType.UNASSIGNED;
      this.selectedUserImage = this.getImageUrl('.');
    } else {
      this.selectedUserDisplay = `${user.firstName} ${user.lastName}`;
      this.selectedUserImage = this.getImageUrl(user.userId);
    }
    this.searchUserIdControl.setValue(this.selectedUserDisplay);

    this.userIdSelected.emit(user);
  }

  capitalizeWords(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  resetUser(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
    this.searchUserIdControl.setValue('');
    this.userIdSelected.emit(null);
  }

  clearImg(): void {
    this.assignee = null;
    this.selectedUserDisplay = '';
    this.selectedUserImage = '';
  }

  getImageUrl(userId: string): string {
    return `${environment.driverImageBaseUrl}/${userId}/profile_thumbnail${environment.driverImageToken}`;
  }

  checkPermissions(): void {
    // Check if the user has 'WRITE' permission for 'All Interventions'
    if (this.permissionService.hasPermission('WRITE', 'All Interventions')) {
      this.editable = true;
    } else {
      // If the user does not have 'WRITE' for 'All Interventions', check for 'My Interventions'
      this.editable = this.permissionService.hasPermission('WRITE', 'My Interventions');
      if (!this.editable) {
        this.searchUserIdControl.disable();
      }
    }
  }

  getLoggedUserId(): string {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      return parsedData.userInfo.objectId;
    }
    return '';
  }

  assignToMe(): void {
    const loggedUserId = this.getLoggedUserId();
    const user = this.allUsers.find((user) => user.userId === loggedUserId);
    if (user) {
      this.onSelectUser(user);
    }
  }
}
