import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-time-place',
  templateUrl: './step-time-place.component.html',
  styleUrls: ['./step-time-place.component.scss'],
})
export class StepTimePlaceComponent {
  @Input() timePlaceForm!: FormGroup;

  dateReport: string = '';
  timeReport: string = '';

  dateInjury: string = '';
  timeInjury: string = '';

  updateDateReport(date: string): void {
    this.timePlaceForm.get('dateReport')?.setValue(date);
  }

  updateTimeReport(time: string): void {
    this.timePlaceForm.get('timeReport')?.setValue(time);
  }

  updateDateInjury(date: string): void {
    this.timePlaceForm.get('dateInjury')?.setValue(date);
  }

  updateTimeInjury(time: string): void {
    this.timePlaceForm.get('timeInjury')?.setValue(time);
  }
}
