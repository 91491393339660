import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getEvent(eventId: string): Observable<any> {
    const url = `${this.baseUrl}/event/${eventId}`;
    return this.http.get<any>(url);
  }

  getEventsList(params: any): Observable<any> {
    const paramMappings: any = {
      driverId: 'driverId',
      pageSize: 'pageSize',
      pageNumber: 'pageNumber',
      startDate: 'startDate',
      endDate: 'endDate',
      type: 'type',
      severity: 'severity',
      status: 'status',
      terminal: 'terminal',
      sortField: 'sortField',
      sortValue: 'sortValue',
      searchField: 'searchField',
    };
    const url = `${this.baseUrl}/event?organizationId=${
      environment.organizationId
    }&${this.getQueryParams(paramMappings, params)}`;
    return this.http.get<any>(url);
  }

  getLinkedEventsToIntervention(params: any): Observable<any> {
    const paramMappings: any = {
      pageSize: 'pageSize',
      pageNumber: 'pageNumber',
      interventionId: 'interventionId',
    };
    const url = `${this.baseUrl}/event/intervention?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key] || request[key] === 0)
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  updateEvent(eventData: any): Observable<any> {
    eventData.organizationId = environment.organizationId;
    const url = `${this.baseUrl}/event`;
    return this.http.put<any>(url, eventData);
  }
}
