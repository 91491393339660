import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalMode } from '@app/models/general.model';
import { ToasterService } from '@app/services/toaster.service';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';

import { FilesViewerModalComponent } from '@app/shared/components/files-viewer-modal/files-viewer-modal.component';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';

@Component({
  selector: 'app-new-file-modal',
  templateUrl: './new-file-modal.component.html',
  styleUrls: ['./new-file-modal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none', opacity: 0 })
      ),
      state('expanded', style({ height: '*', display: 'block', opacity: 1 })),
      transition('expanded <=> collapsed', [
        style({ display: 'block' }),
        animate('225ms ease-in-out'),
      ]),
    ]),
  ],
})
export class NewFileModalComponent implements OnInit {
  recordTypes: any = [
    {
      name: 'Annual Review',
      default: true,
      includedInSchema: true,
      required: false,
      expiration: false,
      sensitive: false,
    },
    {
      name: 'Commercial Driver Application',
      default: true,
      includedInSchema: true,
      required: true,
      expiration: false,
      sensitive: true,
    },
    {
      name: "Driver's License",
      default: false,
      includedInSchema: false,
      required: true,
      expiration: true,
      sensitive: false,
    },
    {
      name: "Medical Examiner's Certificate",
      default: true,
      includedInSchema: true,
      required: true,
      expiration: true,
      sensitive: false,
    },
    {
      name: 'Motor Vehicle Record',
      default: true,
      includedInSchema: true,
      required: true,
      expiration: false,
      sensitive: false,
    },
    {
      name: 'MVR Self Certificate',
      default: false,
      includedInSchema: false,
      required: true,
      expiration: false,
      sensitive: true,
    },
    {
      name: 'Previous Employment and Drug and Alcohol Verification',
      default: true,
      includedInSchema: true,
      required: true,
      expiration: false,
      sensitive: false,
    },
    {
      name: 'Road Test',
      default: false,
      includedInSchema: false,
      required: true,
      expiration: false,
      sensitive: true,
    },
  ];
  urls = ['https://ontheline.trincoll.edu/images/bookdown/sample-local-pdf.pdf'];

  fileHistory: any[] = [
    {
      view: '',
      uploaded: '5/1/2023',
      expires: '5/1/2023',
      current: true,
      uploadedBy: 'Jane Dobrovski',
      expanded: false,
      notes: true,
    },
    {
      view: '',
      uploaded: '5/7/2023',
      expires: '5/7/2023',
      current: false,
      uploadedBy: 'Devarrice Johnson',
      expanded: false,
      notes: false,
    },
  ];

  displayedColumns: string[] = [
    'expand',
    'view',
    'uploaded',
    'expires',
    'current',
    'uploadedBy',
  ];

  recordTypeSelected: any = null;
  expirationDate: string = '';
  expirationDateRequired: boolean = false;
  expandedElement!: any;

  selectedFile: File | null = null;
  previewUrl: string | ArrayBuffer | null = null;
  userInfo!: any;
  avatarUrl!: string;
  mode: ModalMode = ModalMode.CREATE;
  modalMode = ModalMode;

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<NewFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    if (this.data.mode === ModalMode.EDIT) {
      this.mode = this.data.mode;
      this.displayedColumns.push('actions');
    }
    this.loadLoggedUser();
  }

  makeCurrent(row: any): void {
    if (row.current) {
      return;
    }

    this.fileHistory.forEach((item) => {
      item.current = false;
    });

    row.current = true;
  }

  deleteFile(row: any): void {
    const data = {
      title: `Delete File`,
      text: `Are you sure you want to delete the File?`,
      yesButtonText: 'Delete',
      noButtonText: 'Cancel',
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          const index = this.fileHistory.findIndex((item) => item === row);

          if (index > -1) {
            this.fileHistory.splice(index, 1);

            this.fileHistory = [...this.fileHistory];
            this.toasterService.showSuccess('The File was deleted successfully');
          }
        }
      });
  }

  onMenuClick(event: Event): void {
    event.stopPropagation();
  }

  loadLoggedUser(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userInfo = parsedData.userInfo;
      this.avatarUrl = this.userInfo.previewUrl;
    }
  }

  onSave(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onRecordTypeChange(selectedRecordType: any): void {
    this.recordTypeSelected = selectedRecordType;
    this.expirationDate = '';

    this.expirationDateRequired = selectedRecordType.expiration;
  }

  updateExpirationDate(expirationDate: string): void {
    this.expirationDate = expirationDate;
  }

  toggleRow(element: any): void {
    this.expandedElement = this.expandedElement === element ? null : element;
    element.isExpanded = !element.isExpanded;
  }

  openPdfModal() {
    const dialogRef = this.dialog.open(FilesViewerModalComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      data: { urls: this.urls, currentIndex: 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.currentIndex = result.currentIndex;
      }
    });
  }

  //Upload file functions
  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  handleFile(file: File): void {
    this.selectedFile = file;

    const reader = new FileReader();
    reader.onload = () => {
      this.previewUrl = reader.result;
    };
    reader.readAsDataURL(file);
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.handleFile(file);
      // Ensure expiration date logic is applied if in EDIT mode
      if (this.mode === ModalMode.EDIT && this.data?.expires !== '--') {
        this.expirationDateRequired = true;
      }
    }
  }

  getFileSize(sizeInBytes: number): string {
    const sizeInMB = sizeInBytes / 1024 / 1024;
    if (sizeInMB < 1) {
      const sizeInKB = sizeInBytes / 1024;
      return `${sizeInKB.toFixed(2)} KB`;
    } else {
      return `${sizeInMB.toFixed(2)} MB`;
    }
  }

  getFileIcon(fileName: string): string {
    const extension = fileName.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'pdf':
        return 'fas fa-file-pdf icon-big';
      case 'png':
      case 'jpg':
      case 'jpeg':
        return 'fas fa-file-image icon-big';
      default:
        return 'fas fa-file icon-big';
    }
  }

  setFullName(user: any): string {
    return `${user?.firstName} ${user?.lastName}`;
  }

  isSaveDisabled(): boolean {
    if (this.mode === ModalMode.EDIT) {
      return false;
    }

    if (this.expirationDateRequired && !this.expirationDate) {
      return true;
    }

    return !(this.recordTypeSelected && this.selectedFile);
  }
}
