<label>
  <i
    *ngIf="checked"
    class="fa-solid fa-check icon-check"
    [ngClass]="{ 'icon-check-disabled': disabled }"
  ></i>
  <input
    class="custom-checkbox"
    type="checkbox"
    [(ngModel)]="checked"
    [disabled]="disabled"
  />
  <span class="label-text">{{ label }}</span>
</label>
