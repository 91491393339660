export enum FilterType {
  POSITIVE = 'Positive',
  NEGATIVE = 'Negative',
  ALL = 'All',
}

export enum ActivitySectionName {
  ACCIDENTS = 'accidents',
  INJURIES = 'injuries',
  TELEMATICS = 'telematics',
  HOURS_OF_SERVICE = 'hoursOfService',
  INSPECTIONS = 'inspections',
  SPEEDING_EVENTS = 'speeding-events',
  PERCENTAGE_SPEEDING = 'percentage-speeding',
}
