<div class="picker-container">
  <label class="custom-label">
    {{label}}
    <span class="star" *ngIf="required">*</span>
  </label>
  <ngx-timepicker-field
    [confirmBtnTmpl]="confirmBtnTmpl"
    [cancelBtnTmpl]="cancelBtnTmpl"
    [clockTheme]="clockStyle"
    (timeChanged)="onTimeChanged($event)"></ngx-timepicker-field>
  <ng-template #confirmBtnTmpl>
    <button mat-flat-button color="primary">Save</button>
  </ng-template>
  <ng-template #cancelBtnTmpl>
    <button mat-stroked-button color="warn">Cancel</button>
  </ng-template>
</div>

