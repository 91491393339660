<article #scrollContainer>
  <!-- Coaching Script Section -->
  <section>
    <div class="header-block plan-block">
      <h2 [attr.id]="'coaching-plan'">Coaching Script</h2>
      <div
        *ngIf="coachingExamples.length"
        class="link"
        (click)="openModalExamples()"
      >
        <i class="fa-solid fa-circle-info icon"></i>Examples
      </div>
      <div
        *ngIf="coachingStatistics.length"
        class="link"
        (click)="openModalStatistics()"
      >
        <i class="fa-solid fa-chart-simple icon"></i>Statistics
      </div>
    </div>
    <div class="editor-container" *ngIf="canEditInterventions()">
      <app-editor
        [(contents)]="coachingScriptContents"
        (contentsChange)="onEditorContentChange($event)"
      ></app-editor>
      <button
        *ngIf="showSaveButton"
        mat-flat-button
        color="primary"
        (click)="saveCoachingScript()"
      >
        Save
      </button>
      <button
        *ngIf="showSaveButton"
        mat-stroked-button
        (click)="cancelCoachingScript()"
      >
        Cancel
      </button>
    </div>
  </section>

  <!-- Linked Events Section -->
  <section>
    <div class="header-block">
      <h2 [attr.id]="'linked-events'">Linked Events</h2>
    </div>
    <table
      mat-table
      matSort
      [dataSource]="linkedEventList"
      class="table-header-sticky linked-events-table"
    >
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
          {{ element?.eventType }}
        </td>
      </ng-container>

      <ng-container matColumnDef="criteria">
        <th mat-header-cell *matHeaderCellDef>Criteria</th>
        <td
          class="criteria-column"
          mat-cell
          *matCellDef="let element"
          (click)="onRowClick(element)"
          matTooltip="{{ addSpaceAfterComma(element?.criteria) }}"
        >
          {{ addSpaceAfterComma(element?.criteria) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="severity">
        <th mat-header-cell *matHeaderCellDef>Severity</th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
          <i
            *ngIf="element.severity === 'Critical'"
            [matTooltip]="element.severity"
            class="fa-solid fa-angle-double-up icon-red icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Significant'"
            [matTooltip]="element.severity"
            class="fa-solid fa-chevron-up icon-red icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Moderate'"
            [matTooltip]="element.severity"
            class="fa-solid fa-equals icon-orange icon-default-size"
          ></i>
          <i
            *ngIf="element.severity === 'Minor'"
            [matTooltip]="element.severity"
            class="fa-solid fa-chevron-down icon-gray icon-default-size"
          ></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
          {{ element?.processDate | utcDate }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" (click)="onRowClick(element)">
          <ng-container [ngSwitch]="element?.status">
            <ng-container *ngFor="let status of statuses">
              <span *ngSwitchCase="status.viewValue">
                <i [class]="status.icon"></i>
                <span class="status-text">{{ status.viewValue }}</span>
              </span>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="menu">
        <th mat-header-cell *matHeaderCellDef>Menu</th>
        <td mat-cell *matCellDef="let row">
          <div class="icon-container" [matMenuTriggerFor]="menu">
            <i class="fas fa-ellipsis"></i>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onUnlinkIntervention(row)">
              Unlink
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="linkedEventColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: linkedEventColumns"
        [class.row-selected]="isRowSelected(row.id)"
        class="clickable"
      ></tr>
    </table>

    <div *ngIf="isLoading" class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>

    <div
      *ngIf="!isLoading && linkedEventList?.length === 0"
      class="no-records-found"
    >
      <p>No records found.</p>
    </div>
  </section>

  <!-- Notes Section -->
  <section *ngIf="canViewInterventionNotes()" style="margin-bottom: 43px" [attr.id]="'notes-coaching'">
    <app-notes 
      [avatarUrl]="avatarUrl"
      [userName]="userName"
      [contents]="noteContents"
      [notes]="notes"
      [entityId]="dataRow.id"
      [sectionId]="'notes'"
      [entityType]="entityType"
      [assigneeId]="userId"
    ></app-notes>
  </section>
</article>

<ul *ngIf="sections.length > 1 && showMenu">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li
      (click)="onNavClick(item.id)"
      [class.active]="item.id === sectionSelected"
    >
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
