import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent {
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() hideTime: boolean = false;
  @Input() required?: boolean;
  @Input() value: string = '';
  @Input() backgroundWhite: boolean = false;
  @Output() dateChanged: EventEmitter<string> = new EventEmitter();

  onDateChange(event: any): void {
    if (this.hideTime) {
      const date = new Date(event.value);
      this.value = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      this.dateChanged.emit(this.value);
    }
  }
}
