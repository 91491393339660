<div class="header-block">
  <h2>Seatbelt</h2>
</div>

<table
  mat-table
  matSort
  [dataSource]="seatbeltDataFiltered"
  class="table-header-sticky-driver-details"
>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.date | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef>Start Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.start | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="endTime">
    <th mat-header-cell *matHeaderCellDef>End Time</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.end | date : "hh:mm a" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="duration">
    <th mat-header-cell *matHeaderCellDef>Duration</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.duration !== "" ? convertToHourMinuteFormat(element?.duration) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="distance">
    <th mat-header-cell *matHeaderCellDef>Distance</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.distance | number : "1.2-2" }} mi
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="seatbeltColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: seatbeltColumns"
    (click)="onRowClick(row)"
    class="no-hover"
    [class.row-selected]="isRowSelected('seatbelt', row.id)"
  ></tr>
</table>

<div *ngIf="isLoading" class="spinner-container">
  <mat-progress-spinner
    mode="indeterminate"
    [diameter]="30"
    class="spinner"
  ></mat-progress-spinner>
</div>

<div class="paginator-container" *ngIf="!isViewMore">
  <div class="paginator-text">
    Showing {{ startPageIdx }} - {{ endPageIdx }} of {{ totalRecordCount }} items
  </div>
  <mat-paginator
    [pageSize]="pageEvent.pageSize"
    [length]="paginationParams.total"
    [hidePageSize]="true"
    [pageIndex]="pageEvent.pageIndex"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</div>

<div *ngIf="!isLoading && seatbeltDataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr *ngIf="!isLoading" />

<div class="view-more" *ngIf="!isLoading && showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
