import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { DialogActions } from '@app/models';
import { AssigneeType } from '@app/models/status.model';

import { InterventionService } from '@app/services/intervention.service';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { PermissionService } from '@app/services/permission.service';
import { convertStringToArray, toTitleCase } from '@app/shared/helpers/string.helper';
import { MediaService } from '@app/services/media.service';
import { DriverService } from '@app/services/driver.service';
import { UserManagementService } from '@app/services/user-management.service';

@Component({
  selector: 'app-dialog-coaching-details',
  templateUrl: './dialog-coaching-details.component.html',
  styleUrls: ['./dialog-coaching-details.component.scss'],
})
export class DialogCoachingDetailsComponent implements OnInit {
  event: any = {
    status: null,
    eventActivities: [],
  };
  currentStatus: string = '';
  selectedTabIndex: number = 0;
  showMenu: boolean = true;
  editMode: boolean = false;
  fromDriver: boolean = false;
  fromEvent: boolean = false;
  imageUrl: string = '';
  users!: any;
  userSelected!: any;

  assigneeType = AssigneeType;
  toTitleCase = toTitleCase;
  convertStringToArray = convertStringToArray;
  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  @ViewChild('tabGroup') tabGroup!: MatTabGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogCoachingDetailsComponent>,
    private dialogEventService: DialogEventService,
    private interventionService: InterventionService,
    private permissionService: PermissionService,
    private mediaService: MediaService,
    private driverService: DriverService,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    if (!this.data?.key) {
      this.fetchIntervention();
    }
    if (this.data?.fromDriver) {
      this.fromDriver = this.data.fromDriver;
    }
    if (this.data?.fromEvent) {
      this.fromEvent = this.data.fromEvent;
    }
    if (!this.data.showInfo) {
      this.subscriptionToDialogState();
    }
    this.currentStatus = this.data?.status;
    this.onTabChange({ index: 0 } as MatTabChangeEvent);
    this.getUsers();
    this.getAssigneeImage();
  }

  getUsers(): void {
    const params = { type: 'Interventions' };
    this.userManagementService.getUsers(params).subscribe({
      next: (res) => {
        this.users = res[0].userResults;
        this.userSelected = this.users.find(
          (user: any) => user.userId === this.data.assignee
        );
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  fetchIntervention(): void {
    this.interventionService.getIntervention(this.data.id).subscribe({
      next: (res) => {
        this.data = res[0];
        this.data.key = this.data.ticketId;
        this.driverService.getDriverProfile(this.data.driverID).subscribe({
          next: (res) => {
            this.data.driverName = res[0].driverName;
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getAssigneeImage(): void {
    this.mediaService.downloadFile('userProfileImage', this.data?.assignee).subscribe({
      next: (blob: Blob) => {
        this.imageUrl = URL.createObjectURL(blob);
      },
      error: (error) => {
        if (error.status !== 404) {
          console.error(error);
        }
        this.imageUrl = '';
      },
    });
  }

  subscriptionToDialogState(): void {
    this.checkIfTopDialog();
    this.dialog.afterOpened.subscribe((dialogRef: any) => {
      this.checkIfTopDialog();
      dialogRef.afterClosed().subscribe(() => {
        setTimeout(() => {
          this.checkIfTopDialog();
        });
      });
    });
  }

  checkIfTopDialog(): void {
    const openDialogs = this.dialog.openDialogs;
    const isTopDialog = openDialogs[openDialogs.length - 1] === this.dialogRef;

    if (isTopDialog) {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }
  }

  saveClicked(event: any): void {
    switch (event) {
      case DialogActions.CANCEL:
        this.editMode = false;
        break;
      case DialogActions.UPDATE_CLOSE:
        this.editMode = false;
        this.interventionService.announceSelectionChange('update');
        break;
      case DialogActions.UPDATE:
        this.interventionService.announceSelectionChange('update');
        break;
      default:
        break;
    }
  }

  updateIntervention(event: any): void {
    this.data = { ...this.data, ...event, driverID: event.driverId };
  }

  editIntervention(): void {
    this.editMode = true;
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    const container: Element | null = document.querySelector(
      '.dialog-event .mat-mdc-dialog-container .mdc-dialog__surface'
    );
    container?.setAttribute('style', 'overflow: hidden;');
    setTimeout(() => container?.setAttribute('style', 'overflow: visible;'), 1000);
  }

  goToDriverProfile(): void {
    this.tabGroup.selectedIndex = 2;
    this.selectedTabIndex = 2;
  }

  goToTerminal(terminal: string): void {
    this.dialog.openDialogs.forEach((dialogRef) => dialogRef.close());
    this.router.navigate(['/drivers', terminal]);
  }

  handleStatusSelectionChange(newStatus: string) {
    this.data.status = newStatus;
    this.dialogEventService.announceStatusSelectionChange(newStatus);
  }

  canEditInterventions(): boolean {
    const userId = localStorage.getItem('objectId');
    return (
      this.permissionService.hasPermission('WRITE', 'All Interventions') ||
      (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
        this.data?.assignee === userId)
    );
  }

  canViewDriverProfile(): boolean {
    return this.permissionService.hasPermission('READ', 'Driver Profile');
  }
}
