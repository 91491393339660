<mat-dialog-actions *ngIf="!editMode">
  <div class="title-container">
    <h2 class="title">Intervention #{{ data.key }}</h2>
    <span class="edit-action" *ngIf="canEditInterventions()" (click)="editIntervention()"
      ><i class="fa-solid fa-pen-to-square"></i>Edit Intervention</span
    >
  </div>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
</mat-dialog-actions>

<div
  [class.info-empty]="!data.eventType || data.eventType === ''"
  class="info"
  *ngIf="!editMode"
>
  <div class="subtitle">
    {{ data.reason }}
  </div>
  <div class="driver-info">
    <span class="firstname-lastname">{{
      data?.driverName ? toTitleCase(data?.driverName) : ""
    }}</span>

    <button mat-button (click)="goToDriverProfile()">
      <i class="fas fa-id-card"></i>
      <span>{{ data?.driverID }}</span>
    </button>

    <button mat-button (click)="goToTerminal(data?.terminal)">
      <i class="fas fa-map-marker-alt"></i>
      <span>{{ data?.terminal }}</span>
    </button>
  </div>
  <div class="card-type">
    <span *ngFor="let eventType of convertStringToArray(data.eventType)">{{
      eventType
    }}</span>
  </div>
</div>

<div class="custom-columns" *ngIf="!editMode">
  <!-- Assigned Coach -->
  <div class="column column-avatar">
    <img
      *ngIf="this.imageUrl"
      [src]="this.imageUrl"
      class="user-avatar"
      appImgFallback
      alt="Avatar"
    />
    <img
      *ngIf="!this.imageUrl && data?.assigneeName === ' '"
      [src]="''"
      class="user-avatar"
      appImgFallback
      alt="Avatar"
    />
    <div
      *ngIf="(!this.imageUrl || this.imageUrl === '') && data?.assigneeName !== ' '"
      class="initials"
      [ngClass]="getBackgroundClass(data.assigneeName)"
    >
      {{ getInitials(data.assigneeName) }}
    </div>
    <div class="text-content">
      <span class="column-span">
        {{
          data?.assigneeName === " "
            ? assigneeType.UNASSIGNED
            : toTitleCase(data?.assigneeName)
        }}
        <span *ngIf="userSelected && !userSelected.active">&nbsp;(Deactivated)</span>
      </span>
      <label class="column-label">Assigned Coach</label>
    </div>
  </div>
  <!-- Due Date -->
  <div class="column">
    <span class="column-span">{{ data?.dueDate ? (data?.dueDate | utcDate) : "-" }}</span>
    <label class="column-label">Due Date</label>
  </div>
  <!-- Priority -->
  <div class="column">
    <span class="column-span">
      <div class="safety-indicator {{ data?.priority?.toLowerCase() }}">
        <i class="mark"></i>
        <i class="mark"></i>
        <i class="mark"></i>
      </div>
      {{ data?.priority }}</span
    >
    <label class="column-label">Priority</label>
  </div>
  <!-- Status -->
  <div
    class="column special-border"
    [ngClass]="{
      'black-border': data.status.toLowerCase() === 'open',
      'orange-border': data.status.toLowerCase() === 'scheduled',
      'blue-border': data.status.toLowerCase() === 'in progress',
      'green-border': data.status.toLowerCase() === 'completed'
    }"
  >
    <app-status-coaching-select
      [intervention]="data"
      [status]="data.status"
      (selectionChanged)="handleStatusSelectionChange($event)"
      [disabled]="!canEditInterventions()"
    ></app-status-coaching-select>
  </div>
</div>

<app-edit-intervention
  *ngIf="editMode"
  [data]="data"
  (saveClicked)="saveClicked($event)"
  (interventionUpdated)="updateIntervention($event)"
></app-edit-intervention>

<mat-dialog-content>
  <mat-tab-group
    #tabGroup
    (selectedTabChange)="onTabChange($event)"
    animationDuration="0ms"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-triangle-exclamation"></i>
        <span>Details</span>
      </ng-template>
      <app-tab-section-details
        [showMenu]="showMenu"
        [interventionId]="data?.id"
        [driverId]="data?.driverID"
        [dataRow]="data"
      ></app-tab-section-details>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-clock-rotate-left"></i>
        <span>Driver History</span>
      </ng-template>
      <app-tab-section-history
        [showMenu]="showMenu"
        [driverId]="data?.driverID"
      ></app-tab-section-history>
    </mat-tab>
    <mat-tab *ngIf="canViewDriverProfile()">
      <ng-template mat-tab-label>
        <i class="fas fa-th-list ng-star-inserted"></i>
        <span>Driver Info</span>
      </ng-template>
      <app-tab-section-info [showMenu]="showMenu" [rowData]="data"></app-tab-section-info>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
