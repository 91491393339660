<div #modalContainer>
  <mat-dialog-actions>
    <div class="dialog-close">
      <div *ngIf="isLoadingPrintWeb" class="spinner-container-small">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="20"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <i class="fa-solid fa-print print-icon" (click)="printContent()"></i>
      <i class="fas fa-times" (click)="onDialogCloseClick()"></i>
    </div>
    <div mat-dialog-title>
      {{ injuryData?.driverId }} Injury -
      {{ injuryData?.injuryDate | date : "longDate" }}
    </div>
  </mat-dialog-actions>

  <app-print-injury-data
    *ngIf="showPrintComponent"
    [injuryData]="injuryForm.getRawValue()"
  ></app-print-injury-data>

  <mat-dialog-content *ngIf="!showPrintComponent">
    <div *ngIf="isLoading" class="container container-loading">
      <div class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
    </div>
    <form *ngIf="!isLoading" [formGroup]="injuryForm">
      <div class="container">
        <div class="block-driver-info">
          <h2>Driver Info</h2>
          <div class="row">
            <!--Driver ID-->
            <mat-form-field appearance="outline">
              <mat-label>Driver ID</mat-label>
              <input matInput formControlName="driverId" />
            </mat-form-field>
            <!--Driver Name-->
            <mat-form-field appearance="outline">
              <mat-label>Driver Name</mat-label>
              <input matInput formControlName="driverName" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Driver Phone-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Driver Phone#</mat-label>
              <input matInput formControlName="employeePhone" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Organization-->
            <mat-form-field appearance="outline">
              <mat-label>Organization</mat-label>
              <input matInput formControlName="organization" />
            </mat-form-field>
            <!--Terminal-->
            <mat-form-field appearance="outline">
              <mat-label>Terminal</mat-label>
              <input matInput formControlName="terminal" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Fleet Manager-->
            <mat-form-field appearance="outline">
              <mat-label>Fleet Manager</mat-label>
              <input matInput formControlName="fleetManager" />
            </mat-form-field>
            <!--Safety Trainer-->
            <mat-form-field appearance="outline">
              <mat-label>Safety Trainer</mat-label>
              <input matInput formControlName="safetyManager" />
            </mat-form-field>
          </div>
        </div>

        <div class="block-time">
          <h2>Time and Place</h2>
          <div class="row">
            <!--Reported to Work-->
            <app-datetime-picker
              class="normal-size-field"
              label="Reported to Work"
              formControlName="reportToWorkDate"
              [disabled]="!canEditInjuries()"
            ></app-datetime-picker>
            <!--Time of Injury-->
            <app-datetime-picker
              class="normal-size-field"
              label="Time of Injury"
              formControlName="injuryDate"
              [disabled]="!canEditInjuries()"
            ></app-datetime-picker>
          </div>

          <div class="row">
            <!--Address Line 1-->
            <mat-form-field appearance="outline">
              <mat-label>Address Line 1</mat-label>
              <input matInput formControlName="injuryAddress1" />
            </mat-form-field>
          </div>

          <div class="row">
            <!--Address Line 2-->
            <mat-form-field appearance="outline">
              <mat-label>Address Line 2</mat-label>
              <input matInput formControlName="injuryAddress2" />
            </mat-form-field>
          </div>

          <div class="row-city-state-zip">
            <!--City-->
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input matInput formControlName="injuryCity" />
            </mat-form-field>
            <!--State-->
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <input matInput formControlName="injuryState" />
            </mat-form-field>
            <!--Zip-->
            <mat-form-field appearance="outline">
              <mat-label>Zip</mat-label>
              <input matInput formControlName="injuryZip" />
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Other Location Details-->
            <app-textarea-reactive
              class="full-size-field"
              label="Other Location Details"
              formControlName="injuryLocationOtherDetails"
              placeholder="Enter example description here..."
            ></app-textarea-reactive>
          </div>
        </div>

        <div class="block-injury">
          <h2>Injury Details</h2>
          <h3 class="sub-header-injury">Report</h3>
          <div class="row">
            <!--Reported?-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Reported?</mat-label>
              <mat-select
                formControlName="injuryReported"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row">
            <!--Reported To-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Reported To</mat-label>
              <input matInput formControlName="injuryReportedTo" />
            </mat-form-field>
            <!--Reported Time-->
            <app-datetime-picker
              class="normal-size-field"
              label="Reported Time"
              formControlName="injuryReportedDate"
              [disabled]="!canEditInjuries()"
            ></app-datetime-picker>
          </div>

          <h3 class="sub-header-injury">Injury Info</h3>
          <div class="row-full-size">
            <!--How Injury Occurred-->
            <app-textarea-reactive
              class="full-size-field"
              label="How Injury Occurred"
              placeholder="Enter example description here..."
              formControlName="injuryHowOccurred"
              [disabled]="!canEditInjuries()"
            ></app-textarea-reactive>
          </div>

          <div class="row">
            <!--Area of Body Injured-->
            <app-multi-select-with-chips
              class="large-size-field"
              label="Area of Body Injured"
              placeholder="Select..."
              [disabled]="!canEditInjuries()"
              [list]="areaBodyOptions"
              [elements]="injuryData.injuryAreaOfBody"
              (elementsSelected)="handleAreaBodySelected($event)"
            ></app-multi-select-with-chips>
          </div>

          <div class="row-full-size">
            <!--Extent of Injuries-->
            <app-textarea-reactive
              class="full-size-field"
              label="Extent of Injuries"
              placeholder="Enter example description here..."
              formControlName="extentOfInjuryDescription"
              [disabled]="!canEditInjuries()"
            ></app-textarea-reactive>
          </div>

          <h3 class="sub-header-injury">Medical Assistance</h3>
          <div class="row">
            <!--Medical Assistance?-->
            <mat-form-field class="normal-size-field" appearance="outline">
              <mat-label>Medical Assistance?</mat-label>
              <mat-select
                formControlName="medicalAssistanceRequired"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Assistance Info-->
            <app-textarea-reactive
              class="full-size-field"
              label="Assistance Info"
              placeholder="Enter example description here..."
              formControlName="medicalWhereReceived"
              [disabled]="!canEditInjuries()"
            ></app-textarea-reactive>
          </div>
        </div>

        <div class="block-additional">
          <h2>Additional Info</h2>
          <h3 class="sub-header-other">Work Absence</h3>

          <div class="row-no-justify">
            <!--Leave Work?-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Leave Work?</mat-label>
              <mat-select
                formControlName="employeeWorkLeave"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!--Leave Work Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Leave Work Date"
              formControlName="employeeWorkLeaveDate"
              [disabled]="!canEditInjuries()"
            ></app-datetime-picker>
          </div>

          <div class="row-no-justify">
            <!--Return to Work?-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Return to Work?</mat-label>
              <mat-select
                formControlName="employeeWorkReturn"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--Return to Work Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Return to Work Date"
              formControlName="employeeWorkReturnDate"
              [disabled]="!canEditInjuries()"
            ></app-datetime-picker>
          </div>

          <h3 class="sub-header-other">Witness</h3>

          <div class="row">
            <!--Witness?-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Witness?</mat-label>
              <mat-select
                formControlName="witness"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="row-full-size">
            <!--Witness info-->
            <app-textarea-reactive
              class="full-size-field"
              placeholder="Enter example description here..."
              label="Witness info"
              formControlName="witnessInformation"
              [disabled]="!canEditInjuries()"
            ></app-textarea-reactive>
          </div>

          <h3 class="sub-header-other">Miscellaneous</h3>

          <div class="row">
            <!--Winter Cleats?-->
            <mat-form-field class="short-size-field" appearance="outline">
              <mat-label>Winter Cleats?</mat-label>
              <mat-select
                formControlName="employeeWinterCleats"
                placeholder="Select..."
                [disabled]="!canEditInjuries()"
              >
                <mat-option
                  *ngFor="let option of radioDefaultOptions"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <h3 class="sub-header-other">System</h3>

          <div class="row">
            <!--Created By-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Created By</mat-label>
              <input matInput formControlName="createdBy" />
            </mat-form-field>

            <!--Creation Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Creation Date"
              formControlName="creationDate"
              [disabled]="true"
            ></app-datetime-picker>
          </div>

          <div class="row">
            <!--Last Modified By-->
            <mat-form-field appearance="outline" class="normal-size-field">
              <mat-label>Last Modified By</mat-label>
              <input matInput formControlName="LastModifiedBy" />
            </mat-form-field>

            <!--Last Modified Date-->
            <app-datetime-picker
              class="normal-size-field"
              label="Last Modified Date"
              formControlName="LastModifiedDate"
              [disabled]="true"
            ></app-datetime-picker>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</div>

<mat-dialog-actions *ngIf="canEditInjuries()" class="action-buttons">
  <button mat-flat-button color="primary" (click)="save()">Save</button>
  <button mat-stroked-button color="warn" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
