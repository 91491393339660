<div
  [ngClass]="{ 'custom-select': true, 'pointer-cursor': !disabled }"
  (click)="onShowOptionsClick($event)"
>
  <label class="custom-select-label">{{ label }}</label>
  <div class="selected-option">
    <input
      (click)="handleClick($event)"
      class="custom-input"
      type="text"
      [disabled]="disabled"
      [(ngModel)]="searchTerm"
    />
    <i
      class="fa-solid fa-chevron-down"
      [class.rotate]="showOptions"
      (click)="onShowOptionsClick($event)"
    ></i>
  </div>
  <div class="options" [ngClass]="{ 'show-options': showOptions }">
    <div
      class="option"
      *ngFor="let option of filteredOptions"
      (click)="onToggleOptionClick($event, option)"
    >
      <app-checkbox
        [label]="option?.label"
        [checked]="isOptionSelected(option)"
      ></app-checkbox>
    </div>
  </div>
  <div
    class="block-options"
    [ngClass]="{ 'show-block-options': showOptionsBlock }"
  >
    <div class="option-item-block">
      <div
        class="option-item"
        *ngFor="let option of selectedOptions"
        (click)="onRemoveSelectedOptionClick($event, option)"
      >
        {{ option?.label }}
        <i class="fa-regular fa-circle-xmark icon"></i>
      </div>
    </div>
    <i class="fa-solid fa-xmark icon" (click)="onRemoveAllClick($event)"></i>
  </div>
</div>
