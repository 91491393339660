import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SharedService } from '@app/services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isExpanded!: boolean;
  isMenuOpened = false;

  constructor(private router: Router, private sharedService: SharedService) {
    // mobile menu is being collapsed on route change
    router.events.subscribe(() => {
      this.isMenuOpened = false;
    });

    this.setBodyClass(
      !!localStorage.getItem('[Safety] Dark Theme') ? 'dark' : 'light'
    );
  }

  get isBuildDev(): boolean {
    return ['localhost', '127.0.0.1'].includes(location.hostname);
  }

  ngOnInit() {
    this.sharedService.getIsExpanded().subscribe((isExpanded: boolean) => {
      this.isExpanded = isExpanded;
    });
  }

  toggleMenu(): void {
    this.isExpanded = !this.isExpanded;
    this.sharedService.setIsExpanded(this.isExpanded);
  }

  toggleMobileMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.isMenuOpened ? this.sharedService.setIsExpanded(true) : null;
  }

  setBodyClass(theme: string): void {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }
}
