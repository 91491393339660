import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-step-injury',
  templateUrl: './step-injury.component.html',
  styleUrls: ['./step-injury.component.scss'],
})
export class StepInjuryComponent implements OnInit {
  @Input() injuryForm!: FormGroup;

  ngOnInit(): void {
    this.setSubscriptionInjury();
  }

  isInjured(): boolean {
    return this.injuryForm.get('injury')?.value === 'true';
  }

  setSubscriptionInjury(): void {
    this.injuryForm.get('injury')?.valueChanges.subscribe((value) => {
      // prettier-ignore
      if (value === 'true') {
        this.injuryForm.get('injuryDescription')?.setValidators(Validators.required);
        this.injuryForm.get('medicalAssistance')?.setValidators(Validators.required);
      } else {
        this.injuryForm.get('injuryDescription')?.clearValidators();
        this.injuryForm.get('injuryDescription')?.reset();

        this.injuryForm.get('medicalAssistance')?.clearValidators();
        this.injuryForm.get('medicalAssistance')?.reset();
      }

      this.injuryForm.get('injuryDescription')?.updateValueAndValidity();
      this.injuryForm.get('medicalAssistance')?.updateValueAndValidity();
    });
  }
}
