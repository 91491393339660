import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { TimePickerComponent } from './time-picker.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [TimePickerComponent],
  exports: [TimePickerComponent],
  imports: [CommonModule, NgxMaterialTimepickerModule, MatButtonModule, MatDialogModule],
})
export class TimePickerModule {}
