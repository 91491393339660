import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { INote, Note } from '@app/models/note.model';
import { DriverNotesService } from '@app/services/driver-notes.service';
import { ShowImageModalComponent } from './show-image-modal/show-image-modal.component';
import { MediaService } from '@app/services/media.service';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { PermissionService } from '@app/services/permission.service';
import { Observable, Subject, catchError, map, of, takeUntil } from 'rxjs';
import { DialogEventService } from '../dialog-event/dialog-event.service';
import { UserManagementService } from '@app/services/user-management.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @Input() avatarUrl!: string;
  @Input() userName!: string;
  @Input() contents!: string;
  @Input() notes!: Note[];
  @Input() entityId!: string;
  @Input() sectionId!: string;
  @Input() entityType!: string;
  @Input() showEditor: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() assigneeId?: string;
  @Input() hideToolbar: boolean = false;

  isLoading: boolean = false;
  showViewMore: boolean = true;
  totalRecordCount: number = 0;
  maxAmountRecords: number = 5;
  pageSize: number = this.maxAmountRecords;

  selectedImages: File[] = [];
  selectedFiles: File[] = [];
  displayedImage: string | null = null;
  insertedId!: string;
  userInfo!: any;
  assigneeImages: { [assigneeId: string]: string | null } = {};
  users!: any;

  private destroy$ = new Subject<void>();

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private driverNotesService: DriverNotesService,
    private dialog: MatDialog,
    private mediaService: MediaService,
    private permissionService: PermissionService,
    private dialogEventService: DialogEventService,
    private userManagementService: UserManagementService
  ) {}

  ngOnInit(): void {
    this.loadLoggedUser();
    this.getUsers();
    this.setSubscriptionToStatusChange();
  }

  getUsers(): void {
    this.isLoading = true;
    const params = { type: 'Interventions' };
    this.userManagementService.getUsers(params).subscribe({
      next: (res) => {
        this.users = res[0].userResults;
        this.loadNotes();
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setSubscriptionToStatusChange(): void {
    this.dialogEventService.statusSelectionChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        setTimeout(() => this.loadNotes(), 200);
      });
  }

  loadLoggedUser(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userInfo = parsedData.userInfo;
      this.avatarUrl = this.userInfo.previewUrl;
    }
  }

  onImageSelected(event: any) {
    this.selectedImages = Array.from(event.target.files);
  }

  openImageDialog(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.dialog.open(ShowImageModalComponent, {
        data: { imgSrc: e.target.result },
        width: '400px',
        height: '400px',
      });
    };
    reader.readAsDataURL(file);
  }

  removeSelectedImage(index: number) {
    this.selectedImages.splice(index, 1);
  }

  removeSelectedFile(index: number) {
    this.selectedFiles.splice(index, 1);
  }

  onFileSelected(event: any) {
    this.selectedFiles = Array.from(event.target.files);
  }

  onEditorContentChange(newContents: string) {
    this.contents = newContents;
  }

  createNote(): void {
    this.insertedId = '';
    let userId = '';
    userId = this.userInfo.objectId;
    this.isLoading = true;

    this.driverNotesService
      .createNote({
        id: null,
        entity_id: this.entityId,
        entity_type: this.entityType,
        user_id: userId,
        createdBy: userId,
        lastModifiedBy: userId,
        message: JSON.stringify(this.contents),
        images: 0,
        deleted: 0,
      })
      .subscribe({
        next: (res) => {
          this.loadNotes();
          let parsedObject = res;
          this.insertedId = parsedObject[0].InsertedId;
          if (this.selectedImages.length) {
            this.linkImagesToNote();
          }
          if (this.selectedFiles.length) {
            this.linkFilesToNote();
          }
          this.contents = '';
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  isImageFile(fileName: string): boolean {
    const imageFileExtensions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'];
    const extension = fileName.split('.').pop()?.toLowerCase();
    return imageFileExtensions.includes(extension || '');
  }

  linkFilesToNote(): void {
    if (this.selectedFiles.length > 0) {
      this.selectedFiles.forEach((file) => {
        const formData = new FormData();
        formData.append(`file`, file, file.name);
        this.mediaService.upload(formData, 'noteAttachment', this.insertedId).subscribe({
          next: () => {
            this.selectedFiles = [];
          },
          error: (error) => {
            console.error('Error uploading files', error);
          },
        });
      });
    }
  }

  linkImagesToNote(): void {
    if (this.selectedImages.length > 0) {
      this.selectedImages.forEach((file) => {
        const formData = new FormData();
        formData.append(`file`, file, file.name);
        this.mediaService.upload(formData, 'noteAttachment', this.insertedId).subscribe({
          next: () => {
            this.selectedImages = [];
          },
          error: (error) => {
            console.error('Error uploading images', error);
          },
        });
      });
    }
  }

  getAssigneeImage(userId: string): Observable<string | null> {
    if (this.assigneeImages[userId]) {
      return of(this.assigneeImages[userId]);
    }

    return this.mediaService.downloadFile('userProfileImage', userId).pipe(
      map((blob: Blob) => {
        const imageUrl = URL.createObjectURL(blob);
        this.assigneeImages[userId] = imageUrl;
        return imageUrl;
      }),
      catchError((error) => {
        console.error(`Failed to load image for user ${userId}:`, error);
        return of('');
      })
    );
  }

  loadNotes(): void {
    const entityId = this.entityId;
    const page = 1;
    const pageSize = this.pageSize;

    this.driverNotesService
      .getNotes(this.entityType, entityId, page, pageSize)
      .subscribe({
        next: (response: any) => {
          const notes = response[0].notes;
          this.totalRecordCount = response[0].totalRecordCount;
          if (notes) {
            this.notes = [];
            notes.forEach((note: INote) => {
              const tempNote = {
                avatarUrl: '',
                userName: note.user_name ? note.user_name : '',
                noteDate: note.creation_date ? this.extractDate(note.creation_date) : '',
                noteTime: note.creation_date ? this.extractTime(note.creation_date) : '',
                noteMessage: this.parseMessage(note.message),
                noteId: note.id!,
                active: note.active,
              };
              this.getAssigneeImage(note.user_id).subscribe((imageUrl) => {
                tempNote.avatarUrl = imageUrl ? imageUrl : '';
              });
              this.notes.push(tempNote);
            });
          }
          this.fetchFilesForNote();
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  fetchFilesForNote(): void {
    this.notes.forEach((note, index) => {
      if (note.noteId) {
        this.mediaService.downloadFileList('noteAttachment', note.noteId!).subscribe({
          next: (res) => {
            if (res && res[0] && res[0].files) {
              this.notes[index].files = res[0].files;
            }
          },
          error: (error) => {
            console.error(error);
          },
        });
      }
    });
  }

  downloadFile(fileName: string, noteId: string): void {
    this.mediaService.downloadFile('noteAttachment', noteId, fileName).subscribe({
      next: (blob) => {
        const fileDownloadable = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileDownloadable;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return URL.revokeObjectURL(fileDownloadable);
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  parseMessage(message: string): string {
    const htmlString = message;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const lists = doc.querySelectorAll('ul, ol');

    lists.forEach((list) => {
      let subList: any = null;

      list.querySelectorAll('li').forEach((item, index) => {
        if (item.classList.contains('ql-indent-1')) {
          if (!subList) {
            subList = document.createElement(list.tagName);
            list.children[index - 1].appendChild(subList);
          }
          subList.appendChild(item);
        } else {
          subList = null;
        }
      });
    });
    return doc.body.innerHTML;
  }

  showAllNotes(): void {
    this.pageSize = this.totalRecordCount;
    this.loadNotes();
    this.showViewMore = false;
  }

  extractDate(isoString: string): string {
    const date = new Date(isoString);
    return `${date.getMonth() + 1}/${date.getDate()}/${date
      .getFullYear()
      .toString()
      .substr(-2)}`;
  }

  extractTime(isoString: string): string {
    const utcDate = new Date(isoString + 'Z');

    let hours = utcDate.getHours();
    const minutes =
      utcDate.getMinutes() < 10 ? `0${utcDate.getMinutes()}` : utcDate.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;

    const offset = -utcDate.getTimezoneOffset();
    const offsetHours = Math.floor(offset / 60);

    return `${hours}:${minutes} ${ampm}`;
  }

  canWriteNotes(): boolean {
    switch (this.entityType) {
      case 'intervention':
        return (
          (this.permissionService.hasPermission('WRITE', 'My Interventions') &&
            this.userInfo.objectId === this.assigneeId) ||
          this.permissionService.hasPermission('WRITE', 'Intervention Notes')
        );
      case 'event':
        return this.permissionService.hasPermission('WRITE', 'Event Notes');
      case 'driver_profile':
        return this.permissionService.hasPermission('WRITE', 'Driver Notes');
      default:
        return false;
    }
  }

  setFullName(user: any): string {
    return `${user?.firstName} ${user?.lastName}`;
  }
}
