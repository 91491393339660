import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { convertToUSDateFormatWithTime12H } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatetimePickerComponent),
      multi: true,
    },
  ],
})
export class DatetimePickerComponent implements ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() hideTime: boolean = false;
  @Input() required?: boolean;

  innerValue: string = '';
  onChange = (value: any) => {};
  onTouched = () => {};

  get value(): string {
    return this.innerValue
      ? convertToUSDateFormatWithTime12H(this.innerValue)
      : '';
  }

  set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onDateChange(event: any): void {
    const date = event.value;
    this.value = convertToUSDateFormatWithTime12H(date);
    this.onChange(this.value);
  }
}
