import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import {
  ISpeedPercentageElement,
  PercentageSpeedingSource,
} from '@app/models/speed.model';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { convertDateFormat, convertMinutesToHHmm } from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-dialog-driver-speeding',
  templateUrl: './dialog-driver-percentage-speeding.component.html',
  styleUrls: ['./dialog-driver-percentage-speeding.component.scss'],
})
export class DialogDriverPercentageSpeedingComponent implements OnInit {
  isLoading: boolean = true;
  percentageSpeedingRowData!: ISpeedPercentageElement;
  percentageSpeedingDetail!: any;
  activityDetailsColumns: string[] = [
    'date',
    'day',
    'totalDistanceTraveled',
    'driveTime',
    'maxSpeed',
  ];
  activityDetailsSummary!: any;
  source!: string;
  breakdownColumnsSamsara: string[] = ['type', 'time', 'percentage'];
  breakdownColumnsSpeedguage: string[] = [
    'type',
    'incidents',
    'observations',
    'percentage',
  ];
  breakdownSummary!: any;

  percentageSpeedingSource = PercentageSpeedingSource;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogInfoService: DialogDriverService
  ) {
    this.percentageSpeedingRowData = data;
  }

  ngOnInit(): void {
    if (this.percentageSpeedingRowData && this.percentageSpeedingRowData.id) {
      this.dialogInfoService
        .getPercentageSpeedingDetail(this.percentageSpeedingRowData.id)
        .subscribe({
          next: (response: ISpeedPercentageElement[]) => {
            this.percentageSpeedingDetail = response[0];
            this.setSource();
            this.setActivityDetailsSummary();
            this.setBreakdownSummary();
            this.isLoading = false;
          },
          error: (error: any) => {
            console.error('Error:', error);
            this.isLoading = false;
          },
        });
    }
  }

  setSource(): void {
    this.source = this.percentageSpeedingDetail.Source ?? '-';
  }

  setActivityDetailsSummary(): void {
    this.activityDetailsSummary = [
      {
        date: convertDateFormat(this.percentageSpeedingDetail.date),
        day: this.getDayName(this.percentageSpeedingDetail.date),
        totalDistanceTraveled: this.percentageSpeedingDetail.totalDriveDistance,
        driveTime: this.percentageSpeedingDetail.totalDriveTime
          ? convertMinutesToHHmm(+this.percentageSpeedingDetail.totalDriveTime)
          : '-',
        maxSpeed: this.percentageSpeedingDetail.totalPercentSpeeding,
      },
    ];
  }

  setBreakdownSummary(): void {
    if (this.source === PercentageSpeedingSource.SAMSARA) {
      // prettier-ignore
      this.breakdownSummary = [
        {
          type: 'Light Speeding',
          percentage:
            this.percentageSpeedingDetail?.lightCriteria ? this.percentageSpeedingDetail?.lightCriteria[0]?.lightSpeedingPercentage : '',
          time: this.percentageSpeedingDetail?.lightCriteria ? this.percentageSpeedingDetail?.lightCriteria[0]?.lightSpeedingTime : '',
          eventCount: this.percentageSpeedingDetail?.lightCriteria ? this.percentageSpeedingDetail?.lightCriteria[0]?.lightSpeedingCount : '',
        },
        {
          type: 'Moderate Speeding',
          percentage:
            this.percentageSpeedingDetail?.moderateCriteria ? this.percentageSpeedingDetail?.moderateCriteria[0]?.moderateSpeedingPercentage : '',
          time: this.percentageSpeedingDetail?.moderateCriteria ? this.percentageSpeedingDetail?.moderateCriteria[0]?.moderateSpeedingTime : '',
          eventCount:
            this.percentageSpeedingDetail?.moderateCriteria ? this.percentageSpeedingDetail?.moderateCriteria[0]?.moderateSpeedingCount : '',
        },
        {
          type: 'Heavy Speeding',
          percentage: this.percentageSpeedingDetail?.heavyCriteria ? this.percentageSpeedingDetail?.heavyCriteria[0]?.heavySpeedingPercentage : '',
          time: this.percentageSpeedingDetail?.heavyCriteria ? this.percentageSpeedingDetail?.heavyCriteria[0]?.heavySpeedingTime : '',
          eventCount: this.percentageSpeedingDetail?.heavyCriteria ? this.percentageSpeedingDetail?.heavyCriteria[0]?.heavySpeedingCount : '',
        },
        {
          type: 'Severe Speeding',
          percentage: this.percentageSpeedingDetail?.severeCriteria ? this.percentageSpeedingDetail?.severeCriteria[0]?.severeSpeedingPercentage : '',
          time: this.percentageSpeedingDetail?.severeCriteria ? this.percentageSpeedingDetail?.severeCriteria[0]?.severeSpeedingTime : '',
          eventCount: this.percentageSpeedingDetail?.severeCriteria ? this.percentageSpeedingDetail?.severeCriteria[0]?.severeSpeedingCount : '',
        },
      ];
    }
    if (this.source === PercentageSpeedingSource.SPEEDGUAGE) {
      this.breakdownSummary = [
        {
          type: 'Interstate',
          incidents: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.interstateCriteria
            ? this.percentageSpeedingDetail?.interstateCriteria[0]?.speedingPercentage
            : '',
        },
        {
          type: 'Non-interstate',
          incidents: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.nonInterstateCriteria
            ? this.percentageSpeedingDetail?.nonInterstateCriteria[0]?.speedingPercentage
            : '',
        },
        {
          type: 'Total',
          incidents: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.countOfIncidents
            : '',
          observations: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.countOfObservations
            : '',
          percentage: this.percentageSpeedingDetail?.totalCriteria
            ? this.percentageSpeedingDetail?.totalCriteria[0]?.speedingPercentage
            : '',
        },
      ];
    }
  }

  getDayName(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }
}
