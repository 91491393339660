<div class="height">
  <p class="title-radio">{{ label }}</p>
  <div [ngClass]="{ 'radio-block': !vertical }" class="height">
    <div class="radio-item" *ngFor="let option of options">
      <input
        class="radio"
        type="radio"
        [value]="option.value"
        [name]="label"
        [checked]="selectedOption === option.value"
        (change)="onSelectionChange(option.value)"
        [disabled]="disabled"
      />
      <label>{{ option.label }}</label>
    </div>
  </div>
</div>
