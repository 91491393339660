import { Component, Input } from '@angular/core';

import { Section } from '@app/models/dialog-info-sections';
import { TabHistorySections } from '../coaching.constants';
import { DialogEventService } from '@app/shared/components/dialog-event/dialog-event.service';
import { PermissionService } from '@app/services/permission.service';

@Component({
  selector: 'app-tab-section-history',
  templateUrl: './tab-section-history.component.html',
  styleUrls: ['./tab-section-history.component.scss'],
})
export class TabSectionHistoryComponent {
  @Input() driverId!: string;
  @Input() showMenu!: boolean;

  sectionSelected: string = 'events';

  readonly sections: Section[] = TabHistorySections;

  constructor(
    private dialogInfoService: DialogEventService,
    private permissionService: PermissionService
  ) { }

  onNavClick(elementId: string): void {
    this.dialogInfoService.onNavClick(elementId);
  }

  canViewEvents(): boolean {
    return this.permissionService.hasPermission('READ', 'Events')
      || this.permissionService.hasPermission('WRITE', 'Events')
    ;
  }

  canViewInterventions(): boolean {
    return this.permissionService.hasPermission('READ', 'All Interventions')
      || this.permissionService.hasPermission('WRITE', 'All Interventions')
      ;
  }
}
