import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-filter-values',
  templateUrl: './dialog-filter-values.component.html',
  styleUrls: ['./dialog-filter-values.component.scss'],
})
export class DialogFilterValuesComponent {
  shouldClose: boolean = false;
  changesMade = false;

  constructor(
    public dialogRef: MatDialogRef<DialogFilterValuesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.beforeClosed().subscribe(() => {
      if (!this.shouldClose && this.changesMade) {
        this.shouldClose = true;
        this.dialogRef.close(this.data.filterValues);
      }
    });
  }

  clearAllDialog(): void {
    this.data.filterValues = [];
    this.dialogRef.close(this.data.filterValues);
  }

  removeItem(index: number) {
    this.data.filterValues.splice(index, 1);
    this.changesMade = true;
  }
}
