import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent {
  @Input() type!: 'High' | 'Moderate' | 'Low' | '';
  @Input() withLabel: boolean = false;
  @Input() score?: number | undefined = 0;

  getLabel(): string {
    switch (this.type) {
      case 'High':
        return 'High Performer:';
      case 'Moderate':
        return 'Moderate Performer:';
      case 'Low':
        return 'Low Performer:';
      default:
        return '';
    }
  }
}
