import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DriverIdService {
  private driverIdSubject = new BehaviorSubject<string | null>(null);

  constructor() { }

  setDriverId(driverId: string | null): void {
    this.driverIdSubject.next(driverId);
  }

  getDriverId(): string | null {
    return this.driverIdSubject.value;
  }

  driverIdChanges(): Observable<string | null> {
    return this.driverIdSubject.asObservable();
  }
}
