<mat-dialog-actions>
  <div class="dialog-close">
    <i class="fas fa-times" (click)="close()"></i>
  </div>
  <div mat-dialog-title>Score History</div>
</mat-dialog-actions>

<mat-dialog-content>
  <mat-tab-group #tabGroup animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <i class="fas fa-chart-line"></i>
        <span>Score Over Time</span>
      </ng-template>
      <section>
        <span class="title">Score Over Time</span>
        <div class="article-actions">
          <app-date-range-picker
            positionOffset="right"
            [rounded]="true"
            [startDate]="dateRange.controls.start.value"
            [endDate]="dateRange.controls.end.value"
            (dateRangeSelected)="onDateRangeSelected($event)"
          >
          </app-date-range-picker>
        </div>
        <!-- Custom Legends -->
        <div class="custom-legend">
          <div class="legend-item">
            <span class="legend-color intervention"></span>
            Intervention
          </div>
          <div class="legend-item">
            <span class="legend-color event"></span>
            Event
          </div>
          <div class="legend-item">
            <span class="legend-color score"></span>
            Score
          </div>
        </div>
        <div class="canvas-container" [style.display]="isLoading ? 'none' : 'block'">
          <canvas id="myChart"></canvas>
        </div>
        <div *ngIf="isLoading" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="50"
            class="spinner"
          ></mat-progress-spinner>
        </div>
      </section>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
