import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExpandViewService {
  // The event to trigger the expansion of all components
  private expandViewSubject = new Subject<void>();
  expandView$ = this.expandViewSubject.asObservable();

  // The counter for how many components have completed expansion
  private expandViewCounter = new BehaviorSubject<number>(0);
  expandViewCounter$ = this.expandViewCounter.asObservable();

  // Trigger the expansion
  triggerExpandView() {
    this.expandViewSubject.next();
  }

  // Increment the counter when a component finishes loading its data
  incrementExpandView() {
    const currentValue = this.expandViewCounter.value;
    this.expandViewCounter.next(currentValue + 1);
  }

  // Reset the counter
  resetExpandView() {
    this.expandViewCounter.next(0);
  }
}
