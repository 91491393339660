<div class="modal-container">
  <div class="header-modal">
    <h1 class="title-modal">Create intervention</h1>
    <mat-icon class="icon-color">
      <span class="material-symbols-outlined" mat-dialog-close>close</span>
    </mat-icon>
  </div>
  <mat-divider></mat-divider>
  <div class="block-form">
    <div class="input-container">
      <p class="input-size">
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Linked event</mat-label>
          <input matInput placeholder="Placeholder" />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Event Source</mat-label>
          <mat-select>
            <mat-option> Test </mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Priority</mat-label>
          <mat-select>
            <mat-option> Test </mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Driver ID</mat-label>
          <input matInput placeholder="Placeholder" />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Intervention Type</mat-label>
          <mat-select>
            <mat-option> Test </mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Due Date</mat-label>
          <input matInput [matDatepicker]="picker" />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Coach</mat-label>
          <input matInput placeholder="Placeholder" />
        </mat-form-field>
      </p>
      <p>
        <mat-form-field class="input-size" appearance="outline">
          <mat-label>Reason</mat-label>
          <input matInput placeholder="Placeholder" />
        </mat-form-field>
      </p>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="script-container">
    <h1 class="title-modal">Script</h1>
    <div class="editor-container">
      <quill-editor id="editor" [(ngModel)]="script"></quill-editor>
    </div>
  </div>
  <div class="notes-container">
    <h1 class="title-modal">Notes</h1>
    <textarea placeholder="Enter notes here…" class="text-area"></textarea>
  </div>
  <div mat-dialog-actions class="button-container">
    <button class="cancel-button" mat-dialog-close>Close</button>
    <button class="save-change-button" mat-button mat-dialog-close>
      <span class="save-change-button">
        <span class="button-name">Create</span></span
      >
    </button>
  </div>
</div>
