import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
})
export class RadioButtonComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() options: {
    label: string;
    value: string | number | boolean;
  }[] = [];
  @Input() selectedOption: string = '';
  @Input() vertical: boolean = false;
}
