import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Subject, filter, takeUntil } from 'rxjs';

import { StatesList } from '@app/drivers/dialog-driver/dialog-driver.constants';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { SelectOptionItem } from '@app/shared/models';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  formatDateIsoString,
  convertToUSDateFormatWithTime12H,
} from '@app/shared/helpers/date.helper';
import { DriverService } from '@app/services/driver.service';
import { ToasterService } from '@app/services/toaster.service';
import { PermissionService } from '@app/services/permission.service';
import { UserManagementService } from '@app/services/user-management.service';
import {
  convertStringToArray,
  prepareCommaSeparatedString,
} from '@app/shared/helpers/string.helper';
import { areaBodyOptions } from '@app/models/area.body.model';
import { UtcDatePipe } from '@app/shared/pipes/utc-date.pipe';

@Component({
  selector: 'app-dialog-driver-injury',
  templateUrl: './dialog-driver-injury.component.html',
  styleUrls: ['./dialog-driver-injury.component.scss'],
})
export class DialogDriverInjuryComponent implements OnInit, OnDestroy {
  states: SelectOptionItem[] = StatesList.map((item: string) => ({
    label: item,
    value: item,
  }));
  injuryData!: any;
  injuryForm!: FormGroup;
  formatDateIsoString = formatDateIsoString;
  convertToUSDateFormatWithTime12H = convertToUSDateFormatWithTime12H;
  radioDefaultOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];
  users: any[] = [];
  isLoading: boolean = false;
  readOnly: boolean = false;

  areaBodySelected = [];
  areaBodyOptions = areaBodyOptions;

  cssContent: string = '';
  isLoadingPrintWeb: boolean = false;
  showPrintComponent: boolean = false;

  private destroy$ = new Subject<void>();
  private utcDatePipe = new UtcDatePipe();
  private printIframe!: HTMLIFrameElement | null;

  @ViewChild('modalContainer', { static: false }) modalContainer!: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DialogDriverInjuryComponent>,
    private dialogDriverService: DialogDriverService,
    private fb: FormBuilder,
    private driverService: DriverService,
    private toasterService: ToasterService,
    private permissionService: PermissionService,
    private userManagementService: UserManagementService,
    private http: HttpClient
  ) {
    this.initializeInjuryForm();
  }

  ngOnInit(): void {
    this.setReadOnlyView();
    this.fetchInjuryDetails();
    if (!this.canEditInjuries()) {
      this.injuryForm.disable();
    }
    this.loadPrintStyles();
  }

  loadPrintStyles(): void {
    this.http
      .get('assets/css/print-screen-injury.css', { responseType: 'text' })
      .subscribe({
        next: (css) => {
          this.cssContent = css;
        },
        error: (error) => {
          console.error(error);
        },
      });
  }

  getCurrentUser() {
    const userDetailsString = localStorage.getItem('userData');
    if (userDetailsString) {
      const userDetails = JSON.parse(userDetailsString);
      const { objectId: userId, firstName, lastName } = userDetails.userInfo;
      return { userId, firstName, lastName };
    } else {
      console.error('User details not found in local storage');
      return null;
    }
  }

  isSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.includes('safari') && !ua.includes('chrome');
  }

  generateModalHTML(): string {
    const eventTitle = `${this.data?.driverId} - Accident`;

    const currentUser = this.getCurrentUser();
    const userName = currentUser
      ? `${currentUser.firstName} ${currentUser.lastName}`
      : 'Unknown User';

    return `
      <html>
        <head>
          <title>${eventTitle} (Generated for ${userName})</title>
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" rel="stylesheet">
          <style>${this.cssContent}</style>
        </head>
        <body>
          <div class="button-container">
            <button onclick="window.close()">Go Back</button>
            <button onclick="window.print()">Print</button>
          </div>
          <div class="printable-modal">
            ${this.modalContainer.nativeElement.innerHTML}
          </div>
        </body>
      </html>
    `;
  }

  printContent(): void {
    this.isLoadingPrintWeb = true;
    this.showPrintComponent = true;

    if (this.isSafari()) {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          // Reuse the iframe if it has already been created
          if (!this.printIframe) {
            this.printIframe = document.createElement('iframe');
            this.printIframe.style.position = 'absolute';
            this.printIframe.style.width = '0px';
            this.printIframe.style.height = '0px';
            this.printIframe.style.border = 'none';
            document.body.appendChild(this.printIframe);
          }

          const iframeDoc =
            this.printIframe.contentWindow?.document || this.printIframe.contentDocument;
          if (iframeDoc) {
            iframeDoc.open();
            iframeDoc.write(modalHTML);
            iframeDoc.close();

            // Wait for the iframe to load before executing print
            this.printIframe.onload = () => {
              this.printIframe?.contentWindow?.focus();
              this.printIframe?.contentWindow?.print();
            };
          }
        }
        this.isLoadingPrintWeb = false;
        this.showPrintComponent = false;
      }, 1000);
    } else {
      setTimeout(() => {
        if (this.modalContainer) {
          const modalHTML = this.generateModalHTML();

          const blob = new Blob([modalHTML], { type: 'text/html' });
          const url = URL.createObjectURL(blob);

          const printWindow = window.open(url, '_blank');

          if (printWindow) {
            printWindow.onload = () => {};
          }
        }
        this.isLoadingPrintWeb = false;
        this.showPrintComponent = false;
      }, 1000);
    }
  }

  setReadOnlyView(): void {
    if (this.data?.readOnly !== undefined && this.data?.readOnly !== null) {
      this.readOnly = this.data.readOnly;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeInjuryForm(): void {
    this.injuryForm = this.fb.group({
      creationDate: [{ value: null, disabled: true }],
      driverId: [{ value: null, disabled: true }],
      driverName: [{ value: null, disabled: true }],
      employeePhone: [null],
      employeeWinterCleats: [null],
      employeeWorkLeave: [null],
      employeeWorkLeaveDate: [null],
      employeeWorkReturn: [null],
      employeeWorkReturnDate: [null],
      extentOfInjuryDescription: [null],
      fleetManager: [{ value: null, disabled: true }],
      id: [this.data.id],
      injuryAddress1: [null],
      injuryAddress2: [null],
      injuryAreaOfBody: [null],
      injuryCity: [null],
      injuryDate: [null],
      injuryHowOccurred: [null],
      injuryLocationOtherDetails: [null],
      injuryReported: [null],
      injuryReportedDate: [null],
      injuryReportedTo: [null],
      injuryState: [null],
      injuryZip: [null],
      lastModified: [null],
      medicalAssistanceRequired: [null],
      medicalWhereReceived: [null],
      organization: [{ value: null, disabled: true }],
      reportToWorkDate: [null],
      safetyManager: [{ value: null, disabled: true }],
      terminal: [{ value: null, disabled: true }],
      witness: [null],
      witnessInformation: [null],
      createdBy: [{ value: null, disabled: true }],
      LastModifiedBy: [{ value: null, disabled: true }],
      LastModifiedDate: [{ value: null, disabled: true }],
    });
  }

  save(): void {
    const reportToWorkDate = this.injuryForm.get('reportToWorkDate')?.value
      ? this.convertDateFormat(this.injuryForm.get('reportToWorkDate')?.value)
      : '';
    const formValues = { ...this.injuryForm.getRawValue() };
    formValues.reportToWorkDate = reportToWorkDate;

    formValues.injuryAreaOfBody = prepareCommaSeparatedString(
      formValues.injuryAreaOfBody
    );

    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      formValues.UserId = parsedData.userInfo.objectId;
    }

    this.driverService
      .upsertInjury(formValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.dialogRef.close();
          this.toasterService.showSuccess('The Injury Detail was edited successfully');
        },
        error: (error) => {
          this.toasterService.showError(error.error);
          console.error('Error saving injury', error);
        },
      });
  }

  fetchInjuryDetails(): void {
    this.isLoading = true;
    this.dialogDriverService
      .getInjuryDetail(this.data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.injuryData = res[0];
          this.injuryData.injuryAreaOfBody = convertStringToArray(
            this.injuryData.injuryAreaOfBody
          );
          this.areaBodySelected = this.injuryData.injuryAreaOfBody;
          this.fetchUsers();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  fetchUsers(): void {
    const request = {};
    this.userManagementService
      .getUsers(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.users = res[0]?.userResults;
          this.assignUserData('createdBy');
          this.assignUserData('LastModifiedBy');
          this.injuryData.creationDate = this.utcDatePipe.transform(
            this.injuryData.creationDate,
            'M/d/yyyy h:mm a'
          );
          this.injuryData.LastModifiedDate = this.utcDatePipe.transform(
            this.injuryData.LastModifiedDate,
            'M/d/yyyy h:mm a'
          );
          this.injuryForm.patchValue(this.injuryData);
          this.setManagersInForm();
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
        },
      });
  }

  assignUserData(field: 'createdBy' | 'LastModifiedBy'): void {
    const user = this.users.find((user) => user.userId === this.injuryData[field]);
    if (user) {
      this.injuryData[field] = `${user.firstName} ${user.lastName}`;
    }
  }

  setManagersInForm(): void {
    const fleetManagerName = this.injuryData?.managers?.[1]?.managerName;
    const safetyManagerName = this.injuryData?.managers?.[0]?.managerName;
    if (fleetManagerName) {
      this.injuryForm.get('fleetManager')?.setValue(fleetManagerName);
    }
    if (safetyManagerName) {
      this.injuryForm.get('safetyManager')?.setValue(safetyManagerName);
    }
  }

  onDialogCloseClick(): void {
    if (this.injuryForm.touched) {
      const data = {
        title: 'Unsaved changes',
        text: 'You have unsaved changes. Are you sure you want to leave this page? The changes you made will not be saved.',
      };
      this.dialog
        .open(DialogConfirmComponent, { data, width: '500px' })
        .afterClosed()
        .pipe(filter((closeModal: boolean) => closeModal))
        .subscribe(() => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }

  convertDateFormat(dateStr: string) {
    const isoFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    const amPmFormat = /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2} (AM|PM)$/;

    if (isoFormat.test(dateStr)) {
      return dateStr.replace('T', ' ');
    } else if (amPmFormat.test(dateStr)) {
      const parts = dateStr.match(/(\d{2})\/(\d{2})\/(\d{4}) (\d{1,2}):(\d{2}) (AM|PM)/);
      if (parts) {
        const [, month, day, year, hour, minute, period] = parts;
        let hourNumber = parseInt(hour, 10);
        if (period === 'PM' && hourNumber < 12) {
          hourNumber += 12;
        }
        if (period === 'AM' && hourNumber === 12) {
          hourNumber = 0;
        }
        const hourFormatted = hourNumber.toString().padStart(2, '0');
        return `${year}-${month}-${day} ${hourFormatted}:${minute}:00`;
      }
    }
    return dateStr;
  }

  canEditInjuries(): boolean {
    return !this.readOnly && this.permissionService.hasPermission('WRITE', 'Injury');
  }

  handleAreaBodySelected(elements: any): void {
    if (elements) {
      this.injuryForm.get('injuryAreaOfBody')?.setValue(elements);
    } else {
      this.injuryForm.get('injuryAreaOfBody')?.setValue([]);
    }
  }
}
