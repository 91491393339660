import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ExpandViewService } from '@app/services/expand-view.service';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';

@Component({
  selector: 'app-activity-telematics',
  templateUrl: './activity-telematics.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class ActivityTelematicsComponent implements OnInit, OnDestroy {
  @Input() eventActivities!: any;
  @Input() maxDisplayedRecords!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  columns: string[] = ['date', 'time', 'type', 'video'];
  showViewMore: boolean = false;
  isViewMore: boolean = true;
  pageSize!: number;
  dataFiltered: any[] = [];
  addSpacesToCamelCase = addSpacesToCamelCase;

  subscription!: Subscription;

  constructor(private expandViewService: ExpandViewService) {}

  ngOnInit(): void {
    this.setShowViewMore();
    this.pageSize = this.maxDisplayedRecords;
    this.setDataFiltered();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.loadMore(true);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: 'telematics' });
  }

  setShowViewMore(): void {
    if (
      this.eventActivities?.telematicsActivities[0].telematicsSummaries?.length >
      this.maxDisplayedRecords
    ) {
      this.showViewMore = true;
    }
  }

  setDataFiltered(): void {
    if (this.pageSize === 0) {
      this.dataFiltered = [
        ...this.eventActivities?.telematicsActivities[0].telematicsSummaries,
      ];
    } else {
      this.dataFiltered = this.eventActivities?.telematicsActivities[0]
        .telematicsSummaries
        ? this.eventActivities?.telematicsActivities[0].telematicsSummaries?.slice(
            0,
            this.pageSize
          )
        : [];
    }
  }

  loadMore(expandViewMore?: boolean): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : 0;
    this.setDataFiltered();
  }
}
