import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent {
  @Input() disabled?: boolean;
  @Input() width?: string;
  @Input() height?: string;
  @Input() label?: string;
  @Input() margin?: string;
  @Input() required?: boolean;
  @Output() timeChanged: EventEmitter<string> = new EventEmitter();

  constructor() {}

  get styles(): { [key: string]: string } {
    const params: any = {};
    if (this.width?.length) {
      params.width = this.width;
    }
    if (this.height?.length) {
      params.height = this.height;
    }
    if (this.margin?.length) {
      params.margin = this.margin;
    }
    return params;
  }

  get clockStyle() {
    return {
      clockFace: {
        clockFaceTimeActiveColor: 'var(--shade9)',
        clockHandColor: 'var(--shade9)',
      },
      container: {
        buttonColor: 'var(--shade1)',
      },
    };
  }

  onTimeChanged(time: string): void {
    this.timeChanged.emit(time);
  }
}
