import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private isExpanded = new BehaviorSubject<boolean>(true);
  private isMobile = new BehaviorSubject<boolean>(true);

  setIsExpanded(value: boolean): void {
    this.isExpanded.next(value);
    localStorage.setItem('sidenavConfigData', JSON.stringify({ isExpanded: value }));
  }

  getIsExpanded() {
    const storedSidenavConfigData = localStorage.getItem('sidenavConfigData');
    if (storedSidenavConfigData) {
      const sidenavConfigData = JSON.parse(storedSidenavConfigData);
      this.setIsExpanded(sidenavConfigData.isExpanded);
    }
    return this.isExpanded.asObservable();
  }

  setIsMobile(value: boolean): void {
    this.isMobile.next(value);
  }

  getIsMobile() {
    return this.isMobile.asObservable();
  }
}
