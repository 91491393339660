<table mat-table matSort [dataSource]="dataFiltered" class="table-header-sticky">
  <ng-container matColumnDef="inspectionDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.inspectionDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="reportNumber">
    <th mat-header-cell *matHeaderCellDef>Inspection Id</th>
    <td mat-cell *matCellDef="let element">{{ element?.reportNumber }}</td>
  </ng-container>

  <ng-container matColumnDef="reportState">
    <th mat-header-cell *matHeaderCellDef>State</th>
    <td mat-cell *matCellDef="let element">{{ element?.reportState }}</td>
  </ng-container>

  <ng-container matColumnDef="level">
    <th mat-header-cell *matHeaderCellDef>Inspection Level</th>
    <td mat-cell *matCellDef="let element">{{ element?.level }}</td>
  </ng-container>

  <ng-container matColumnDef="violation">
    <th mat-header-cell *matHeaderCellDef>Violation</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.violations && element?.violations[0]?.basic ? "Yes" : "No" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    (click)="onRowClick(row)"
    class="clickable"
  ></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
