export enum FlyoutType {
  EVENT = 'event',
  DRIVER = 'driver',
  COACHING = 'coaching',
  SCORE = 'score',
  USER = 'user',
  ADMIN_ROLES = 'admin-roles',
  ADMIN_ROLES_ADD_MEMBERS = 'admin-roles-add-members',
  BEHAVIOR_RULES = 'behavior-rules',
  ADD_REMOVE_CRITERIA = 'add-remove-criteria',
  EDIT_QUALIFICATION_FILES = 'edit-qualification-files',
  QUALIFICATION_FILES = 'qualification-files',
  AUTO_WORKFLOWS = 'auto-workflows',
}
