<div [formGroup]="timePlaceForm">
  <div class="stepper-container w-500">
    <h3>When did the accident occur?</h3>
    <div class="row">
      <app-date-time-picker
        label="Date"
        [hideTime]="true"
        [required]="true"
        [value]="date"
        (dateChanged)="updateDate($event)"
      ></app-date-time-picker>
      <app-time-picker
        label="Time"
        (timeChanged)="updateTime($event)"
      ></app-time-picker>
    </div>
  </div>
  <div class="stepper-container">
    <h3>Where did the accident occur?</h3>
    <div class="row">
      <mat-label class="label">Address Line 1</mat-label>
      <mat-form-field>
        <input matInput formControlName="addressLine1" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-label class="label">Address Line 2</mat-label>
      <mat-form-field>
        <input matInput formControlName="addressLine2" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-label class="label">City*</mat-label>
      <mat-form-field>
        <input matInput formControlName="city" required />
      </mat-form-field>
    </div>
    <div class="row state">
      <mat-label class="label">State*</mat-label>
      <mat-form-field>
        <input matInput formControlName="state" required />
      </mat-form-field>
      <mat-label class="label">Zip</mat-label>
      <mat-form-field>
        <input type="number" matInput formControlName="zip" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-label class="label">Other Location Details</mat-label>
      <mat-form-field>
        <textarea matInput formControlName="otherLocationDetails"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
