import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-additional-info',
  templateUrl: './step-additional-info.component.html',
  styleUrls: ['./step-additional-info.component.scss'],
})
export class StepAdditionalInfoComponent {
  @Input() additionalInfoForm!: FormGroup;

  isAWitness(): boolean {
    return this.additionalInfoForm.get('witness')?.value === 'true';
  }
}
