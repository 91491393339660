import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//material
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { InputModule } from '@app/shared/components/input/input.module';
import { TimePickerModule } from '@app/shared/components/time-picker/time-picker.module';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { RadioButtonModule } from '@app/shared/components/radio-button/radio-button.module';
import { DialogNewInjuryComponent } from '@app/shared/components/dialog-new-injury/dialog-new-injury.component';
import { DateTimePickerModule } from '@app/shared/components/date-time-picker/date-time-picker.module';
import { StepDriverInfoComponent } from './step-driver-info/step-driver-info.component';
import { StepTimePlaceComponent } from './step-time-place/step-time-place.component';
import { StepInjuryDetailComponent } from './step-injury-detail/step-injury-detail.component';
import { StepInjuryDescriptionComponent } from './step-injury-description/step-injury-description.component';
import { StepAdditionalInfoComponent } from './step-additional-info/step-additional-info.component';
import { StepLeaveComponent } from './step-leave/step-leave.component';

@NgModule({
  declarations: [
    DialogNewInjuryComponent,
    StepDriverInfoComponent,
    StepTimePlaceComponent,
    StepInjuryDetailComponent,
    StepInjuryDescriptionComponent,
    StepAdditionalInfoComponent,
    StepLeaveComponent,
  ],
  exports: [DialogNewInjuryComponent],
  imports: [
    CommonModule,
    MatStepperModule,
    InputModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    TimePickerModule,
    MatCheckboxModule,
    CheckboxModule,
    RadioButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDialogModule,
    DateTimePickerModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
})
export class DialogNewInjuryModule {}
