<div
  (click)="onShowOptionsClick()"
  [ngClass]="{ 'custom-select': true, 'pointer-cursor': !disabled }"
  [ngStyle]="styles"
>
  <label class="custom-select-label">{{ label }}</label>
  <div class="selected-option">
    <div>{{ selectedOption || "" }}</div>
    <i
      class="fa-solid fa-chevron-down"
      [class.rotate]="showOptions"
      (click)="onShowOptionsClick()"
    ></i>
  </div>
  <div
    class="options"
    [ngClass]="{ 'show-options': showOptions }"
    *ngIf="!disabled"
  >
    <div
      class="option"
      *ngFor="let option of options"
      (click)="onSelectOptionClick($event, option?.value)"
    >
      {{ option?.label }}
    </div>
  </div>
</div>
