<div class="article-actions">
  <app-date-range-picker
    positionOffset="right"
    [rounded]="true"
    [startDate]="dateRange.controls.start.value"
    [endDate]="dateRange.controls.end.value"
    (dateRangeSelected)="onDateRangeSelected($event)"
  >
  </app-date-range-picker>
</div>

<article #scrollContainer>
  <!-- Accidents Section -->
  <section class="accidents-block" [attr.id]="'accidents'" *ngIf="canViewAccidents()">
    <app-accidents-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-accidents-list>
  </section>

  <!-- Injury Section -->
  <section class="injuries-block" [attr.id]="'injuries'" *ngIf="canViewInjuries()">
    <app-injury-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-injury-list>
  </section>

  <!-- Telematics Section -->
  <section [attr.id]="'telematics'">
    <app-telematics-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-telematics-list>
  </section>

  <!-- Hours of Service Section -->
  <section [attr.id]="'hours-of-service'">
    <app-hours-service-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-hours-service-list>
  </section>

  <!-- Inspection Section -->
  <section [attr.id]="'inspections'">
    <app-inspection-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-inspection-list>
  </section>

  <!-- Speeding Events Section -->
  <section [attr.id]="'speeding-events'">
    <app-speed-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-speed-list>
  </section>

  <!-- Percentage Speeding Section -->
  <section [attr.id]="'percentage-speeding'">
    <app-speeding-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-speeding-list>
  </section>

  <!-- Seatbelt Section -->
  <section [attr.id]="'seatbelt'">
    <app-seatbelt-list
      [dateRange]="dateRange"
      [driverId]="rowData.driverId"
      [maxDisplayedRecords]="maxDisplayedRecords"
      [defaultPageSize]="defaultPageSize"
      (rowClicked)="onRowClick($event.row, $event.sectionName)"
    ></app-seatbelt-list>
  </section>
</article>

<ul *ngIf="sections.length > 1">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li
      (click)="onNavClick(item.id)"
      [class.active]="item.id === sectionSelected"
    >
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
