import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step-flares-hazmat',
  templateUrl: './step-flares-hazmat.component.html',
  styleUrls: ['./step-flares-hazmat.component.scss'],
})
export class StepFlaresHazmatComponent {
  @Input() flaresHazmatForm!: FormGroup;
}
