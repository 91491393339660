<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <div mat-dialog-title>HOS</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="content">
    <div class="row">
      <div class="row-item">
        <div class="title">Activity Details</div>
        <hr />
        <div *ngIf="isLoading" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
        <table
          *ngIf="!isLoading"
          mat-table
          [dataSource]="activityDetails"
          class="hos-table"
        >
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
        <hr />
      </div>

      <div class="row-item">
        <div class="title">HOS Summary</div>
        <hr />
        <div *ngIf="isLoading" class="spinner-container">
          <mat-progress-spinner
            mode="indeterminate"
            [diameter]="30"
            class="spinner"
          ></mat-progress-spinner>
        </div>
        <table
          *ngIf="!isLoading"
          mat-table
          [dataSource]="hosSummary"
          class="hos-table"
        >
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
        <hr />
      </div>
    </div>

    <div class="full-width-row">
      <div class="violation-title">Violations</div>
      <div *ngIf="isLoading" class="spinner-container">
        <mat-progress-spinner
          mode="indeterminate"
          [diameter]="30"
          class="spinner"
        ></mat-progress-spinner>
      </div>
      <div
        *ngIf="!isLoading && violations.length === 0"
        class="no-records-found"
      >
        <p>No violations found.</p>
      </div>
      <table
        *ngIf="!isLoading && violations.length"
        mat-table
        [dataSource]="violations"
        class="hos-table"
      >
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{ element?.label }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{ element?.value }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
