<mat-dialog-actions>
  <div class="dialog-close">
    <i class="fas fa-times" (click)="onDialogCloseClick()"></i>
  </div>
  <div mat-dialog-title>New Injury</div>
</mat-dialog-actions>

<mat-dialog-content>
  <div class="info">
    For safety assistance, please call Laura Hall: 443-997-0541 or Gene Funk:
    410-303-2355
    <br />If you need after hours substance testing, please call 866-457-4009
    Account #101 432 77
  </div>

  <mat-stepper #stepper (selectionChange)="onStepChange($event)">
    <ng-template matStepperIcon="edit">
      <mat-icon>check</mat-icon>
    </ng-template>

    <!-- Driver Info Step -->
    <mat-step [stepControl]="driverInfoFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Driver Info</ng-template>
        <app-step-driver-info
          [driverInfoForm]="driverInfoFormGroup"
        ></app-step-driver-info>
      </form>
    </mat-step>

    <!-- Time & Place Step -->
    <mat-step [stepControl]="timePlaceFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Time & Place</ng-template>
        <app-step-time-place
          [timePlaceForm]="timePlaceFormGroup"
        ></app-step-time-place>
      </form>
    </mat-step>

    <!-- Injury Detail Step -->
    <mat-step [stepControl]="injuryDetailFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Injury Detail</ng-template>
        <app-step-injury-detail
          [injuryDetailForm]="injuryDetailFormGroup"
        ></app-step-injury-detail>
      </form>
    </mat-step>

    <!-- Injury Description Step -->
    <mat-step [stepControl]="injuryDescriptionFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Injury Description</ng-template>
        <app-step-injury-description
          [injuryDescriptionForm]="injuryDescriptionFormGroup"
        ></app-step-injury-description>
      </form>
    </mat-step>

    <!-- Additional Info Step -->
    <mat-step [stepControl]="additionalInfoFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Additional Info</ng-template>
        <app-step-additional-info
          [additionalInfoForm]="additionalInfoFormGroup"
        ></app-step-additional-info>
      </form>
    </mat-step>

    <!-- Leave Step -->
    <mat-step [stepControl]="leaveFormGroup">
      <form [formGroup]="injuryForm">
        <ng-template matStepLabel>Leave</ng-template>
        <app-step-leave [leaveForm]="leaveFormGroup"></app-step-leave>
      </form>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions class="action-buttons">
  <button
    mat-flat-button
    color="primary"
    (click)="onDoneClick()"
    *ngIf="step === maxStep - 1"
    [disabled]="injuryForm.invalid"
  >
    Done
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="nextStep()"
    *ngIf="step < maxStep - 1"
    [disabled]="!driverNameValue"
  >
    Next
  </button>
  <button
    mat-flat-button
    color="primary"
    (click)="onSaveClick()"
    *ngIf="step < maxStep - 1 && step > 0"
    [disabled]="!driverNameValue || !dateReport || !dateInjury"
  >
    Save and Finish Later
  </button>
  <button
    mat-stroked-button
    color="primary"
    *ngIf="step !== 0 && step !== maxStep - 1"
    (click)="prevStep()"
  >
    Back
  </button>
  <button
    mat-stroked-button
    color="warn"
    (click)="onDialogCloseClick()"
    *ngIf="step === 0"
  >
    Cancel
  </button>
  <button
    mat-stroked-button
    color="warn"
    (click)="reset()"
    *ngIf="step === maxStep - 1"
  >
    Reset
  </button>
</mat-dialog-actions>
