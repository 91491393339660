import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { MediaService } from '@app/services/media.service';
import { ToasterService } from '@app/services/toaster.service';
import { UserManagementService } from '@app/services/user-management.service';
import { DialogConfirmComponent } from '@app/shared/components/dialog-confirm/dialog-confirm.component';
import { getBackgroundClass, getInitials } from '@app/shared/helpers/functions.helper';
import { environment } from '@env/environment';

@Component({
  selector: 'app-dialog-profile',
  templateUrl: './dialog-profile.component.html',
  styleUrls: ['./dialog-profile.component.scss'],
})
export class DialogProfileComponent implements OnInit {
  previewUrl: string | ArrayBuffer | null = null;
  fullName = '';
  selectedFile!: File;
  userForm!: FormGroup;
  userInfo: any;
  userData: any;
  successMessageShown: boolean = false;
  isLoading: boolean = false;
  imageUpdated: boolean = false;
  selectedOrganization: any = {};
  groups: any[] = [];

  getBackgroundClass = getBackgroundClass;
  getInitials = getInitials;

  constructor(
    private toasterService: ToasterService,
    private fb: FormBuilder,
    private userManagementService: UserManagementService,
    private dialogRef: MatDialogRef<DialogProfileComponent>,
    private mediaService: MediaService,
    private dialog: MatDialog
  ) {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, this.emailValidator]],
    });
  }

  ngOnInit(): void {
    this.loadSelectedOrganization();
    this.getUserData();
    this.getUserImage();
    this.getGroups();
  }

  loadSelectedOrganization(): void {
    const storedOrganization = localStorage.getItem('selectedOrganization');
    if (storedOrganization) {
      this.selectedOrganization = JSON.parse(storedOrganization);
    }
  }

  getGroups(): void {
    const organizationId = this.selectedOrganization.organizationId;
    this.userManagementService.getGroups(organizationId).subscribe({
      next: (res) => {
        this.groups = res.groups.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });
        const group = this.groups.find(
          (g) => g.name === this.selectedOrganization.userGroups[0].groupName
        );
        if (group) {
          this.userForm.get('role')?.setValue(group.groupId);
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getUserData(): void {
    const storedData = localStorage.getItem('userData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      this.userData = parsedData;
      this.userInfo = parsedData.userInfo;
      this.fullName = this.userInfo.firstName + ' ' + this.userInfo.lastName;
      this.userForm.setValue({
        firstName: this.userInfo.firstName,
        lastName: this.userInfo.lastName,
        email: this.userInfo.email,
      });
    }
  }

  getUserImage(): void {
    this.isLoading = true;
    this.mediaService.downloadFile('userProfileImage', this.userInfo.objectId).subscribe({
      next: (res) => {
        this.previewUrl = URL.createObjectURL(res);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.status !== 404) {
          console.log(error);
        }
      },
    });
  }

  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailRegex = environment.emailPattern;
    if (!control.value.match(emailRegex)) {
      return { invalidEmail: true };
    }
    return null;
  }

  onSaveChanges() {
    if (this.userForm.pristine) {
      this.saveUserImage(true);
      return;
    }
    const request: any = {
      objectId: this.userInfo.objectId,
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      principalName: this.userInfo.principalName,
      lastModifiedBy: this.userInfo.objectId,
      groupId: this.userForm.get('role')?.value,
      organizationId: this.selectedOrganization.organizationId,
    };
    this.saveUserImage(false);
    this.userManagementService.upsertUser(request).subscribe({
      next: () => this.hasSuccess(),
      error: (error) => {
        console.log(error);
      },
    });
  }

  saveUserImage(onlyImage: boolean): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append(`file`, this.selectedFile, this.selectedFile.name);
      this.mediaService
        .upload(formData, 'userProfileImage', this.userInfo.objectId)
        .subscribe({
          next: () => {
            if (onlyImage && !this.successMessageShown) {
              this.toasterService.showSuccess('Changes saved successfully');
              this.successMessageShown = true;
            }
            this.hasSuccess();
          },
          error: (error) => {
            console.log(error);
          },
        });
    }
  }

  onDeleteImage(): void {
    const data = {
      title: 'Delete Profile Image',
      text: 'Are you sure you want to delete the image?',
      yesButtonText: 'Delete',
      noButtonText: 'Cancel',
    };
    this.dialog
      .open(DialogConfirmComponent, { data, width: '500px' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.mediaService
            .deleteFile(this.userInfo.objectId, 'userProfileImage')
            .subscribe({
              next: () => {
                this.previewUrl = null;
                this.hasSuccess();
              },
              error: (error) => {
                console.log(error);
              },
            });
        }
      });
  }

  hasSuccess(): void {
    if (!this.successMessageShown) {
      this.toasterService.showSuccess('Changes saved successfully');
      this.successMessageShown = true;
    }
    this.saveDataOnStore();
  }

  saveDataOnStore(): void {
    const accountProfileData = {
      objectId: this.userInfo.objectId,
      firstName: this.userForm.get('firstName')?.value,
      lastName: this.userForm.get('lastName')?.value,
      email: this.userForm.get('email')?.value,
      principalName: this.userInfo.principalName,
      userType: this.userInfo.userType,
      lastModifiedBy: this.userInfo.objectId,
      previewUrl: this.previewUrl,
    };
    localStorage.setItem(
      'userData',
      JSON.stringify({ ...this.userData, userInfo: accountProfileData })
    );
    this.dialogRef.close(true);
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer!.files;
    if (files.length > 0) {
      const file = files[0];
      this.handleFile(file);
    }
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
  }

  handleFile(file: File): void {
    this.imageUpdated = true;
    this.selectedFile = file;
    // File type validation
    if (!['image/jpeg', 'image/gif', 'image/png', 'image/svg+xml'].includes(file.type)) {
      this.toasterService.showError('File type not allowed');
      return;
    }

    // Image size validation
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 100 || img.height < 100 || img.width !== img.height) {
        this.toasterService.showError('The dimensions of the image are not valid');
        return;
      }

      // image read and preview
      const reader = new FileReader();
      reader.onload = () => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(file);
    };
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files![0];
    if (file) {
      this.handleFile(file);
    }
  }
}
