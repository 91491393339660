import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DriverService } from '@app/services/driver.service';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-step-driver-info',
  templateUrl: './step-driver-info.component.html',
  styleUrls: ['./step-driver-info.component.scss'],
})
export class StepDriverInfoComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() driverInfoForm!: FormGroup;

  driverData!: any;
  driverIdSubscription?: Subscription;
  isLoading = false;

  @ViewChild('driverIdInput') driverIdInput!: ElementRef;

  constructor(
    private driverService: DriverService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const driverIdControl = this.driverInfoForm.get('driverId');
    if (driverIdControl) {
      this.driverIdSubscription = driverIdControl.valueChanges
        .pipe(debounceTime(700), distinctUntilChanged())
        .subscribe((driverId) => {
          if (driverId) {
            const driverIdUpper = driverId.toUpperCase();
            // prettier-ignore
            this.driverInfoForm.get('driverId')?.setValue(driverIdUpper, { emitEvent: false });
            this.searchDriver(driverIdUpper);
          }
        });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.driverIdInput.nativeElement.focus());
  }

  searchDriver(driverId: string): void {
    this.isLoading = true;
    this.driverService.getDriverProfile(driverId).subscribe({
      next: (res: any) => {
        if (res.length) {
          this.driverData = res[0];
          this.driverInfoForm.get('cellphone')?.setValue(this.driverData.phone);
          this.driverInfoForm
            .get('driverName')
            ?.setValue(this.driverData?.driverName);
          this.driverInfoForm
            .get('organization')
            ?.setValue(this.driverData?.organization);
          this.driverInfoForm
            .get('terminal')
            ?.setValue(this.driverData?.terminal);
          this.driverInfoForm
            .get('fleetManager')
            ?.setValue(this.driverData?.Managers[1].managerName);
          this.driverInfoForm
            .get('safetyManager')
            ?.setValue(this.driverData?.Managers[0].managerName);
        } else {
          this.snackBar.open('Driver not found', 'Close', {
            duration: 3000,
          });
          this.driverInfoForm.get('cellphone')?.reset();
          this.driverInfoForm.get('driverName')?.reset();
          this.driverData = undefined;
        }
        this.isLoading = false;
      },
      error: (error) => {
        console.log(error);
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this.driverIdSubscription?.unsubscribe();
  }
}
