import { Manager } from './manager';

export interface Driver {
  avatarUrl?: string;
  driverName: string;
  driverId: string;
  terminal: string;
  managers: Manager[];
  lastDispatchDate?: string;
  nbrOfInterventions: string;
  sameForInterventions: string;
  score: number;
  safetyTrainer: string;
}

export interface DateRange {
  start: Date;
  end: Date;
}
