<article>
  <section *ngIf="canViewEvents()" [attr.id]="'events'">
    <app-section-history-events
      [driverId]="driverId"
    ></app-section-history-events>
  </section>
  <section *ngIf="canViewInterventions()" [attr.id]="'coaching'">
    <app-section-history-coaching
      [driverId]="driverId"
    ></app-section-history-coaching>
  </section>
</article>

<ul *ngIf="sections.length > 1 && showMenu">
  <ng-container *ngFor="let item of sections; first as isFirst">
    <li
      (click)="onNavClick(item.id)"
      [class.active]="item.id === sectionSelected"
    >
      <span>{{ item?.title }}</span>
      <i class="indicator"></i>
    </li>
  </ng-container>
</ul>
