<mat-dialog-actions>
  <div class="dialog-close">
    <i mat-dialog-close class="fas fa-times"></i>
  </div>
  <h2>My Profile</h2>
</mat-dialog-actions>

<mat-dialog-content class="profile-container">
  <div class="file-upload-container">
    <div class="preview-and-delete-container">
      <!-- Image Preview -->
      <div class="preview-container">
        <ng-container *ngIf="isLoading">
          <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
        </ng-container>
        <img
          *ngIf="previewUrl && !isLoading"
          [src]="previewUrl"
          alt="Image preview"
          class="image-preview"
        />
        <div
          *ngIf="!previewUrl && !isLoading"
          class="initials"
          [ngClass]="getBackgroundClass(fullName)"
        >
          {{ getInitials(fullName) }}
        </div>
      </div>
      <!-- Delete Button -->
      <button
        *ngIf="previewUrl"
        mat-icon-button
        class="text-icon-align delete-button"
        (click)="onDeleteImage()"
      >
        <i class="fa-regular fa-trash-can delete-button menu-icon"></i>
        <span class="delete-text">Delete Photo</span>
      </button>
    </div>

    <!-- Drag & Drop -->
    <div
      class="drag-drop-container"
      (click)="fileInput.click()"
      (drop)="onDrop($event)"
      (dragover)="onDragOver($event)"
    >
      <div class="icon-text-container">
        <input
          type="file"
          #fileInput
          (change)="onFileSelected($event)"
          style="display: none"
          accept="image/*"
        />
        <i class="fa-solid fa-cloud-arrow-up"></i>
        <div class="drag-drop-text-container">
          <p class="drag-drop-main-text">Drag & Drop Image</p>
          <small class="drag-drop-secondary-text">or Select a File...</small>
        </div>
      </div>
    </div>
  </div>

  <form [formGroup]="userForm" class="user-form">
    <div class="input-row">
      <!-- First Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" type="text" />
        <mat-error *ngIf="userForm.get('firstName')?.hasError('required')"
          >The First Name is required</mat-error
        >
      </mat-form-field>
      <!-- Last Name -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" type="text" />
        <mat-error *ngIf="userForm.get('lastName')?.hasError('required')"
          >The Last Name is required</mat-error
        >
      </mat-form-field>
    </div>
    <div class="input-row">
      <!-- Email -->
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="text" />
        <mat-error *ngIf="userForm.get('email')?.hasError('required')"
          >The Email is required</mat-error
        >
        <mat-error
          *ngIf="
            userForm.get('email')?.hasError('invalidEmail') &&
            !userForm.get('email')?.hasError('required') &&
            userForm.get('email')?.touched
          "
        >
          Please enter a valid email
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <ng-template #loadingTemplate>
    <div class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        [diameter]="30"
        class="spinner"
      ></mat-progress-spinner>
    </div>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions class="end">
  <button
    mat-flat-button
    color="primary"
    (click)="onSaveChanges()"
    [disabled]="userForm.pristine && !imageUpdated"
  >
    <i class="far fa-circle-check"></i>
    <span>Save Changes</span>
  </button>
</mat-dialog-actions>
