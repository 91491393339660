<div *ngIf="accidentData">
  <div class="two-column-grid">
    <div *ngFor="let field of getFormattedData()" [class.full-row]="field.fullRow">
      <!-- Display section title (h2) -->
      <ng-container *ngIf="field.section">
        <div class="section-title full-column">
          <h2>{{ field.label }}</h2>
        </div>
      </ng-container>

      <!-- Display sub-subheadings like Injuries, Damages, etc., as h3 -->
      <ng-container *ngIf="field.isSubSubheading">
        <div class="full-column">
          <h3>{{ field.label }}</h3>
        </div>
      </ng-container>

      <!-- Display subheadings for action items as h4, without value, taking full row -->
      <ng-container *ngIf="field.isSubheading && field.fullRow">
        <div class="full-column">
          <span>{{ field.label }}</span>
        </div>
      </ng-container>

      <!-- Display other fields in left column -->
      <ng-container
        *ngIf="
          !field.section &&
          !field.isSubheading &&
          !field.isSubSubheading &&
          field.column === 'left'
        "
      >
        <div class="left-column">
          <strong>{{ field.label }}:</strong> {{ field.value }}
        </div>
      </ng-container>

      <!-- Display other fields in right column -->
      <ng-container
        *ngIf="
          !field.section &&
          !field.isSubheading &&
          !field.isSubSubheading &&
          field.column === 'right'
        "
      >
        <div class="right-column">
          <strong>{{ field.label }}:</strong> {{ field.value }}
        </div>
      </ng-container>

      <!-- Display fields that take up full row -->
      <ng-container
        *ngIf="
          field.fullRow && !field.isSubheading && !field.isSubSubheading && !field.section
        "
      >
        <div class="full-column">
          <strong>{{ field.label }}:</strong> {{ field.value }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
