import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TableData } from '@app/drivers/dialog-driver/dialog-driver.constants';

@Component({
  selector: 'app-dialog-file-upload',
  templateUrl: './dialog-file-upload.component.html',
  styleUrls: ['./dialog-file-upload.component.scss'],
})
export class DialogFileUploadComponent {
  protected readonly tableData = TableData;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { title: string }) {}
}
