export enum StatusType {
  OPEN = 'Open',
  ASSIGNED = 'Assigned',
  CLOSED = 'Closed',
  NON_EVENT = 'Non-Event',
}

export enum AssigneeType {
  UNASSIGNED = 'Unassigned',
}

export enum CoachingStatusType {
  OPEN = 'Open',
  SCHEDULED = 'Scheduled',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed',
}

export enum SeverityType {
  CRITICAL = 'Critical',
  SIGNIFICANT = 'Significant',
  MODERATE = 'Moderate',
  MINOR = 'Minor',
}
