import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink, RouterLinkActive } from '@angular/router';

// Material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { DateTimePickerModule } from '@app/shared/components/date-time-picker/date-time-picker.module';
import { SidenavListComponent } from './sidenav-list/sidenav-list.component';
import { HeaderComponent } from './header/header.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { DialogSettingsComponent } from './sub-header/dialog-settings/dialog-settings.component';
import { DialogNewAccidentModule } from '@app/shared/components/dialog-new-accident/dialog-new-accident.module';
import { DialogNewInjuryModule } from '@app/shared/components/dialog-new-injury/dialog-new-injury.module';
import { DialogProfileComponent } from './sub-header/dialog-profile/dialog-profile.component';

@NgModule({
  declarations: [
    SidenavListComponent,
    HeaderComponent,
    SubHeaderComponent,
    DialogSettingsComponent,
    DialogProfileComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterLink,
    NgOptimizedImage,
    RouterLinkActive,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatBadgeModule,
    MatDialogModule,
    MatSlideToggleModule,
    DateTimePickerModule,
    DialogNewAccidentModule,
    DialogNewInjuryModule,
    MatTooltipModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
  ],
  exports: [HeaderComponent, SidenavListComponent, SubHeaderComponent],
})
export class LayoutModule {}
