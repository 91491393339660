import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class InterventionService {
  private baseUrl = environment.apiUrl;

  private selectionChangeSource = new Subject<string>();

  selectionChanged$ = this.selectionChangeSource.asObservable();

  constructor(private http: HttpClient) {}

  announceSelectionChange(status: string) {
    this.selectionChangeSource.next(status);
  }

  getInterventionsList(params: any): Observable<any> {
    const paramMappings: any = {
      pageSize: 'pageSize',
      pageNumber: 'pageNumber',
      startDate: 'startDueDate',
      endDate: 'endDueDate',
      priority: 'priority',
      status: 'status',
      eventType: 'eventType',
      terminal: 'terminal',
      assignee: 'assignee',
      ticketId: 'ticketId',
      driverId: 'driverId',
      sortField: 'sortField',
      sortValue: 'sortValue',
    };
    const url = `${this.baseUrl}/intervention?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getIntervention(interventionId: string): Observable<any> {
    const url = `${this.baseUrl}/intervention/${interventionId}`;
    return this.http.get<any>(url);
  }

  getCoachingSamples(params: any): Observable<any> {
    const paramMappings: any = {
      pageSize: 'pageSize',
      pageNumber: 'pageNumber',
      eventTypes: 'eventTypes',
      sampleTypes: 'sampleTypes',
    };
    const url = `${this.baseUrl}/coaching/samples?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  upsertCoachingSamples(request: any): Observable<any> {
    return this.http.post(`${this.baseUrl}/coaching/samples`, request);
  }

  getInterventionsSearch(params: any): Observable<any> {
    const paramMappings: any = {
      pageSize: 'pageSize',
      pageNumber: 'pageNumber',
      searchField: 'searchField',
      driverId: 'driverId',
    };
    const url = `${this.baseUrl}/intervention/search?${this.getQueryParams(
      paramMappings,
      params
    )}`;
    return this.http.get<any>(url);
  }

  getQueryParams(paramMappings: any, request: any): any {
    return Object.keys(paramMappings)
      .filter((key) => request[key] || request[key] === 0)
      .map((key) => `${paramMappings[key]}=${request[key]}`)
      .join('&');
  }

  upsertIntervention(request: any): Observable<any> {
    if (request && request.id) {
      return this.http.put(`${this.baseUrl}/intervention`, request);
    } else {
      return this.http.post(`${this.baseUrl}/intervention`, request);
    }
  }

  relationshipEventIntervention(request: any): Observable<any> {
    if (request && request.Id) {
      return this.http.put(`${this.baseUrl}/event/intervention`, request);
    } else {
      return this.http.post(`${this.baseUrl}/event/intervention`, request);
    }
  }

  getEventInterventions(eventId: string): Observable<any> {
    const url = `${this.baseUrl}/intervention/event?eventId=${eventId}`;
    return this.http.get<any>(url);
  }
}
