<div class="title-section-container">
  <form [formGroup]="interventionForm">
    <div class="inputs-container">
      <!-- Title -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>ID #</mat-label>
        <input matInput formControlName="key" />
      </mat-form-field>
      <!-- Reason -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>Reason</mat-label>
        <input matInput formControlName="reason" />
      </mat-form-field>
      <!-- Driver -->
      <mat-form-field class="input-icon input-separated" appearance="outline">
        <mat-label>Driver</mat-label>
        <input matInput formControlName="driver" [matAutocomplete]="auto" />
        <mat-icon class="suffix" matSuffix>
          <i class="fa-solid fa-magnifying-glass"></i>
        </mat-icon>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelectDriver($event.option.value)"
        >
          <mat-option
            *ngFor="let driver of filteredDrivers | async"
            [value]="driver"
          >
            <div class="driver-option-content">
              <span>{{ driver.driverName | capitalize }}</span>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <!-- Driver Info -->
      <div class="driver-info">
        <button mat-button (click)="goToDriverProfile()">
          <i class="fas fa-id-card"></i>
          <span>{{ driverSelected?.driverId }}</span>
        </button>

        <button mat-button (click)="goToTerminal(data?.terminal)">
          <i class="fas fa-map-marker-alt"></i>
          <span>{{ driverSelected?.terminal }}</span>
        </button>
      </div>
    </div>
  </form>
  <div class="buttons-container">
    <!-- Save Button  -->
    <button
      class="save-button"
      mat-stroked-button
      color="green-light"
      (click)="save()"
    >
      <i class="fa-solid fa-circle-check"></i>Save
    </button>
    <!-- Cancel Button  -->
    <button mat-stroked-button color="warn" (click)="cancel()">
      <i class="fa-solid fa-circle-xmark"></i>Cancel
    </button>
  </div>
</div>

<div class="custom-columns">
  <!-- Assigned Coach -->
  <div class="column assigned-couch">
    <app-edit-assigned-coach
      [assignee]="data?.assignee"
      [assigneeName]="data?.assigneeName"
      [intervention]="data"
      (userIdSelected)="updateAssignedCoach($event)"
    ></app-edit-assigned-coach>
  </div>
  <!-- Due Date -->
  <div class="column clickable" (click)="openDatePicker()">
    <span class="column-span"
      ><i class="fa-solid fa-calendar-days calendar"></i
      >{{ dueDate | date : "shortDate" }}</span
    >
    <label class="column-label">Due Date</label>

    <input
      [ngxMatDatetimePicker]="picker"
      [(ngModel)]="dueDate"
      (ngModelChange)="updateDueDate($event)"
      [style.visibility]="'hidden'"
      [style.position]="'absolute'"
    />
    <ngx-mat-datetime-picker
      #picker
      [showSpinners]="true"
      [showSeconds]="false"
      [stepHour]="1"
      [hideTime]="true"
      [stepMinute]="1"
      [touchUi]="false"
      [enableMeridian]="true"
    >
      <ngx-mat-datepicker-actions>
        <button mat-raised-button ngxMatDatepickerCancel>
          <i class="fas fa-times"></i>
          <span class="button-title">Cancel</span>
        </button>
        <button mat-flat-button color="primary" ngxMatDatepickerApply>
          <i class="fas fa-check"></i>
          <span class="button-title">Apply</span>
        </button>
      </ngx-mat-datepicker-actions>
    </ngx-mat-datetime-picker>
  </div>
  <!-- Priority -->
  <div class="column clickable priority-select-container">
    <mat-select
      #prioritySelect
      [(ngModel)]="priority"
      class="priority-select"
      (selectionChange)="onPrioritySelectionChange()"
    >
      <mat-select-trigger>
        <span class="column-span column-span-priority">
          <div class="select-trigger-content">
            <div class="safety-indicator {{ priority.toLowerCase() }}">
              <i class="mark"></i>
              <i class="mark"></i>
              <i class="mark"></i>
            </div>
          </div>
          {{ priority }}
        </span>
        <label class="column-label">Priority</label>
      </mat-select-trigger>
      <mat-option *ngFor="let priority of priorities" [value]="priority.value">
        <div class="select-trigger-content">
          <div class="safety-indicator {{ priority.label.toLowerCase() }}">
            <i class="mark mark-first"></i>
            <i class="mark"></i>
            <i class="mark"></i>
          </div>
          {{ priority.label }}
        </div>
      </mat-option>
    </mat-select>
  </div>
  <!-- Status -->
  <div
    class="column special-border"
    [ngClass]="{
      'black-border': currentStatus.toLowerCase() === 'open',
      'orange-border': currentStatus.toLowerCase() === 'scheduled',
      'blue-border': currentStatus.toLowerCase() === 'in progress',
      'green-border': currentStatus.toLowerCase() === 'completed'
    }"
  >
    <app-status-coaching-select
      [intervention]="data"
      [status]="data.status"
      (selectionChanged)="handleStatusSelectionChange($event)"
      [disabled]="!canEditInterventions()"
    ></app-status-coaching-select>
  </div>
</div>
