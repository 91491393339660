import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-dialog-settings',
  templateUrl: './dialog-settings.component.html',
  styleUrls: ['./dialog-settings.component.scss'],
})
export class DialogSettingsComponent implements OnInit {
  @ViewChild('theme') theme!: MatSlideToggle;
  @ViewChild('content') content!: MatSlideToggle;
  isDarkTheme = false;
  isComfyContent = false;

  private readonly storageKeys = {
    theme: '[Safety] Dark Theme',
    content: '[Safety] Content Density',
  };

  constructor(private dialogRef: MatDialogRef<DialogSettingsComponent>) {}

  ngOnInit(): void {
    this.isDarkTheme = !!localStorage.getItem(this.storageKeys.theme) || false;
    this.isComfyContent = !!localStorage.getItem(this.storageKeys.content) || false;
  }

  toggleSlide(element: string, event: Event): void {
    event.preventDefault();
    switch (element) {
      case 'content':
        this.isComfyContent = this.content.checked;
        break;
      case 'theme':
        this.isDarkTheme = this.theme.checked;
        break;
    }
  }

  onUpdateClick(): void {
    this.isComfyContent
      ? localStorage.setItem(this.storageKeys.content, 'true')
      : localStorage.removeItem(this.storageKeys.content);
    this.isDarkTheme
      ? localStorage.setItem(this.storageKeys.theme, 'true')
      : localStorage.removeItem(this.storageKeys.theme);
    this.setBodyClass(this.theme.checked ? 'dark' : 'light');
    this.dialogRef.close();
  }

  setBodyClass(theme: string): void {
    document.body.classList.remove('light', 'dark');
    document.body.classList.add(theme);
  }
}
