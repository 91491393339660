<mat-form-field [ngClass]="{ 'background-white': backgroundWhite }">
  <label class="custom-label">
    {{ label }}
    <span class="star" *ngIf="required">*</span>
  </label>
  <input class="custom-input" [value]="value" [disabled]="true" matInput />
  <input
    hidden
    (dateChange)="onDateChange($event)"
    matInput
    [ngxMatDatetimePicker]="picker"
    [disabled]="disabled"
  />
  <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker
    #picker
    [disabled]="disabled"
    [showSpinners]="true"
    [showSeconds]="false"
    [stepHour]="1"
    [hideTime]="hideTime"
    [stepMinute]="1"
    [touchUi]="false"
    [enableMeridian]="true"
    class="hola"
  >
    <ngx-mat-datepicker-actions>
      <button mat-raised-button ngxMatDatepickerCancel [disabled]="disabled">
        <i class="fas fa-times"></i>
        <span class="button-title">Cancel</span>
      </button>
      <button mat-flat-button color="primary" ngxMatDatepickerApply [disabled]="disabled">
        <i class="fas fa-check"></i>
        <span class="button-title">Save</span>
      </button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</mat-form-field>
