import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ExpandViewService } from '@app/services/expand-view.service';

@Component({
  selector: 'app-activity-speeding',
  templateUrl: './activity-speeding.component.html',
  styleUrls: ['../../tab-sections.component.scss'],
})
export class ActivitySpeedingComponent implements OnInit, OnDestroy {
  @Input() eventActivities!: any;
  @Input() maxDisplayedRecords!: number;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();

  columns: string[] = [
    'date',
    'milesDriven',
    'evaluationPeriod',
    'speedingPeriod',
    'speeding',
  ];
  showViewMore: boolean = false;
  isViewMore: boolean = true;
  pageSize!: number;
  dataFiltered: any[] = [];
  activities!: any;
  sectionName!: string;

  subscription!: Subscription;

  constructor(private expandViewService: ExpandViewService) {}

  ngOnInit(): void {
    this.setData();
    this.setShowViewMore();
    this.pageSize = this.maxDisplayedRecords;
    this.setDataFiltered();

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.loadMore(true);
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  setData(): void {
    if (this.eventActivities?.speedPercentageActivities) {
      this.columns = [
        'date',
        'milesDriven',
        'evaluationPeriod',
        'speedingPeriod',
        'speeding',
      ];
      this.activities =
        this.eventActivities?.speedPercentageActivities[0].speedPercentageSummaries;
      this.sectionName = 'percentage-speeding';
    } else {
      this.columns = ['date', 'time', 'posted', 'actual', 'difference', 'category'];
      this.activities = this.eventActivities?.speedActivities[0].speedSummaries;
      this.sectionName = 'speeding-events';
    }
  }

  onRowClick(row: any): void {
    this.rowClicked.emit({ row, sectionName: this.sectionName });
  }

  setShowViewMore(): void {
    if (this.activities?.length > this.maxDisplayedRecords) {
      this.showViewMore = true;
    }
  }

  setDataFiltered(): void {
    if (this.pageSize === 0) {
      this.dataFiltered = [...this.activities];
    } else {
      this.dataFiltered = this.activities ? this.activities?.slice(0, this.pageSize) : [];
    }
  }

  loadMore(expandViewMore?: boolean): void {
    if (expandViewMore) {
      this.isViewMore = false;
    } else {
      this.isViewMore = !this.isViewMore;
    }
    this.pageSize = this.isViewMore ? this.maxDisplayedRecords : 0;
    this.setDataFiltered();
  }
}
