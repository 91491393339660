import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() disabled?: boolean;
  @Input() width?: string;
  @Input() height?: string;
  @Input() label?: string;
  @Input() margin?: string;
  @Input() options: { label: string; value: string | number | boolean }[] = [];
  @Input() selectedOption: string | number | boolean | null = null;
  showOptions: boolean = false;

  get styles(): { [key: string]: string } {
    const params: any = {};
    if (this.width?.length) {
      params.width = this.width;
    }
    if (this.height?.length) {
      params.height = this.height;
    }
    if (this.margin?.length) {
      params.margin = this.margin;
    }
    return params;
  }

  onShowOptionsClick(): void {
    if (!this.disabled) {
      this.showOptions = !this.showOptions;
    }
  }

  onSelectOptionClick(
    event: Event,
    value: string | number | boolean | null = null
  ): void {
    event?.preventDefault();
    event?.stopPropagation();
    this.selectedOption = value;
    this.showOptions = !this.showOptions;
  }
}
