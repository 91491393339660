import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-activity',
  templateUrl: './dialog-activity.component.html',
  styleUrls: ['./dialog-activity.component.scss'],
})
export class DialogActivityComponent {
  script: string = '';
}
