<mat-form-field appearance="outline" class="custom-input" floatLabel="always">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid #chipList>
    <mat-chip
      *ngFor="let selectedElement of selectedElements"
      (removed)="removeElement(selectedElement)"
      [disabled]="disabled"
    >
      <span class="inline-container">
        {{ selectedElement }}
      </span>
      <span *ngIf="!disabled" matChipRemove class="material-symbols-outlined icon-black">
        cancel
      </span>
    </mat-chip>

    <input
      type="text"
      matInput
      #elementInput
      [placeholder]="placeholder"
      [matChipInputFor]="chipList"
      (input)="onInput($event)"
      (blur)="onBlur()"
      [matAutocomplete]="autoComplete"
      [disabled]="disabled"
    />
    <span
      *ngIf="selectedElements.length && !disabled"
      class="icon-xmark"
      matSuffix
      (click)="resetSelectedElements()"
      [class.disabled]="disabled"
    >
      <i class="fa-solid fa-xmark"></i>
    </span>
    <span *ngIf="!selectedElements.length" class="icon-magnifying-glass" matSuffix>
      <i class="fa-solid fa-magnifying-glass"></i>
    </span>
  </mat-chip-grid>
  <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="displayFn">
    <mat-option
      *ngFor="let element of filteredElements | async"
      [value]="element"
      (onSelectionChange)="selectElement(element)"
      [disabled]="disabled"
    >
      {{ element }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
