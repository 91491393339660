import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FlyoutType } from '@app/models/fly-out.model';

@Injectable({
  providedIn: 'root',
})
export class FlyoutService {
  constructor(private dialog: MatDialog) {}

  getIndexOfFirstDialogOccurrence(uniqueIdentifier: string): number {
    const openDialogs = this.dialog.openDialogs;

    return openDialogs.findIndex(
      (dialogRef) =>
        dialogRef.componentInstance.data.uniqueIdentifier === uniqueIdentifier
    );
  }

  getIndexofCurrentDialog(uniqueIdentifier: string): number {
    const openDialogs = this.dialog.openDialogs;
    let index = -1;

    openDialogs.forEach((dialogRef, i) => {
      if (
        dialogRef.componentInstance.data.uniqueIdentifier === uniqueIdentifier
      ) {
        index = i;
      }
    });

    return index;
  }

  getFlyoutValueBasedOnOpenDialogs(): number {
    const openDialogsCount = this.dialog.openDialogs.length;

    switch (openDialogsCount) {
      case 0:
        return 850;
      case 1:
        return 810;
      case 2:
        return 770;
      default:
        return 730;
    }
  }

  getFlyoutOffsetBasedOnIndex(): number {
    const openDialogsCount = this.dialog.openDialogs.length;
    switch (openDialogsCount) {
      case 0:
        return -40;
      case 1:
        return 0;
      case 2:
        return 40;
      case 3:
        return 80;
      default:
        return 0;
    }
  }

  isDialogAlreadyOpen(uniqueIdentifier: any): boolean {
    const openDialogs = this.dialog.openDialogs;

    const count = openDialogs.reduce(
      (acc, dialogRef) =>
        acc +
        (dialogRef.componentInstance.data.uniqueIdentifier === uniqueIdentifier
          ? 1
          : 0),
      0
    );

    return count > 0;
  }

  isDialogOnTop(uniqueIdentifier: string): boolean {
    const openDialogs = this.dialog.openDialogs;
    const dialogIndex = this.getIndexofCurrentDialog(uniqueIdentifier);

    return dialogIndex === openDialogs.length - 1;
  }

  closeDialogsFromIndex(index: number): void {
    const openDialogs = this.dialog.openDialogs;

    if (index < 0 || index >= openDialogs.length) {
      return;
    }

    for (let i = index; i < openDialogs.length; i++) {
      const dialogRef: MatDialogRef<any> = openDialogs[i];
      dialogRef.close();
    }
  }

  handleDialogsOfType(type: FlyoutType): void {
    if (this.isDialogAlreadyOpen(type)) {
      const firstIndex = this.getIndexOfFirstDialogOccurrence(type);
      this.closeDialogsFromIndex(firstIndex);
    }
  }
}
