<table mat-table matSort [dataSource]="dataFiltered" class="table-header-sticky">
  <ng-container matColumnDef="summaryDate">
    <th mat-header-cell *matHeaderCellDef>Date</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.summaryDate | date : "M/d/yyyy" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="day">
    <th mat-header-cell *matHeaderCellDef>Day</th>
    <td mat-cell *matCellDef="let element">{{ element?.day }}</td>
  </ng-container>

  <ng-container matColumnDef="offDuty">
    <th mat-header-cell *matHeaderCellDef>Off Duty</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.offDuty !== "" ? convertToHourMinuteFormat(element?.offDuty) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="sleeper">
    <th mat-header-cell *matHeaderCellDef>Sleeper</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.sleeper !== "" ? convertToHourMinuteFormat(element?.sleeper) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="driving">
    <th mat-header-cell *matHeaderCellDef>Driving</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.driving !== "" ? convertToHourMinuteFormat(element?.driving) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="onDuty">
    <th mat-header-cell *matHeaderCellDef>On Duty</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.onDuty !== "" ? convertToHourMinuteFormat(element?.onDuty) : "" }}
    </td>
  </ng-container>

  <ng-container matColumnDef="personalConveyance">
    <th mat-header-cell *matHeaderCellDef>Personal Conveyance</th>
    <td mat-cell *matCellDef="let element">
      {{
        element?.personalConveyance !== ""
          ? convertToHourMinuteFormat(element?.personalConveyance)
          : ""
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="violations">
    <th mat-header-cell *matHeaderCellDef>Violation</th>
    <td mat-cell *matCellDef="let element">
      {{ element?.violations.length ? "Yes" : "No" }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: columns"
    (click)="onRowClick(row)"
    class="clickable"
  ></tr>
</table>

<div *ngIf="dataFiltered.length === 0" class="no-records-found">
  <p>No records found.</p>
</div>

<hr />

<div class="view-more" *ngIf="showViewMore" (click)="loadMore()">
  <span>{{ isViewMore ? "View More" : "View Less" }}</span>
  <mat-icon matIconSuffix>{{ isViewMore ? "expand_more" : "expand_less" }}</mat-icon>
</div>
