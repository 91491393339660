import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private _snackBar: MatSnackBar) {}

  showSuccess(message: string, duration: number = 4000): void {
    this._snackBar.open(message, 'Close', {
      duration,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: ['success-snackbar'],
    });
  }

  showError(message: string, duration: number = 4000): void {
    this._snackBar.open(message, 'Close', {
      duration,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      panelClass: ['error-snackbar'],
    });
  }
}
