<form [formGroup]="additionalInfoForm">
  <ng-template matStepLabel>Additional Info</ng-template>
  <div class="stepper-container w-full">
    <h3>Were you wearing winter cleats? *</h3>
    <mat-radio-group formControlName="winterCleats">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <h3>Was there a witness? *</h3>
    <mat-radio-group formControlName="witness">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <div class="stepper-container w-500" *ngIf="isAWitness()">
      <h3>Please provide the witness information</h3>
      <mat-form-field appearance="outline" class="textarea">
        <textarea matInput formControlName="witnessInformation"></textarea>
      </mat-form-field>
    </div>
  </div>
</form>
