import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ISpeedElement } from '@app/models/speed.model';
import { addSpacesToCamelCase } from '@app/shared/helpers/string.helper';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import {
  convertDateFormat,
  convertTo12HrFormat,
  getAMorPM,
} from '@app/shared/helpers/date.helper';

@Component({
  selector: 'app-dialog-driver-speed',
  templateUrl: './dialog-driver-speed.component.html',
  styleUrls: ['./dialog-driver-speed.component.scss'],
})
export class DialogDriverSpeedComponent implements OnInit {
  speedRowData!: ISpeedElement;
  speedDetail!: ISpeedElement;
  activityDetails!: { title: string; value: string | number }[];
  isLoading: boolean = true;
  srcMap!: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISpeedElement,
    private dialogInfoService: DialogDriverService,
    private sanitizer: DomSanitizer
  ) {
    this.speedRowData = data;
  }

  ngOnInit(): void {
    if (this.speedRowData) {
      const unsafeUrl = `${environment.googleMapStartUrl}${this.speedRowData.latitude},${this.speedRowData.longitude}&zoom=14&key=${environment.googleApiKey}`;

      this.srcMap = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
      this.dialogInfoService
        .getSpeedSummaryDetail(this.speedRowData.id)
        .subscribe({
          next: (response: any) => {
            this.speedDetail = response[0];
            this.setActivityDetails();
            this.isLoading = false;
          },
          error: (error: any) => {
            console.error('Error:', error);
            this.setActivityDetails();
            this.isLoading = false;
          },
        });
    }
  }

  setActivityDetails(): void {
    this.activityDetails = [
      {
        title: 'Date',
        value: this.speedDetail?.date
          ? convertDateFormat(this.speedDetail.date)
          : '',
      },
      {
        title: 'Time',
        value: this.speedDetail?.time
          ? `${convertTo12HrFormat(this.speedDetail?.time)} (EST)`
          : '',
      },
      {
        title: 'Driver ID',
        value: this.speedDetail?.driverId ? this.speedDetail.driverId : '',
      },
      {
        title: 'Vehicle ID',
        value: this.speedDetail?.vehicleId ? this.speedDetail.vehicleId : '',
      },
      {
        title: 'Posted',
        value: this.speedDetail?.posted ? this.speedDetail.posted : '',
      },
      {
        title: 'Actual',
        value: this.speedDetail?.acutal ? this.speedDetail.acutal : '',
      },
      {
        title: 'Difference',
        value: this.speedDetail?.difference ? this.speedDetail.difference : '',
      },
      {
        title: 'Category',
        value: this.speedDetail?.category ? this.speedDetail.category : '',
      },
      {
        title: 'Source',
        value: this.speedDetail?.source
          ? addSpacesToCamelCase(this.speedDetail.source)
          : '',
      },
    ];
  }
}
