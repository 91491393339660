<div class="textarea-block">
  <label class="custom-textarea-label" for="textarea-custom">{{ label }}</label>
  <textarea
    class="custom-textarea"
    id="textarea-custom"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="value"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()"
  ></textarea>
</div>
