import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Chart, ChartConfiguration, ChartOptions, Tooltip } from 'chart.js';
import { DriverService } from '@app/services/driver.service';
import { FormControl, FormGroup } from '@angular/forms';
import { convertDateFormat, formatDate } from '@app/shared/helpers/date.helper';
import { addSpaceAfterComma, insertBreaks } from '@app/shared/helpers/string.helper';
import { ScoreOverTimeChart } from '@app/models/analytics.model';
import { CoachingStatusType, SeverityType, StatusType } from '@app/models/status.model';
import { FlyoutService } from '@app/services/flyout.service';
import { InterventionService } from '@app/services/intervention.service';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { DialogCoachingDetailsComponent } from '@app/shared/components/dialog-coaching-details/dialog-coaching-details.component';
import { FlyoutType } from '@app/models/fly-out.model';
import { DialogEventComponent } from '@app/shared/components/dialog-event/dialog-event.component';
import { EventService } from '@app/services/event.service';
import { PermissionService } from '@app/services/permission.service';
import { environment } from '@env/environment';

const endDate = new Date();
const startDate = new Date();
startDate.setDate(endDate.getDate() - 30);

Chart.register(Tooltip);

@Component({
  selector: 'app-score-history-modal',
  templateUrl: './score-history-modal.component.html',
  styleUrls: ['./score-history-modal.component.scss'],
})
export class ScoreHistoryModalComponent implements OnInit, OnDestroy {
  scoreHistory!: any;
  params: any = {
    driverId: this.data.driverId,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    organizationId: environment.organizationId,
  };
  dateRange = new FormGroup({
    start: new FormControl(
      new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    ),
    end: new FormControl(
      new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    ),
  });

  colorYellow: string = 'rgb(255, 153, 0)';
  colorGreen: string = 'rgb(0, 153, 0)';
  colorGray: string = 'rgba(148,159,177,1)';

  lineChartData: ChartConfiguration<'line'>['data'] = {
    datasets: [],
  };
  lineChartOptions: ChartOptions<'line'> = {};
  lineChartLegend = true;
  processDatesArray!: string[];
  driverScoresArray!: number[];
  driverScoresEventsArray!: number[];
  driverScoresInterventionsArray!: number[];
  isLoading: boolean = true;

  minValue: number = 0;
  maxValue: number = 100;

  private chart: Chart | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ScoreHistoryModalComponent>,
    private driverService: DriverService,
    private flyoutService: FlyoutService,
    private interventionService: InterventionService,
    private eventService: EventService,
    private dialog: MatDialog,
    private permissionService: PermissionService
  ) {}

  ngOnInit(): void {
    this.fetchScoreHistory();
  }

  ngOnDestroy() {
    this.destroyChart();
    const tooltipEl = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
      tooltipEl.remove();
    }
  }

  destroyChart(): void {
    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
  }

  calculateYScaleBounds(datasets: any) {
    const allData = datasets.flatMap((dataset: any) => dataset.driverScore as number);
    const minValue = Math.min(...allData) / 10;
    const maxValue = Math.max(...allData) / 10;
    this.minValue = minValue < 10 ? minValue - 10 : 0;
    this.maxValue = maxValue >= 100 ? maxValue + 10 : 100;
  }

  initChart(): void {
    this.lineChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          label: 'Intervention',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: this.colorGreen,
          pointBorderColor: this.colorGreen,
          pointHoverBackgroundColor: this.colorGreen,
          pointHoverBorderColor: this.colorGreen,
          pointHoverRadius: 7,
          pointRadius: 5,
          fill: 'origin',
          tension: 0.5,
          datalabels: {
            display: false,
          },
        },
        {
          data: [],
          label: 'Event',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: this.colorYellow,
          pointBorderColor: this.colorYellow,
          pointHoverBackgroundColor: this.colorYellow,
          pointHoverBorderColor: this.colorYellow,
          pointHoverRadius: 7,
          pointRadius: 5,
          fill: 'origin',
          tension: 0.5,
          datalabels: {
            display: false,
          },
        },
        {
          data: [],
          label: 'Score',
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: this.colorGray,
          pointBackgroundColor: this.colorGray,
          pointBorderColor: this.colorGray,
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: this.colorGray,
          pointHoverRadius: 5,
          fill: 'origin',
          tension: 0.5,
          datalabels: {
            display: false,
          },
        },
      ],
    };
    this.lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        intersect: false,
      },
      onClick: (event, elements, chart) => this.pointClick(elements),
      plugins: {
        tooltip: {
          enabled: false, // Disable the default tooltip
          bodyFont: {
            family: 'Roboto, Arial, Helvetica, sans-serif',
            size: 12,
          },
          padding: 6,
          external: (context) => this.customTooltip(context),
        },
        legend: {
          display: false, // Hide the legend
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          min: this.minValue,
          max: this.maxValue,
          ticks: {
            callback: (value) => {
              return value !== 110 ? value : '';
            },
          },
        },
      },
      onHover: (event, chartElement) => {
        const nativeEvent = event.native;
        if (nativeEvent) {
          const target = nativeEvent.target as HTMLElement;
          if (chartElement.length) {
            const datasetIndex = chartElement[0].datasetIndex;
            if (
              (datasetIndex === ScoreOverTimeChart.EVENTS &&
                this.canViewOrWriteEvents()) ||
              (datasetIndex === ScoreOverTimeChart.INTERVENTIONS &&
                this.canViewOrWriteInterventions())
            ) {
              target.style.cursor = 'pointer';
            } else {
              target.style.cursor = 'default';
            }
          } else {
            target.style.cursor = 'default';
          }
        }
      },
    };
    this.chart = new Chart('myChart', {
      type: 'line',
      data: this.lineChartData,
      options: this.lineChartOptions,
    });
  }

  pointClick(elements: any): void {
    if (elements.length === 0) return; // Do nothing if a point was not clicked

    const elementIndex = elements[0].index;
    const datasetIndex = elements[0].datasetIndex;

    if (datasetIndex === ScoreOverTimeChart.EVENTS) {
      const eventId = this.scoreHistory[elementIndex]?.events[0]?.eventId;
      if (eventId && this.canViewOrWriteEvents()) {
        this.openEventDetail(eventId);
      }
    } else if (datasetIndex === ScoreOverTimeChart.INTERVENTIONS) {
      const interventionId = this.scoreHistory[elementIndex]?.interventions[0];
      if (interventionId && this.canViewOrWriteInterventions()) {
        this.openInterventionDetail(interventionId);
      }
    }
  }

  openEventDetail(eventId: string): void {
    this.eventService.getEvent(eventId).subscribe({
      next: (res) => {
        const eventData = res[0];
        this.flyoutService.handleDialogsOfType(FlyoutType.EVENT);
        const dialogWidth = calculateDialogWidth(
          this.flyoutService.getFlyoutOffsetBasedOnIndex()
        );

        this.dialog
          .open(DialogEventComponent, {
            data: {
              id: eventId,
              ...eventData,
              showInfo: true,
              uniqueIdentifier: FlyoutType.EVENT,
            },
            position: {
              right: '0',
              top: '70px',
            },
            width: dialogWidth,
            minWidth: dialogWidth,
            panelClass: ['dialog-event', 'animate-slide-in-left'],
            autoFocus: false,
          })
          .afterClosed()
          .subscribe(() => {});
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  openInterventionDetail(intervention: any): void {
    this.interventionService.getIntervention(intervention.interventionId).subscribe({
      next: (res) => {
        const interventionData = res[0];
        delete interventionData.EventData;
        this.flyoutService.handleDialogsOfType(FlyoutType.COACHING);
        const dialogWidth = calculateDialogWidth(
          this.flyoutService.getFlyoutOffsetBasedOnIndex()
        );

        this.dialog
          .open(DialogCoachingDetailsComponent, {
            data: {
              key: interventionData?.ticketId,
              driverName: this.data.driverName,
              driverId: this.data.driverId,
              ...interventionData,
              uniqueIdentifier: FlyoutType.COACHING,
            },
            position: {
              right: '0',
              top: '70px',
            },
            width: dialogWidth,
            minWidth: dialogWidth,
            panelClass: ['dialog-event', 'animate-slide-in-left'],
            autoFocus: false,
          })
          .afterClosed()
          .subscribe(() => {});
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private createOrUpdateTooltipElement(): HTMLElement {
    let tooltipEl = document.getElementById('chartjs-tooltip') as HTMLElement;
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.innerHTML = '<section></section>';
      document.body.appendChild(tooltipEl);
    }
    return tooltipEl;
  }

  private configureTooltipStyle(tooltipEl: HTMLElement, context: any) {
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }
    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left =
      window.scrollX +
      context.chart.canvas.getBoundingClientRect().left +
      tooltipModel.caretX +
      'px';
    tooltipEl.style.top =
      window.scrollY +
      context.chart.canvas.getBoundingClientRect().top +
      tooltipModel.caretY +
      'px';
    tooltipEl.style.font = `${Chart.defaults.font.size}px ${Chart.defaults.font.family}`;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.background = '#808080';
    tooltipEl.style.color = 'white';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.zIndex = '9999';
    tooltipEl.style.padding = '5px';
  }

  private generateTooltipContent(index: number, tooltipEl: HTMLElement) {
    const score = this.driverScoresArray[index] ?? this.driverScoresEventsArray[index];
    let innerHtml = `<ul><li><strong>Score:</strong> ${score}</li>`;

    if (this.driverScoresEventsArray[index]) {
      innerHtml += this.generateEventTooltipContent(index);
    }
    if (this.driverScoresInterventionsArray[index]) {
      innerHtml += this.generateInterventionTooltipContent(index);
    }
    innerHtml += '</ul>';

    const tableRoot = tooltipEl.querySelector('section');
    tableRoot!.innerHTML = innerHtml;
  }

  private generateEventTooltipContent(index: number): string {
    const eventData = this.scoreHistory[index]?.events[0];
    return `
      <li>${convertDateFormat(this.scoreHistory[index]?.processDate)} - Event</li>
      <li>${eventData?.type}</li>
      <li>${
        eventData?.criteria ? insertBreaks(addSpaceAfterComma(eventData?.criteria)) : ''
      }</li>
      <li>${this.getSeverityIcon(eventData?.severity)} ${insertBreaks(
      addSpaceAfterComma(eventData?.severity)
    )}</li>
      <li>${this.getEventStatusIcon(eventData?.status)} ${eventData?.status}</li>
    `;
  }

  private generateInterventionTooltipContent(index: number): string {
    const interventionData = this.scoreHistory[index]?.interventions[0];
    return `
      <li>${convertDateFormat(this.scoreHistory[index]?.processDate)} - Intervention</li>
      <li> ${interventionData?.interventionType}</li>
      <li>${insertBreaks(addSpaceAfterComma(interventionData?.interventionName))}</li>
      <li><div class="line-wrapper">${this.getInterventionPriorityIcon(
        interventionData?.priority
      )} <span>${interventionData?.priority}</span></div></li>
      <li>${this.getInterventionStatusIcon(interventionData?.status)} ${
      interventionData?.status
    }</li>
    `;
  }

  customTooltip(context: any) {
    const index = context.tooltip.dataPoints[0].dataIndex;
    const tooltipEl = this.createOrUpdateTooltipElement();

    this.generateTooltipContent(index, tooltipEl);
    this.configureTooltipStyle(tooltipEl, context);
  }

  getSeverityIcon(severity: string): string {
    const severityIcons: any = {
      [SeverityType.CRITICAL]: `<i class="fa-solid fa-angle-double-up icon-red icon"></i>`,
      [SeverityType.SIGNIFICANT]: `<i class="fa-solid fa-angle-double-up icon-red icon"></i>`,
      [SeverityType.MODERATE]: `<i class="fa-solid fa-equals icon-orange icon"></i>`,
      [SeverityType.MINOR]: `<i class="fa-solid fa-chevron-down icon-gray icon"></i>`,
    };

    return severityIcons[severity] || '';
  }

  getEventStatusIcon(status: string): string {
    const statusIcons: any = {
      [StatusType.OPEN]: `<i class="fa-solid fa-circle-notch icon-open icon"></i>`,
      [StatusType.ASSIGNED]: `<i class="fa-solid fa-user-circle icon-second-blue icon"></i>`,
      [StatusType.CLOSED]: `<i class="fa-solid fa-circle-xmark icon-red icon"></i>`,
    };

    return statusIcons[status] || '';
  }

  getInterventionStatusIcon(status: string): string {
    const statusIcons: any = {
      [CoachingStatusType.OPEN]: `<i class="fa-solid fa-circle-notch icon-open icon"></i>`,
      [CoachingStatusType.SCHEDULED]: `<i class="fa-solid fa-calendar-days icon-orange icon"></i>`,
      [CoachingStatusType.IN_PROGRESS]: `<i class="fa-solid fa-clock icon-second-blue icon"></i>`,
      [CoachingStatusType.COMPLETED]: `<i class="fa-solid fa-circle-check icon-green icon"></i>`,
    };

    return statusIcons[status] || '';
  }

  getInterventionPriorityIcon(priority: string): string {
    return `<div class="safety-indicator ${priority.toLowerCase()}">
      <i class="mark mark-first"></i>
      <i class="mark"></i>
      <i class="mark"></i>
    </div>`;
  }

  onDateRangeSelected(range: { start: Date | null; end: Date | null }) {
    if (range.start && range.end) {
      this.dateRange.setValue({
        start: range.start,
        end: range.end,
      });
      const startDateControl = this.dateRange.get('start')?.value;
      const endDateControl = this.dateRange.get('end')?.value;
      if (startDateControl) {
        this.params.startDate = formatDate(startDateControl);
      }
      if (endDateControl) {
        this.params.endDate = formatDate(endDateControl);
      }
      this.fetchScoreHistory();
    }
  }

  dateRangeUpdated(): void {
    const startDateControl = this.dateRange.get('start')?.value;
    const endDateControl = this.dateRange.get('end')?.value;
    if (startDateControl) {
      this.params.startDate = formatDate(startDateControl);
    }
    if (endDateControl) {
      this.params.endDate = formatDate(endDateControl);
    }
    this.fetchScoreHistory();
  }

  fetchScoreHistory(): void {
    this.destroyChart();
    this.isLoading = true;
    this.driverService.getScoreHistory(this.params).subscribe({
      next: (res) => {
        this.scoreHistory = res;
        this.calculateYScaleBounds(this.scoreHistory);
        this.initChart();
        this.setChartData();
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  createPointBackgroundColorArray(color: string) {
    return this.scoreHistory.map((item: any) =>
      item.events && item.events.length > 0 ? color : 'rgba(148,159,177,1)'
    );
  }

  setChartData(): void {
    this.processDatesArray = this.scoreHistory.map((item: any) => {
      const [year, month, day] = item.processDate.split('-').map(Number);
      const date = new Date(year, month - 1, day);
      const dayStr = String(date.getDate()).padStart(2, '0');
      const monthStr = String(date.getMonth() + 1).padStart(2, '0');
      const yearStr = String(date.getFullYear()).substring(2);
      return `${monthStr}/${dayStr}/${yearStr}`;
    });
    this.driverScoresArray = this.scoreHistory.map((item: any) => {
      return item.driverScore / 10;
    });

    // Events
    this.driverScoresEventsArray = this.scoreHistory.map((item: any) => {
      if (item?.events) {
        return item.driverScore / 10;
      } else {
        return null;
      }
    });

    // Interventions
    this.driverScoresInterventionsArray = this.scoreHistory.map((item: any) => {
      if (item?.interventions) {
        return item.driverScore / 10;
      } else {
        return null;
      }
    });

    this.lineChartData.labels = this.processDatesArray;
    this.lineChartData.datasets[ScoreOverTimeChart.SCORES].data = this.driverScoresArray;

    this.lineChartData.datasets[ScoreOverTimeChart.EVENTS].data =
      this.driverScoresEventsArray;

    this.lineChartData.datasets[ScoreOverTimeChart.INTERVENTIONS].data =
      this.driverScoresInterventionsArray;
  }

  close(): void {
    this.dialogRef.close();
  }

  canViewOrWriteEvents(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Events') ||
      this.permissionService.hasPermission('WRITE', 'Events')
    );
  }

  canViewOrWriteInterventions(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'All Interventions') ||
      this.permissionService.hasPermission('WRITE', 'All Interventions')
    );
  }
}
