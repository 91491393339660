<span class="label">Assigned Coach</span>

<mat-form-field appearance="outline" class="custom-input assigned-coach-search">
  <span matPrefix class="prefix">
    <div class="input-img">
      <img
        *ngIf="
          !(
            selectedUserImage &&
            assigneeName.trim() &&
            assigneeName !== assigneeType.UNASSIGNED
          )
        "
        [src]="selectedUserImage"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
      <img
        *ngIf="assigneeName.trim() && assigneeImages[assignee]"
        [src]="assigneeImages[assignee]"
        appImgFallback
        alt="Avatar"
        class="user-avatar"
      />
      <div
        *ngIf="
          selectedUserImage &&
          assigneeName.trim() &&
          assigneeName !== assigneeType.UNASSIGNED &&
          !assigneeImages[assignee]
        "
        class="initials"
        [ngClass]="getBackgroundClass(assigneeName)"
      >
        {{ getInitials(assigneeName) }}
      </div>
    </div>
  </span>
  <input
    type="text"
    matInput
    [matTooltip]="searchUserIdControl.value"
    [formControl]="searchUserIdControl"
    [matAutocomplete]="auto"
    [hidden]="false"
    (input)="clearImg()"
  />
  <span *ngIf="selectedUserImage" class="input-reset" matSuffix (click)="resetUser()">
    <i class="fa-solid fa-circle-xmark"></i>
  </span>
  <span *ngIf="!selectedUserImage" matSuffix class="input-reset search-icon">
    <i class="fa-solid fa-magnifying-glass"></i>
  </span>
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onSelectUser($event.option.value, true)"
  >
    <!-- Unassigned option-->
    <mat-option [value]="assigneeType.UNASSIGNED"
      ><div class="driver-option-content">
        <div class="driver-avatar">
          <img [src]="getImageUrl('')" appImgFallback alt="Avatar" class="user-avatar" />
        </div>
        <span>{{ assigneeType.UNASSIGNED }}</span>
      </div></mat-option
    >
    <!-- Users options-->
    <mat-option *ngFor="let user of filteredUsers | async" [value]="user">
      <div class="driver-option-content">
        <div class="driver-avatar">
          <img
            *ngIf="!user?.firstName.trim()"
            [src]="getImageUrl(user.assigneeId)"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <img
            *ngIf="user?.firstName.trim() && assigneeImages[user.userId]"
            [src]="assigneeImages[user.userId]"
            appImgFallback
            alt="Avatar"
            class="user-avatar"
          />
          <div
            *ngIf="user?.firstName.trim() && !assigneeImages[user.userId]"
            class="initials"
            [ngClass]="getBackgroundClass(setFullName(user))"
          >
            {{ getInitials(setFullName(user)) }}
          </div>
        </div>
        <span>{{ setFullName(user) }}</span>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
