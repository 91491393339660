import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { DialogDriverService } from '@app/services/dialog-driver.service';
import { FilesViewerModalComponent } from '@app/shared/components/files-viewer-modal/files-viewer-modal.component';
import { NewFileModalComponent } from '../new-file-modal/new-file-modal.component';
import { ToasterService } from '@app/services/toaster.service';
import { ModalMode } from '@app/models/general.model';
import { ExpandViewService } from '@app/services/expand-view.service';
import { Subscription } from 'rxjs';

interface Record {
  view: string;
  recordType: string;
  uploaded: string;
  expires: string;
  complianceSchema: string;
  status: string;
}

interface Section {
  title: string;
  id: string;
  records: Record[];
  isViewMore: boolean;
}

@Component({
  selector: 'app-tab-sections-files',
  templateUrl: './tab-sections-files.component.html',
  styleUrls: ['../tab-sections.component.scss'],
})
export class TabSectionsFilesComponent {
  isLoading: boolean = false;
  maxDisplayedRecords: number = 6;
  dataSources: { [key: string]: MatTableDataSource<Record> } = {};

  urls = ['https://ontheline.trincoll.edu/images/bookdown/sample-local-pdf.pdf'];

  displayedColumns = [
    'view',
    'recordType',
    'uploaded',
    'expires',
    'complianceSchema',
    'status',
  ];
  onlyCompliance: boolean = false;

  // prettier-ignore
  sections: Section[] = [
    {
      title: 'Qualification Files',
      id: 'qualification-files',
      records: [
        { view: '', recordType: 'Commercial Driver App', uploaded: '5/1/2023', expires: 'n/a', complianceSchema: 'Included', status: 'Compliant' },
        { view: '', recordType: 'Annual Review', uploaded: '5/1/2023', expires: '5/7/2023', complianceSchema: 'Not Included', status: 'Expired' },
        { view: '', recordType: 'Medical Examiners Review', uploaded: '--', expires: '--', complianceSchema: 'Not Included', status: 'Missing' },
        { view: '', recordType: 'Drivers License', uploaded: '5/7/2023', expires: '5/7/2023', complianceSchema: 'Included', status: 'Expires Soon' },
        { view: '', recordType: 'Previous Employment Drug and Alcohol Verification', uploaded: '5/1/2023', expires: 'n/a', complianceSchema: 'Included', status: 'Processing' },
        { view: '', recordType: 'MVR Self Certification', uploaded: '5/7/2023', expires: '5/7/2023', complianceSchema: 'Included', status: 'Compliant' },
        { view: '', recordType: 'Commercial Driver App', uploaded: '5/1/2023', expires: 'n/a', complianceSchema: 'Included', status: 'Compliant' },
        { view: '', recordType: 'Annual Review', uploaded: '5/1/2023', expires: '5/7/2023', complianceSchema: 'Included', status: 'Expired' },
        { view: '', recordType: 'Medical Examiners Review', uploaded: '--', expires: '--', complianceSchema: 'Included', status: 'Missing' },
        { view: '', recordType: 'Drivers License', uploaded: '5/7/2023', expires: '5/7/2023', complianceSchema: 'Included', status: 'Expires Soon' },
        { view: '', recordType: 'Previous Employment Drug and Alcohol Verification', uploaded: '5/1/2023', expires: 'n/a', complianceSchema: 'Included', status: 'Processing' },
        { view: '', recordType: 'MVR Self Certification', uploaded: '5/7/2023', expires: '5/7/2023', complianceSchema: 'Included', status: 'Compliant' },
      ],
      isViewMore: true,
    },
    {
      title: 'Medical Files',
      id: 'medical-files',
      records: [
        { view: '', recordType: 'Physical', uploaded: '5/1/2023', expires: '5/1/2023', complianceSchema: 'Included', status: 'Compliant' },
        { view: '', recordType: 'Physician Documents', uploaded: '--', expires: '--', complianceSchema: 'Not Included', status: 'Missing' },
        { view: '', recordType: 'Medical Self Certification', uploaded: 'n/a', expires: 'n/a', complianceSchema: 'Not Included', status: 'n/a' },
      ],
      isViewMore: true,
    },
    {
      title: 'Substance Testing',
      id: 'substance-testing',
      records: [
        {
          view: '',
          recordType: 'Limited DBA Clearinghouse Verification',
          uploaded: '5/1/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'DBA Clearinghouse Education Training',
          uploaded: '5/7/2023',
          expires: '5/7/2023',
          complianceSchema: 'Not Included',
          status: 'Expires Soon',
        },
        {
          view: '',
          recordType: 'Pre-Employment Drug Test',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Not Included',
          status: 'Incorrect',
        },
        {
          view: '',
          recordType: 'Random Notification',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Needs Review',
        },
        {
          view: '',
          recordType: 'Random Drug Test',
          uploaded: '5/7/2023',
          expires: '5/1/2023',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'Random Alcohol Test',
          uploaded: '5/7/2023',
          expires: '5/1/2023',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
      ],
      isViewMore: true,
    },
    {
      title: 'Certifications',
      id: 'certifications',
      records: [
        {
          view: '',
          recordType: 'Physical',
          uploaded: '5/1/2023',
          expires: '5/1/2023',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'Physician Documents',
          uploaded: 'n/a',
          expires: 'n/a',
          complianceSchema: 'Not Included',
          status: 'n/a',
        },
        {
          view: '',
          recordType: 'Medical Self Certification',
          uploaded: 'n/a',
          expires: 'n/a',
          complianceSchema: 'Not Included',
          status: 'n/a',
        },
      ],
      isViewMore: true,
    },
    {
      title: 'Personnel Files',
      id: 'personnel-files',
      records: [
        {
          view: '',
          recordType: 'Criminal Report',
          uploaded: '5/1/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Expires Soon',
        },
        {
          view: '',
          recordType: 'Handbook Receipt',
          uploaded: '5/7/2023',
          expires: '5/7/2023',
          complianceSchema: 'Not Included',
          status: 'Expired',
        },
        {
          view: '',
          recordType: 'DOT Roadside Inspection',
          uploaded: '5/1/2023',
          expires: '5/1/2023',
          complianceSchema: 'Not Included',
          status: 'Missing',
        },
        {
          view: '',
          recordType: 'Certificate',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'Policy Receipts',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'Personnel Documents',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
        {
          view: '',
          recordType: 'Personnel Documents',
          uploaded: '5/7/2023',
          expires: 'n/a',
          complianceSchema: 'Included',
          status: 'Compliant',
        },
      ],
      isViewMore: true,
    },
  ];
  sectionSelected: string = this.sections[0].title;

  subscription!: Subscription;

  @ViewChildren(MatSort) sorts!: QueryList<MatSort>;
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialogInfoService: DialogDriverService,
    public dialog: MatDialog,
    private toasterService: ToasterService,
    private expandViewService: ExpandViewService
  ) {
    this.sections.forEach((section) => {
      this.dataSources[section.id] = new MatTableDataSource(
        section.records.slice(0, this.maxDisplayedRecords)
      );
    });
  }

  ngOnInit(): void {
    this.setInitialDataSources();
    setTimeout(() => this.dialogInfoService.viewInit(255));

    this.subscription = this.expandViewService.expandView$.subscribe(() => {
      this.loadMore();
    });
  }

  loadMore(): void {
    this.sections.forEach((section) => {
      if (section.isViewMore) {
        this.toggleViewMore(section);
      }
      this.expandViewService.incrementExpandView();
    });
  }

  private setInitialDataSources(): void {
    this.sections.forEach((section) => {
      this.dataSources[section.id] = new MatTableDataSource(
        this.onlyCompliance
          ? section.records.filter((record) => record.complianceSchema === 'Included')
          : section.records.slice(0, this.maxDisplayedRecords)
      );
    });
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
    this.sections.forEach((section, index) => {
      const sort = this.sorts.toArray()[index];
      if (this.dataSources[section.id]) {
        this.dataSources[section.id].sort = sort;
      }
    });
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  onNavClick(elementId: string): void {
    this.sectionSelected = elementId;
    this.dialogInfoService.onNavClick(elementId);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'Compliant':
        return 'status-compliant';
      case 'Expired':
        return 'status-expired';
      case 'Missing':
        return 'status-missing';
      case 'Expires Soon':
        return 'status-expires-soon';
      case 'Processing':
        return 'status-processing';
      case 'Incorrect':
        return 'status-incorrect';
      case 'Needs Review':
        return 'status-needs-review';
      default:
        return '';
    }
  }

  getStatusIcon(status: string): string {
    switch (status) {
      case 'Compliant':
        return 'fas fa-check-circle';
      case 'Expired':
        return 'fas fa-times-circle';
      case 'Missing':
        return 'fas fa-times-circle';
      case 'Expires Soon':
        return 'fas fa-check-circle';
      case 'Processing':
        return 'fas fa-clock';
      case 'Incorrect':
        return 'fas fa-times-circle';
      case 'Needs Review':
        return 'fas fa-times-circle';
      default:
        return '';
    }
  }

  toggleViewMore(section: Section): void {
    section.isViewMore = !section.isViewMore;

    let filteredRecords = section.records;

    if (this.onlyCompliance) {
      filteredRecords = section.records.filter(
        (record) => record.complianceSchema === 'Included'
      );
    }

    this.dataSources[section.id].data = section.isViewMore
      ? filteredRecords.slice(0, this.maxDisplayedRecords)
      : filteredRecords;
  }

  openPdfModal() {
    const dialogRef = this.dialog.open(FilesViewerModalComponent, {
      height: 'calc(100% - 30px)',
      width: 'calc(100% - 30px)',
      maxWidth: '100%',
      maxHeight: '100%',
      data: { urls: this.urls, currentIndex: 0 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.currentIndex = result.currentIndex;
      }
    });
  }

  onRowClick(data: any): void {
    const dialogRef = this.dialog.open(NewFileModalComponent, {
      width: '660px',
      panelClass: 'single',
      data: { ...data, mode: ModalMode.EDIT },
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toasterService.showSuccess('The File was updated successfully');
      }
    });
  }

  toggleOnlyCompliance(event: any) {
    this.onlyCompliance = event.checked;
    this.updateDataSources();
  }

  updateDataSources(): void {
    this.sections.forEach((section) => {
      const filteredRecords = this.onlyCompliance
        ? section.records.filter((record) => record.complianceSchema === 'Included')
        : section.records;

      this.dataSources[section.id].data = filteredRecords.slice(
        0,
        section.isViewMore ? this.maxDisplayedRecords : filteredRecords.length
      );
    });
  }
}
