import {
  Component,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Input,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { Section } from '@app/models';
import { TabInfoSections } from '@app/drivers/dialog-driver/dialog-driver.constants';
import { DialogDriverService } from '@app/services/dialog-driver.service';
import { DriverService } from '@app/services/driver.service';
import { DriverProfile } from '@app/models/driverProfile';
import { Manager } from '@app/models/manager';
import { IDriverScoring } from '@app/models/scoring.model';
import { toTitleCase } from '@app/shared/helpers/string.helper';
import { ScoreHistoryModalComponent } from '@app/drivers/dialog-driver/tab-sections/tab-sections-info/score-history-modal/score-history-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UsPhoneFormatPipe } from '@app/shared/pipes/us-phone-format.pipe';
import { Subject, takeUntil } from 'rxjs';
import { PermissionService } from '@app/services/permission.service';
import { FlyoutType } from '@app/models/fly-out.model';
import { calculateDialogWidth } from '@app/shared/helpers/functions.helper';
import { FlyoutService } from '@app/services/flyout.service';

enum Factors {
  Accident = 'Accident',
  Telematics = 'Telematics',
  PercentSpeeding = 'Percent Speeding',
  Egregious = 'Egregious Speeding',
  HOS = 'HOS',
  RoadsideInspections = 'Roadside Inspections',
  Seatbelt = 'Seatbelt',
  Total = 'Total',
}

@Component({
  selector: 'app-tab-sections-info',
  templateUrl: './tab-sections-info.component.html',
  styleUrls: ['../tab-sections.component.scss'],
  providers: [DatePipe, UsPhoneFormatPipe],
})
export class TabSectionsInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() rowData!: any;
  @Input() showMenu!: boolean;

  tableData = {
    safety: {
      columns: ['factors', 'score', 'empty', 'average'],
      data: [
        {
          factors: Factors.Accident,
          empty: 0,
          average: 0,
          change: '+3',
        },
        {
          factors: Factors.Telematics,
          empty: 0,
          average: 0,
          change: '-3',
        },
        {
          factors: Factors.PercentSpeeding,
          empty: 0,
          average: 0,
          change: '-3',
        },
        {
          factors: Factors.Egregious,
          empty: 0,
          average: 0,
          change: '-3',
        },
        {
          factors: Factors.HOS,
          empty: 0,
          average: 0,
          change: '-2',
        },
        {
          factors: Factors.RoadsideInspections,
          empty: 0,
          average: 0,
          change: '-2',
        },
        {
          factors: Factors.Seatbelt,
          empty: 0,
          average: 0,
          change: '-2',
        },
        {
          factors: Factors.Total,
          empty: 0,
          average: '',
          change: '-2',
        },
      ],
    },
  };
  sections: Section[] = TabInfoSections;
  dataGeneralInfo:
    | {
        avatarUrl?: string | null;
        icon?: string;
        title: string | number | Manager[];
        label: string;
        scoreIcon?: boolean;
      }[]
    | null = null;
  dataPersonalInfo: { title: string | Number; label: string }[] | null = null;
  scoring!: IDriverScoring | null;
  styles: any = {
    principalBar: null,
    bottomPoint: null,
    averageScoringText: null,
    pointValeLevel: null,
  };
  isLoadingDriverProfile: boolean = false;
  isLoadingDriverScoring: boolean = false;
  sectionSelected: string = 'general-info';
  private destroy$ = new Subject<void>();

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  constructor(
    private dialogInfoService: DialogDriverService,
    private driverService: DriverService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private usPhoneFormatPipe: UsPhoneFormatPipe,
    private permissionService: PermissionService,
    private flyoutService: FlyoutService
  ) {
    setTimeout(() => this.dialogInfoService.viewInit());
  }

  ngOnInit() {
    this.getDriverProfile();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNavClick(elementId: string): void {
    this.dialogInfoService.onNavClick(elementId);
  }

  ngAfterViewInit(): void {
    this.attachScrollListener();
  }

  private attachScrollListener(): void {
    const articleElement = this.scrollContainer.nativeElement;
    articleElement.addEventListener('scroll', () => {
      this.onContainerScroll();
    });
  }

  private onContainerScroll(): void {
    let closestSection = null;
    let minDistance = Infinity;

    this.sections.forEach((section) => {
      const element = this.scrollContainer.nativeElement.querySelector(`#${section.id}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const distance = Math.abs(
          rect.top - this.scrollContainer.nativeElement.getBoundingClientRect().top
        );

        if (distance < minDistance) {
          minDistance = distance;
          closestSection = section.id;
        }
      }
    });

    if (closestSection !== null) {
      this.sectionSelected = closestSection;
    }
  }

  getDriverScoring(): void {
    this.isLoadingDriverScoring = true;
    this.driverService
      .getDriverScoring(this.rowData?.driverId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: IDriverScoring[]) => {
          this.isLoadingDriverScoring = false;
          this.scoring = res[0];
          if (this.scoring) {
            this.setStyles();
            this.updateFactors(
              Factors.Accident,
              this.scoring.accidentPts,
              this.scoring.scoreFactorAverages[0].avgAccidentPts
            );
            this.updateFactors(
              Factors.Telematics,
              this.scoring.telematicsPts,
              this.scoring.scoreFactorAverages[0].avgTelematicsPts
            );
            this.updateFactors(
              Factors.PercentSpeeding,
              this.scoring.percentagePts,
              this.scoring.scoreFactorAverages[0].avgPercentagePts
            );
            this.updateFactors(
              Factors.Egregious,
              this.scoring.egregiousPts,
              this.scoring.scoreFactorAverages[0].avgEgregiousPts
            );

            this.updateFactors(
              Factors.HOS,
              this.scoring.hosViolationPts,
              this.scoring.scoreFactorAverages[0].avgHosViolationPts
            );
            this.updateFactors(
              Factors.RoadsideInspections,
              this.calculateAverage(this.scoring.roadsidePoints),
              this.calculateAverage(this.scoring.scoreFactorAverages[0].avgRoadsidePts)
            );
            this.updateFactors(
              Factors.Seatbelt,
              this.scoring.seatBeltPts,
              this.scoring.scoreFactorAverages[0].avgSBEPts
            );
            this.updateFactors(
              Factors.Total,
              this.scoring.totalPtsDeducted,
              1000 - this.scoring.averageScore
            );
            this.dataGeneralInfo?.push({
              title: this.scoring.currentScore / 10,
              label: 'Risk Score',
            });
          }
        },
        error: (error: any) => {
          this.isLoadingDriverScoring = false;
          console.log(error);
        },
      });
  }

  calculateAverage(pointsArray: any[]): number {
    if (pointsArray.length === 0) return 0;

    const totalPoints = pointsArray.reduce((acc, obj) => {
      return acc + Object.values(obj).reduce((a: any, b: any) => a + b, 0);
    }, 0);

    const totalEntries = pointsArray.length * Object.keys(pointsArray[0]).length;

    return totalPoints / totalEntries;
  }

  updateFactors(factors: string, newEmpty: number, newAverage: number) {
    const index = this.tableData.safety.data.findIndex(
      (item) => item.factors === factors
    );
    if (index !== -1) {
      this.tableData.safety.data[index].empty = newEmpty;
      this.tableData.safety.data[index].average = newAverage;
    }
  }

  getDriverProfile(): void {
    this.isLoadingDriverProfile = true;
    this.driverService.getDriverProfile(this.rowData?.driverGuid).subscribe({
      next: (data: DriverProfile[]) => {
        this.isLoadingDriverProfile = false;
        this.setDataGeneralInfo(data);
        this.setDataPersonalInfo(data);
        this.getDriverScoring();
      },
      error: (error: any) => {
        this.isLoadingDriverProfile = false;
        console.log(error);
      },
    });
  }

  setDataGeneralInfo(data: DriverProfile[]): void {
    this.dataGeneralInfo = [];
    this.dataGeneralInfo.push(
      { title: data[0]?.terminal, label: 'Terminal' },
      {
        title: data[0]?.driverName ? toTitleCase(data[0]?.driverName) : '',
        label: 'Driver Name',
      }
    );
    if (data[0]?.Managers && data[0]?.Managers.length > 0) {
      data[0]?.Managers.forEach((manager) => {
        this.dataGeneralInfo?.push({
          title: manager?.managerName,
          label: manager?.title,
        });
      });
    }
    this.dataGeneralInfo.push({ title: data[0]?.status, label: 'Status' });
  }

  setDataPersonalInfo(data: DriverProfile[]): void {
    this.dataPersonalInfo = [
      {
        title: data[0]?.email ? data[0].email.toLowerCase() : '',
        label: 'Email',
      },
      { title: data[0]?.licenseNumber, label: 'License Number' },
      { title: data[0]?.driverType, label: 'Driver Type' },
      {
        title: this.usPhoneFormatPipe.transform(data[0]?.phone),
        label: 'Phone Number',
      },
      {
        title: (this.datePipe.transform(data[0]?.hireDate, 'longDate') as string) || '',
        label: 'Hire Date',
      },
      { title: data[0]?.tariff, label: 'Tariff' },
      { title: data[0]?.licenseState, label: 'State' },
      { title: data[0]?.domicile, label: 'Domicile' },
      {
        title:
          (this.datePipe.transform(data[0]?.dateOfBirth, 'longDate') as string) || '',
        label: 'Date of Birth',
      },
      { title: data[0]?.fullTime, label: 'Work Status' },
    ];

    if (data[0]?.terminationDate) {
      this.dataPersonalInfo.push({
        title: this.datePipe.transform(data[0].terminationDate, 'longDate') || '',
        label: 'Termination Date',
      });
    }
  }

  setStyles(): void {
    const score = this.scoring?.currentScore ?? 0;
    const percentageScore = score / 10;
    const averageScore = this.scoring?.averageScore ?? 0;
    const percentageAvgScore = averageScore / 10;
    let barColors = '';
    let pointValeLevelTextColor = '';
    if (percentageScore <= 33) {
      barColors = '#910101, #CC0000';
      pointValeLevelTextColor = 'black';
    } else if (percentageScore > 33 && percentageScore <= 66) {
      barColors = '#ff9900, #ffbf00';
      pointValeLevelTextColor = 'black';
    } else {
      barColors = '#00450F, #006600';
      pointValeLevelTextColor = 'white';
    }
    this.styles.pointValeLevel = {
      color: pointValeLevelTextColor,
    };
    this.styles.principalBar = {
      background: `linear-gradient(to right, ${barColors}) 0% 0% / ${percentageScore}% 100%, #E6E6E6 50% 0% / 50% 100%`,
      'background-repeat': 'no-repeat',
    };
    this.styles.bottomPoint = {
      'margin-left': `${percentageAvgScore}%`,
    };
    let marginLeft = '-34px';
    if (percentageScore < 4) {
      marginLeft = '0';
    }
    if (percentageScore > 96) {
      marginLeft = '-80px';
    }
    this.styles.averageScoringText = {
      'margin-left': `${marginLeft}`,
    };
  }

  getBarStyle(empty: number): any {
    const percentage = empty / 10;

    return {
      backgroundSize: `${percentage}% 100%, 50% 100%`,
    };
  }

  getBarClass(empty: number): string {
    const percentage = empty / 10;
    if (percentage <= 19) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  openScoreHistoryModal(): void {
    this.flyoutService.handleDialogsOfType(FlyoutType.SCORE);
    const dialogWidth = calculateDialogWidth(
      this.flyoutService.getFlyoutOffsetBasedOnIndex()
    );
    this.dialog.open(ScoreHistoryModalComponent, {
      data: {
        driverId: this.rowData?.driverId,
      },
      position: {
        right: '0',
        top: '70px',
      },
      width: dialogWidth,
      minWidth: dialogWidth,
      panelClass: ['dialog-event', 'animate-slide-in-left'],
      autoFocus: false,
    });
  }

  canViewScoreHistory(): boolean {
    return this.permissionService.hasPermission('READ', 'Score History');
  }
}
