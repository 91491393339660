<mat-form-field
  [class.border-open]="status.toLowerCase() === 'open' && border"
  [class.border-scheduled]="status.toLowerCase() === 'scheduled' && border"
  [class.border-in-progress]="status.toLowerCase() === 'in progress' && border"
  [class.border-completed]="status.toLowerCase() === 'completed' && border"
  class="status-select"
  appearance="fill"
  [style.width.px]="border ? 190 : 180"
  style="height: 46px"
>
  <mat-select
    [(ngModel)]="status"
    name="status"
    (selectionChange)="onSelectionChange($event)"
    [disabled]="disabled"
  >
    <mat-option *ngFor="let status of statuses" [value]="status.viewValue">
      <i [class]="status.icon"></i>
      <span class="dropdown-text">{{ status.viewValue }}</span>
    </mat-option>
    <mat-select-trigger>
      <ng-container [ngSwitch]="status">
        <ng-container *ngFor="let status of statuses">
          <span *ngSwitchCase="status.viewValue">
            <i [class]="status.icon"></i>
            <span class="dropdown-text">{{ status.viewValue }}</span>
          </span>
        </ng-container>
      </ng-container>
    </mat-select-trigger>
  </mat-select>
</mat-form-field>
