import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { Subscription, debounceTime, fromEvent } from 'rxjs';

import { DialogActivityComponent } from '@app/drivers/dialog-activity/dialog-activity.component';
import { DialogDriverComponent } from '@app/drivers/dialog-driver/dialog-driver.component';
import { DriversFiltersModalComponent } from '@app/drivers/drivers-list/drivers-filters-modal/drivers-filters-modal.component';
import { Driver, PaginationParams } from '@app/models';
import { FlyoutType } from '@app/models/fly-out.model';
import { toTitleCase } from '@app/shared/helpers/string.helper';
import { DialogNewInterventionComponent } from '@app/shared/components/dialog-new-intervention/dialog-new-intervention.component';
import { DialogFilterValuesComponent } from '@app/shared/components/dialog-filter-values/dialog-filter-values.component';
import { DriverIdService } from '@app/services/driver-id.service';
import { DriverService } from '@app/services/driver.service';
import { SharedService } from '@app/services/shared.service';
import { PermissionService } from '@app/services/permission.service';
import { AnalyticService } from '@app/services/analytic.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-drivers-list',
  templateUrl: './drivers-list.component.html',
  styleUrls: ['./drivers-list.component.scss'],
})
export class DriversListComponent implements OnInit, OnDestroy, AfterViewInit {
  value = '';
  displayedColumns: string[] = [
    'select',
    'driverName',
    'driverId',
    'terminal',
    'hireDate',
    'nbrOfInterventions',
    'lastDispatchDate',
    'driverScore',
    'scoreChangeType',
    'actions',
  ];
  allColumns: string[] = [
    'driverName',
    'driverId',
    'terminal',
    'hireDate',
    'nbrOfInterventions',
    'lastDispatchDate',
    'scoreChangeType',
    'actions',
  ];

  dataSource: MatTableDataSource<Driver>;
  defaultPageSize = 50;
  paginationParams: PaginationParams = {
    limit: this.defaultPageSize,
    offset: 0,
    total: 0,
  };
  isLoading: boolean = true;
  isLoadingFollow: boolean = false;
  selectedRow: string | null = null;
  pageSizeOptions = [10, 25, 50, 100];

  isModalOpen: boolean = false;
  isExpanded!: boolean;
  dialogRef: MatDialogRef<DriversFiltersModalComponent> | null = null;
  filtersForm!: any;
  filters: string[] = [];
  groupedFilters: any[] = [];
  searchValue = '';
  toTitleCase = toTitleCase;

  defaultSortActive = 'driverId';
  defaultSortDirection: SortDirection = 'asc';
  queryParamsSubscription!: Subscription;
  paramsSubscription!: Subscription;

  selection = new SelectionModel<any>(true, []);
  objectId!: any;
  followedDrivers: boolean = false;

  pageEvent: PageEvent = {
    length: this.paginationParams.total || 0,
    pageIndex: 0,
    pageSize: this.paginationParams.limit,
  };

  driverScoreMin: number | null = null;
  driverScoreMax: number | null = null;

  private shouldResetFilters: boolean = true;

  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('slider', { static: false }) slider!: ElementRef;

  constructor(
    private dialog: MatDialog,
    private driverService: DriverService,
    private driverIdService: DriverIdService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private permissionService: PermissionService,
    private analyticService: AnalyticService
  ) {
    this.dataSource = new MatTableDataSource<Driver>([]);
  }

  ngOnInit(): void {
    this.setFilters();
    this.resetFiltersForm();
    this.setFiltersByDefault();
    this.loadSavedPageData();
    this.getIsExpanded();

    this.setUserFromLocalStorage();
    this.updateDisplayedColumns();
    this.subscriptionToParamsChange();
    this.fetchDriversListData(true);

    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(debounceTime(700))
      .subscribe(() => {
        this.searchDriver();
      });
  }

  subscriptionToParamsChange(): void {
    this.paramsSubscription = this.route.params.subscribe((params) => {
      const terminal = params['terminal'];
      if (terminal) {
        this.filters.push(`Terminal: ${terminal}`);
        this.groupFilters();
        this.filtersForm.terminal = [terminal];
        this.fetchDriversListData();
      }
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      const driverId = params['driverId'];
      if (driverId) {
        this.onRowClick({ driverId });
      }
    });
  }

  setFilters(): void {
    const filters = this.analyticService.getDriversFilters();
    if (filters.driverScoreMin !== null) {
      this.driverScoreMin = filters.driverScoreMin;
    }
    if (filters.driverScoreMax !== null) {
      this.driverScoreMax = filters.driverScoreMax;
    }
  }

  resetFilters(): void {
    this.analyticService.resetDriversFilters();
  }

  ngOnDestroy() {
    this.resetFilters();
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  setUserFromLocalStorage(): void {
    const objectId = localStorage.getItem('objectId');
    if (objectId) {
      this.objectId = objectId;
    }
  }

  openFilterValuesDialog(
    event: MouseEvent,
    filterKey: string,
    filterValues: string[]
  ): void {
    const clickTarget = event.target as HTMLElement;
    const boundingRect = clickTarget.getBoundingClientRect();
    const dialogRef = this.dialog.open(DialogFilterValuesComponent, {
      width: '250px',
      data: { filterKey, filterValues },
      position: {
        top: `${boundingRect.bottom + window.scrollY + 7}px`,
        left: `${boundingRect.left + window.scrollX - 10}px`,
      },
    });

    dialogRef.afterClosed().subscribe((updatedValues) => {
      if (updatedValues) {
        this.updateFilterValues(filterKey, updatedValues);
      }
    });
  }

  updateFilterValues(filterKey: string, updatedValues: string[]): void {
    let filter = this.groupedFilters.find((f) => f.key === filterKey);
    if (filter) {
      filter.values = updatedValues;
      filter.count = updatedValues.length;
    }

    if (filter.count === 0) {
      this.groupedFilters = this.groupedFilters.filter((f) => f.key !== filterKey);
    }

    this.filters = this.filters.filter((f) => !f.startsWith(filterKey + ':'));
    updatedValues.forEach((value) => {
      this.filters.push(`${filterKey}: ${value}`);
    });

    if (filterKey === 'Terminal') {
      this.filtersForm.terminal = updatedValues;
    } else if (filterKey === 'Work Status') {
      this.filtersForm.workStatuses = updatedValues.map((value) => ({ name: value }));
    } else if (filterKey === 'Driver Type') {
      this.filtersForm.driverTypes = updatedValues.map((value) => ({ name: value }));
    } else if (filterKey === 'Status') {
      this.filtersForm.active = updatedValues.includes('Active');
      this.filtersForm.inactive = updatedValues.includes('Inactive');
    }
    this.fetchDriversListData(true);
  }

  groupFilters(): void {
    const filterMap = new Map();

    for (const filter of this.filters) {
      const [key, value] = filter.split(': ');
      if (!filterMap.has(key)) {
        filterMap.set(key, []);
      }
      filterMap.get(key).push(value);
    }

    this.groupedFilters = Array.from(filterMap).map(([key, values]) => ({
      key,
      values,
      count: values.length,
    }));
  }

  getImageUrl(driverId: string): string {
    return `${environment.driverImageBaseUrl}/${driverId}/profile_thumbnail${environment.driverImageToken}`;
  }

  clearSearch(): void {
    this.searchValue = '';
    this.searchDriver();
  }

  setFiltersByDefault(): void {
    this.filtersForm.active = true;
    if (this.driverScoreMin !== null) {
      this.filtersForm.driverScoreMin = this.driverScoreMin;
    }
    if (this.driverScoreMax !== null) {
      this.filtersForm.driverScoreMax = this.driverScoreMax;
    }
    if (this.driverScoreMin !== null && this.driverScoreMax !== null) {
      this.filters.push(`Score Range: ${this.driverScoreMin} to ${this.driverScoreMax}`);
    }
    this.filters.push('Status: Active');
    this.groupFilters();
  }

  announceSortChange(sortState: Sort): void {
    this.fetchDriversListData(true);
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => (this.pageEvent.pageIndex = 0));
    this.dataSource.sort = this.sort;
    this.updateSliderPosition();
  }

  getIsExpanded(): void {
    this.sharedService.getIsExpanded().subscribe((isExpanded: boolean) => {
      this.isExpanded = isExpanded;
    });
  }

  removeBox(key: string): void {
    this.goToFirstPage();
    const index = this.filters.findIndex((filter) => filter.startsWith(key));

    if (index === -1) {
      console.error('Filter not found');
      return;
    }

    const filterToRemove = this.filters[index];

    if (filterToRemove.startsWith('Terminal:')) {
      const typeToRemove = filterToRemove.split(': ')[1];
      this.filtersForm.terminal = this.filtersForm.terminal.filter(
        (item: any) => item !== typeToRemove
      );
    } else if (filterToRemove.startsWith('Work Status:')) {
      this.filtersForm.workStatuses = [];
    } else if (filterToRemove.startsWith('Driver Type:')) {
      const typeToRemove = filterToRemove.split(': ')[1];
      this.filtersForm.driverTypes = this.filtersForm.driverTypes.filter(
        (item: any) => item.name !== typeToRemove
      );
    } else if (filterToRemove === 'Status: Active') {
      this.filtersForm.active = false;
    } else if (filterToRemove === 'Status: Inactive') {
      this.filtersForm.inactive = false;
    } else if (filterToRemove.startsWith('Score Range:')) {
      this.filtersForm.driverScoreMin = 0;
      this.filtersForm.driverScoreMax = 100;
    } else if (filterToRemove === 'Open Interventions: True') {
      this.filtersForm.trueOption = false;
    }
    this.filters.splice(index, 1);
    this.groupFilters();
    this.fetchDriversListData(true);
  }

  openFiltersModal(): void {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.dialogRef = this.dialog.open(DriversFiltersModalComponent, {
        position: {
          left: this.isExpanded ? '300px' : '150px',
          top: '140px',
        },
        width: '400px',
        autoFocus: false,
        data: this.filtersForm,
        id: 'drivers-filters',
        panelClass: 'single',
      });

      this.dialogRef.afterClosed().subscribe((result: any) => {
        this.isModalOpen = false;
        if (result) {
          this.goToFirstPage();
          if (result !== 'clearAll') {
            this.clearAll();
            this.setFiltersBox(result);
            this.setFiltersForm(result);
            this.fetchDriversListData(true);
          } else {
            this.clearAll(true);
          }
        }
      });
    }
  }

  setFiltersForm(result: any) {
    this.filtersForm = {
      terminal: result.form.terminal ? result.form.terminal : [],
      active: result.form.active,
      inactive: result.form.inactive,
      trueOption: result.form.trueOption,
      workStatuses: result.workStatuses,
      driverTypes: result.driverTypes,
      driverScoreMin: result.form.driverScoreMin,
      driverScoreMax: result.form.driverScoreMax,
    };
  }

  setFiltersBox(result: any): void {
    if (result.form.driverScoreMin !== 0 || result.form.driverScoreMax !== 100) {
      this.filters.push(
        `Score Range: ${result.form.driverScoreMin} to ${result.form.driverScoreMax}`
      );
      this.groupFilters();
    }
    if (result.form.terminal && result.form.terminal.length > 0) {
      result.form.terminal.forEach((terminal: any) => {
        this.filters.push(`Terminal: ${terminal}`);
        this.groupFilters();
      });
    }
    const formStatuses = [
      { condition: result.form.active, label: 'Status: Active' },
      { condition: result.form.inactive, label: 'Status: Inactive' },
      { condition: result.form.trueOption, label: 'Open Interventions: True' },
    ];
    formStatuses.forEach((status) => {
      if (status.condition) {
        this.filters.push(status.label);
        this.groupFilters();
      }
    });
    if (result.workStatuses && result.workStatuses.length > 0) {
      result.workStatuses.forEach((status: any) => {
        this.filters.push(`Work Status: ${status.name}`);
        this.groupFilters();
      });
    }
    if (result.driverTypes && result.driverTypes.length > 0) {
      result.driverTypes.forEach((status: any) => {
        this.filters.push(`Driver Type: ${status.name}`);
        this.groupFilters();
      });
    }
  }

  addStateToFilters(stateArray: string[], label: string) {
    if (stateArray.length) {
      const joinedState = stateArray.join(', ');
      this.filters.push(`${label}: ${joinedState}`);
      this.groupFilters();
    }
  }

  goToFirstPage(): void {
    this.pageEvent.pageIndex = 0;
    localStorage.setItem(
      'driversListPage',
      JSON.stringify({
        pageIndex: this.pageEvent.pageIndex,
        pageSize: this.pageEvent.pageSize,
        searchValue: this.searchValue ? this.searchValue : '',
        followedDrivers: this.followedDrivers,
      })
    );
  }

  clearAll(fetchOrders: boolean = false): void {
    this.goToFirstPage();
    this.filters = [];
    this.groupFilters();
    this.resetFiltersForm();
    if (fetchOrders) {
      this.fetchDriversListData(true);
    }
  }

  resetFiltersForm(): void {
    this.filtersForm = {
      terminal: [],
      active: false,
      inactive: false,
      trueOption: false,
      workStatuses: [],
      driverTypes: [],
      driverScoreMin: 0,
      driverScoreMax: 100,
    };
  }

  searchDriver(): void {
    this.goToFirstPage();
    this.fetchDriversListData(true);
  }

  private loadSavedPageData(): void {
    const savedPage = this.getSavedPageDataFromLocalStorage();
    if (savedPage.pageIndex !== undefined && savedPage.pageSize !== undefined) {
      this.pageEvent.pageIndex = savedPage.pageIndex;
      this.pageEvent.pageSize = savedPage.pageSize;
      this.searchValue = savedPage.searchValue;
      this.followedDrivers = savedPage.followedDrivers;
    }
  }

  private getSavedPageDataFromLocalStorage(): any {
    return JSON.parse(localStorage.getItem('driversListPage') || '{}');
  }

  get isPaginationVisible(): boolean {
    return (
      this.paginationParams.total! >
      this.paginationParams.offset! + this.paginationParams.limit!
    );
  }

  get pageSize(): number {
    return this.paginationParams.limit - this.paginationParams.offset;
  }

  onNewActivityClick(): void {
    this.dialog.open(DialogActivityComponent, {
      panelClass: 'single',
      autoFocus: false,
    });
  }

  onNewInterventionClick(row: any): void {
    this.dialog.open(DialogNewInterventionComponent, {
      width: '800px',
      panelClass: 'single',
      data: {
        driver: {
          driverId: row.driverId,
          driverName: row.driverName,
          terminal: row.terminal,
        },
      },
    });
  }

  onFollowDriverClick(row: any): void {
    const request: any = [
      {
        UserId: this.objectId,
        DriverId: row.driverId,
        Deleted: row?.bookMark ? 'True' : 'False',
        organizationId: environment.organizationId,
        ...(row.bookMark && { id: this.objectId }),
      },
    ];
    this.bulkFollowDrivers(request);
  }

  onFollowSelectedClick(): void {
    const request = this.selection.selected.map((selectedItem) => ({
      UserId: this.objectId,
      DriverId: selectedItem.id,
      Deleted: selectedItem.bookMark ? 'True' : 'False',
      organizationId: environment.organizationId,
      ...(selectedItem.bookMark && { id: this.objectId }),
    }));
    this.bulkFollowDrivers(request);
  }

  bulkFollowDrivers(request: any[]): void {
    this.isLoadingFollow = true;
    this.driverService.upsertFollow(request).subscribe({
      next: () => {
        setTimeout(() => {
          this.fetchDriversListData();
          this.isLoadingFollow = false;
        }, 1000);
      },
      error: (error) => {
        this.isLoadingFollow = false;
        console.log(error);
      },
    });
  }

  allBookmarked(): boolean {
    return (
      this.selection.selected.length > 0 &&
      this.selection.selected.every((item) => item.bookMark)
    );
  }

  onPageChangeClick(event: PageEvent): void {
    this.pageEvent = event;
    localStorage.setItem(
      'driversListPage',
      JSON.stringify({
        pageIndex: event.pageIndex,
        pageSize: event.pageSize,
        searchValue: this.searchValue ? this.searchValue : '',
        followedDrivers: this.followedDrivers,
      })
    );
    this.fetchDriversListData(true);
  }

  onRowClick(data: any): void {
    this.driverIdService.setDriverId(data?.driverId);
    this.selectedRow = data?.driverId;
    this.dialog
      .open(DialogDriverComponent, {
        data: { ...data, uniqueIdentifier: FlyoutType.DRIVER },
        position: {
          right: '0',
          top: '70px',
        },
        width: '60%',
        minWidth: '850px',
        panelClass: ['dialog-driver', 'animate-slide-in-left'],
        autoFocus: false,
      })
      .afterClosed()
      .subscribe(() => {
        this.selectedRow = null;
        this.driverIdService.setDriverId(null);
      });
  }

  onMenuClick(event: Event): void {
    event.stopPropagation();
  }

  isRowSelected(id: string): boolean {
    return id === this.selectedRow;
  }

  getSafetyManagerName(element: Driver): string {
    if (element.managers && Array.isArray(element.managers)) {
      const safetyManager = element.managers.find(
        (manager) => manager.title === 'Safety Manager'
      );
      return safetyManager ? safetyManager.managerName : '';
    }
    return '';
  }

  isScoreBlockVisible(score: number): boolean {
    return !isNaN(score);
  }

  private prepareCommaSeparatedString(array: any[]): string {
    return array.length > 0 ? array.map((item) => item.name).join(',') : '';
  }

  private prepareParams(withSearch: boolean): any {
    const driverTypeNames = this.prepareCommaSeparatedString(
      this.filtersForm.driverTypes
    );
    const workStatusNames = this.prepareCommaSeparatedString(
      this.filtersForm.workStatuses
    );

    let driverStatus: string[] = [];
    if (this.filtersForm.active) {
      driverStatus.push('Active');
    }
    if (this.filtersForm.inactive) {
      driverStatus.push('Inactive');
    }

    let params: any = {
      page: this.pageEvent.pageIndex + 1,
      pageSize: this.pageEvent.pageSize,
      terminal: this.filtersForm.terminal,
      driverScoreMin: this.filtersForm.driverScoreMin,
      driverScoreMax: this.filtersForm.driverScoreMax,
      driverTypes: driverTypeNames,
      workStatuses: workStatusNames
        ? workStatusNames
            .split(',')
            .map((text) => `\"${text}\"`)
            .join(',')
        : '',
      status: driverStatus.join(','),
      userId: this.objectId,
      bookMarkOnly: this.followedDrivers,
      organizationId: environment.organizationId,
    };
    if (withSearch) {
      params.searchField = this.searchValue?.trim();
    }
    return params;
  }

  fetchDriversListData(withSearch: boolean = false): void {
    this.selection.clear();
    this.isLoading = true;
    if (this.searchValue !== '') {
      withSearch = true;
    }
    const params = this.prepareParams(withSearch);
    params.sortField = this.sort ? this.sort.active : this.defaultSortActive;
    params.sortValue = this.sort
      ? this.sort.direction.toUpperCase()
      : this.defaultSortDirection.toUpperCase();
    this.driverService.getDriverList(params).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        if (data.length) {
          this.paginationParams.total = data[0].totalRecordCount;
          this.dataSource.data = data[0].driverSummaries;
        } else {
          this.paginationParams.total = 0;
          this.dataSource.data = [];
        }
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
      },
    });
  }

  canCreateInterventions(): boolean {
    return this.permissionService.hasPermission('WRITE', 'All Interventions');
  }

  canViewDriverScores(): boolean {
    return (
      this.permissionService.hasPermission('READ', 'Driver Scores') ||
      this.permissionService.hasPermission('WRITE', 'Driver Scores')
    );
  }

  updateDisplayedColumns() {
    const position = this.allColumns.indexOf('lastDispatchDate') + 1;

    if (this.canViewDriverScores()) {
      if (!this.displayedColumns.includes('driverScore')) {
        this.displayedColumns.splice(position, 0, 'driverScore');
      }
      if (!this.displayedColumns.includes('scoreChangeType')) {
        this.displayedColumns.splice(position + 1, 0, 'scoreChangeType');
      }
    } else {
      let index = this.displayedColumns.indexOf('driverScore');
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
      index = this.displayedColumns.indexOf('scoreChangeType');
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getSymbol(scoreChangeType: string): string {
    if (scoreChangeType === 'Positive') {
      return '+';
    }
    return '';
  }

  onRadioSliderClick(followedDrivers: boolean = true): void {
    this.followedDrivers = followedDrivers;
    setTimeout(() => this.updateSliderPosition(), 0);
    this.goToFirstPage();
    this.fetchDriversListData(false);
  }

  updateSliderPosition() {
    const labels = document.querySelectorAll('.new-radio-label');
    const sliderContainer = document.querySelector('.new-radio-slider') as HTMLElement;
    const activeLabel = labels[this.followedDrivers ? 1 : 0] as HTMLElement;
    if (this.slider && this.slider.nativeElement && activeLabel && sliderContainer) {
      const style = window.getComputedStyle(activeLabel);
      const textWidth =
        activeLabel.clientWidth -
        parseFloat(style.paddingLeft) -
        parseFloat(style.paddingRight);

      if (!this.followedDrivers) {
        // All Drivers (left side)
        this.slider.nativeElement.style.left = `${activeLabel.offsetLeft}px`;
        //this.slider.nativeElement.style.width = `${textWidth}px`;
        this.slider.nativeElement.style.width = `95px`;
      } else {
        // Followed Drivers (right side)
        const extraSpace = 3;
        const offset = activeLabel.offsetLeft - extraSpace;
        const availableWidth = sliderContainer.clientWidth - offset;
        this.slider.nativeElement.style.left = `${offset}px`;
        this.slider.nativeElement.style.width = `${availableWidth}px`;
      }
    }
  }
}
