<mat-dialog-content class="content-container">
  <mat-list>
    <mat-list-item
      *ngFor="let value of data.filterValues; let i = index"
      class="clickable-item"
    >
      <button class="remove-filter" (click)="removeItem(i)">
        <i class="fas fa-times icon"></i>
      </button>
      {{ value }}
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-divider></mat-divider>

<mat-dialog-actions class="actions-container">
  <button class="button" mat-button (click)="clearAllDialog()">Clear All</button>
</mat-dialog-actions>
