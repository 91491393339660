<div [formGroup]="injuryDetailForm">
  <ng-template matStepLabel>Injury Detail</ng-template>
  <div class="stepper-container w-500">
    <h3>Has the injury been reported? *</h3>
    <mat-radio-group formControlName="injuryReported">
      <mat-radio-button value="true">Yes</mat-radio-button>
      <mat-radio-button value="false">No</mat-radio-button>
    </mat-radio-group>
    <div *ngIf="isInjured()">
      <h3>Who was the injury reported to?</h3>
      <div class="column">
        <mat-form-field appearance="outline">
          <mat-label>Reported</mat-label>
          <input matInput formControlName="injuryReportedTo" />
        </mat-form-field>
      </div>
    </div>
    <div class="stepper-container w-500" *ngIf="isInjured()">
      <h3>When was the injury reported?</h3>
      <div class="row">
        <app-date-time-picker
          label="Date"
          [hideTime]="true"
          [required]="true"
          [value]="dateInjuryReported"
          (dateChanged)="updateDateInjuryReported($event)"
        ></app-date-time-picker>
        <app-time-picker
          label="Time"
          [required]="true"
          (timeChanged)="updateTimeInjuryReported($event)"
        ></app-time-picker>
      </div>
    </div>
    <div>
      <h3>How did the injury occur? *</h3>
      <mat-form-field appearance="outline" class="textarea w-full">
        <textarea matInput formControlName="howInjuryOccur"></textarea>
      </mat-form-field>
    </div>
  </div>
</div>
